import jsonExport from 'jsonexport/dist'
import { downloadCSV } from 'react-admin'

import { formatDateTimeForExport } from '../../utils/dates'

const usersExporter = (records) => {
  const usersForExport = records.map((user) => {
    // Add new fields (date formatting)
    const dateFieldsToFormat = [
      'terms_acceptance_date',
      'marketing_terms_acceptance_date',
      'creation_date',
      'last_update_date',
      'account_closing_date',
    ]
    dateFieldsToFormat.forEach((field) => (user[field] = formatDateTimeForExport(user[field])))
    return user
  })

  jsonExport(
    usersForExport,
    {
      // order fields in the export
      headers: [
        'id',
        'first_name',
        'last_name',
        'phone_number',
        'email',
        'monitoring_email',
        'is_trusted',
        'address',
        'app_version',
        'terms_acceptance_date',
        'marketing_terms_acceptance_date',
        'creation_date',
        'last_update_date',
        'account_closing_date',
        'account_closing_reason',
        'account_closed_by',
        'account_closing_justification',
      ],
    },
    (err, csv) => downloadCSV(csv, 'users'),
  )
}

export default usersExporter
