import { LocaleManager, PresetManager, MessageDialog, EventModel } from 'bryntum-scheduler'
import EnLocale from 'bryntum-scheduler/locales/scheduler.locale.En'
import 'bryntum-scheduler/scheduler.material.css'
import './styles.css'

export const BryntumEventModel = EventModel

LocaleManager.registerLocale('EnBE', { locale: EnLocale })
LocaleManager.extendLocale('EnBE', {
  DateHelper: {
    locale: 'en-BE',
    weekStartDay: 1,
    parsers: { L: 'DD/MM/YYYY', LT: 'HH:mm' },
  },
})
LocaleManager.applyLocale('EnBE')

const DEFAULT_PRESETS = {
  monthAndYear: {
    displayDateFormat: 'll LT',
  },
  weekAndMonth: {
    headers: [
      { unit: 'month', dateFormat: 'MMM YYYY' },
      { unit: 'week', dateFormat: 'DD/MM' },
    ],
    displayDateFormat: 'll LT',
  },
  weekAndDayLetter: {
    headers: [
      { unit: 'week', dateFormat: '{Week} WW (DD/MM)' },
      { unit: 'day', dateFormat: 'dd DD/MM' },
    ],
    displayDateFormat: 'll LT',
    timeResolution: {
      unit: 'minute',
      increment: 15,
    },
  },
  hourAndDay: {
    headers: [
      { unit: 'day', dateFormat: 'ddd DD/MM' },
      { unit: 'hour', dateFormat: 'LT' },
    ],
    displayDateFormat: 'll LT',
    timeResolution: {
      unit: 'minute',
      increment: 5,
    },
  },
}

PresetManager.records
  .filter((r) => Object.keys(DEFAULT_PRESETS).some((p) => r.id.indexOf(p) > -1))
  .forEach((preset) => {
    const customId = preset.id.split('-')[0]
    const customPreset = {
      id: preset.id + 'Custom',
      base: preset.id,
      ...DEFAULT_PRESETS[customId],
    }
    PresetManager.add(customPreset)
  })

export const defaultPresets = PresetManager.records.filter((r) => r.id.indexOf('Custom') > -1)

export const defaultPresetName = 'hourAndDayCustom'

export const BryntumMessageDialog = MessageDialog
