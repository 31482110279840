import { FunctionField } from 'react-admin'
import { Box, Tooltip } from '@mui/material'
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes'
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff'

export default ({ source }) => (
  <FunctionField
    source={source}
    render={(record) => (
      <Box sx={{ marginTop: { xs: '10px !important' } }}>
        {record[source] ? (
          <Tooltip title={record[source]} placement="bottom">
            <SpeakerNotesIcon />
          </Tooltip>
        ) : (
          <SpeakerNotesOffIcon color="disabled" />
        )}
      </Box>
    )}
  />
)
