import SvgIcon from '@mui/material/SvgIcon'
import { forwardRef } from 'react'

export const path =
  'M21.12 22.54L19 20.41L16.88 22.54L15.47 21.12L17.59 19L15.47 16.88L16.88 15.47L19 17.59L21.12 15.47L22.54 16.88L20.41 19L22.54 21.12L21.12 22.54M14 2H6C4.89 2 4 2.89 4 4V20C4 21.11 4.89 22 6 22H13.81C13.28 21.09 13 20.05 13 19C13 15.69 15.69 13 19 13C19.34 13 19.67 13.03 20 13.08V8L14 2M13 9V3.5L18.5 9H13Z'

const FileRemoveIcon = forwardRef((props, ref) => (
  <SvgIcon ref={ref} {...props}>
    <path d={path} />
  </SvgIcon>
))

export default FileRemoveIcon
