import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classnames from 'classnames'
import { useTranslate } from 'ra-core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ArrayField, Datagrid, useRecordContext } from 'react-admin'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useTheme } from '@mui/styles'
import { useWatch } from 'react-hook-form'

import { Title, useShowStyles } from '../common/show'
import AdvancedTextField from '../../components/AdvancedTextField'
import PriceField from '../../components/PriceField'
import UnitField from '../../components/UnitField'
import env from '../../config/env'
import { useCommonStyles } from '../../config/theme'
import { formatDuration } from '../../utils/dates'
import '../../vendor/recharts'

const useStyles = makeStyles({
  panel: {
    paddingTop: 8,
    paddingBottom: 24,
  },
  table: {
    minWidth: 500,
    paddingRight: 30,
    paddingBottom: 24,
  },
  chart: {
    paddingTop: 8,
    maxWidth: 750,
    minWidth: 500,
  },
  chartOverlay: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
  },
})

const initialState = {
  zoomed: false,
  left: 'dataMin',
  right: 'dataMax',
  top: 'dataMax+50',
  bottom: 0,
  top2: 'dataMax+50',
  bottom2: 0,
}

const Chart = ({ steps: initialSteps }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const [steps, setSteps] = useState(initialSteps)
  useEffect(() => {
    setSteps(initialSteps)
  }, [initialSteps])

  const theme = useTheme()
  const getAxisYDomain = useCallback(
    (from, to, ref, offset) => {
      const refData = steps.slice(from, to + 1)
      if (refData[0]) {
        let [bottom, top] = [refData[0][ref], refData[0][ref]]
        refData.forEach((d) => {
          if (d[ref] > top) top = d[ref]
          if (d[ref] < bottom) bottom = d[ref]
        })
        bottom = bottom | 0
        return [bottom - (bottom === 0 ? 0 : offset), (top | 0) + offset]
      } else {
        return [0, 'dataMax+' + offset]
      }
    },
    [steps],
  )

  const [areaState, setAreaState] = useState({ left: null, right: null })
  const [state, setState] = useState(initialState)

  const zoom = useCallback(() => {
    let { left: areaLeft, right: areaRight } = areaState

    if (
      areaLeft === areaRight ||
      areaLeft === null ||
      areaRight === null ||
      areaLeft === undefined ||
      areaRight === undefined
    ) {
      setAreaState({ left: null, right: null })
      return
    }

    // xAxis domain
    if (areaLeft > areaRight) {
      ;[areaLeft, areaRight] = [areaRight, areaLeft]
    }

    areaLeft = Math.min(Math.max(areaLeft, 0), steps.length - 1)
    areaRight = Math.min(Math.max(areaRight, 0), steps.length - 1)

    // yAxis domain
    const [bottom, top] = getAxisYDomain(areaLeft, areaRight, 'formattedAmount', 50)
    const [bottom2, top2] = getAxisYDomain(areaLeft, areaRight, 'mileage', 50)

    setAreaState({ left: null, right: null })
    setState({
      zoomed: true,
      left: steps[areaLeft].duration,
      right: steps[areaRight].duration,
      bottom,
      top,
      bottom2,
      top2,
    })
    // trigger graph animation by cloning data
    setSteps(steps.slice())
  }, [areaState, steps, getAxisYDomain, setAreaState, setState, setSteps])

  const zoomOut = useCallback(() => {
    setAreaState({ left: null, right: null })
    setState(initialState)
    setSteps(steps.slice())
  }, [setAreaState, setState, steps])

  return (
    <div style={{ flex: 1, position: 'relative' }}>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={steps}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          onMouseDown={(e) => !!e && setAreaState((prev) => ({ ...prev, left: e.activeTooltipIndex }))}
          onMouseUp={zoom}
          onMouseMove={(e) =>
            !!e && areaState.left !== null && setAreaState((prev) => ({ ...prev, right: e.activeTooltipIndex }))
          }
        >
          <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />

          <XAxis
            dataKey="duration"
            tick={{ fill: theme.palette.text.primary }}
            tickFormatter={(v, index) => {
              if (v > state.right) {
                // Hide last tick that shouldn't be visible
                return ''
              }
              return formatDuration(v)
            }}
            allowDataOverflow
            domain={[state.left, state.right]}
            type="number"
            scale="sqrt"
            tickSize={10}
            tickMargin={10}
          />

          <YAxis
            yAxisId="left"
            unit={env.CURRENCY_SYMBOL}
            tick={{ fill: theme.palette.primary.main }}
            type="number"
            scale="sqrt"
            allowDataOverflow
            domain={[state.bottom, state.top]}
            tickSize={10}
            tickMargin={10}
          />
          <YAxis
            yAxisId="right"
            unit="km"
            tick={{ fill: theme.palette.warning.main }}
            type="number"
            scale="sqrt"
            orientation="right"
            allowDataOverflow
            domain={[state.bottom2, state.top2]}
            tickSize={10}
            tickMargin={10}
          />
          <Tooltip
            labelStyle={{ color: theme.palette.text.primary }}
            contentStyle={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: theme.shape.borderRadius,
            }}
            formatter={(value, name) => {
              return [value, name]
            }}
            labelFormatter={(...args) => {
              return formatDuration(args[0])
            }}
          />
          <Legend verticalAlign="top" wrapperStyle={{ paddingBottom: 20 }} />
          <Line
            animationDuration={300}
            yAxisId="left"
            type="linear"
            dataKey="formattedAmount"
            name={translate('resources.pricing-strategies.fields.steps.amount')}
            strokeWidth="2"
            stroke={theme.palette.primary.main}
            dot={{ fill: theme.palette.primary.main, r: 2.5 }}
            activeDot={{ r: 6, strokeWidth: 0 }}
          />
          <Line
            animationDuration={300}
            yAxisId="right"
            type="linear"
            dataKey="mileage"
            name={translate('resources.pricing-strategies.fields.steps.mileage')}
            strokeWidth="2"
            stroke={theme.palette.warning.main}
            dot={{ fill: theme.palette.warning.main, r: 2.5 }}
            activeDot={{ r: 6, strokeWidth: 0 }}
          />
          {steps[areaState.left] && steps[areaState.right] ? (
            <ReferenceArea
              yAxisId="left"
              x1={steps[areaState.left].duration}
              x2={steps[areaState.right].duration}
              strokeOpacity={0.3}
            />
          ) : null}
        </LineChart>
      </ResponsiveContainer>
      {steps?.length === 0 ? (
        <div className={classes.chartOverlay}>{translate('resources.pricing-strategies.chart.noData')}</div>
      ) : null}
      {state.zoomed && (
        <Button size="small" style={{ position: 'absolute', right: 90, top: 0 }} variant="outlined" onClick={zoomOut}>
          {translate('resources.pricing-strategies.chart.resetZoom')}
        </Button>
      )}
    </div>
  )
}

const formatSteps = (steps) =>
  steps
    ?.map((step) =>
      step?.duration !== null && step?.amount !== null && step?.mileage !== null
        ? {
            ...step,
            formattedAmount: step.amount / 100,
            formattedDuration: formatDuration(step.duration),
          }
        : null,
    )
    .filter(Boolean)
    .sort((a, b) => a.duration - b.duration) ?? []

export const PricingStrategyPanel = () => {
  const record = useRecordContext()
  const showClasses = useShowStyles()
  const classes = useStyles()
  const steps = useMemo(() => formatSteps(record.steps), [record])
  return (
    <div className={classes.panel}>
      <div className={showClasses.row}>
        <div className={classes.table}>
          <PricingStrategyGrid />
        </div>
        <div className={classnames(showClasses.expanded, classes.chart)}>
          <Chart steps={steps} />
        </div>
      </div>
    </div>
  )
}

export const PricingStrategyTable = () => (
  <div>
    <PricingStrategyGrid />
  </div>
)

const PricingStrategyGrid = () => {
  const commonClasses = useCommonStyles()
  const record = useRecordContext()
  const steps = useMemo(() => formatSteps(record.steps), [record])
  return (
    <ArrayField record={{ steps }} source="steps" currentSort={{ field: 'duration', order: 'ASC' }}>
      <Datagrid className={commonClasses.datagridWithoutLastBottomBorder} sx={{ '& .RaDatagrid-row': { height: 33 } }}>
        <AdvancedTextField source="formattedDuration" label="resources.pricing-strategies.fields.steps.duration" />
        <PriceField source="amount" label="resources.pricing-strategies.fields.steps.amount" />
        <UnitField unit="km" source="mileage" label="resources.pricing-strategies.fields.steps.mileage" />
      </Datagrid>
    </ArrayField>
  )
}

export const PricingStrategyChart = () => {
  const stepsData = useWatch({ name: 'steps' })
  const steps = useMemo(() => formatSteps(stepsData), [stepsData])
  return <Chart steps={steps} />
}

export const PricingStrategyTitle = (props) => <Title>{props.details || props.record.name}</Title>
