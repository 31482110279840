import config from './config'
import List from './list'
import Show from './show'
import Edit from './edit'
import Create from './create'

export default {
  ...config,
  config,
  list: List,
  show: Show,
  edit: Edit,
  create: Create,
}
