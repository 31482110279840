// Bypass Firebase for authentication.
// Operations here are mostly no-op. There is therefore no login/logout
// when running in local development mode.

export default (accountsProvider, permissionsProvider) => {
  return {
    login: async () => {
      await permissionsProvider.fetch()
    },
    logout: async () => {
      accountsProvider.clear()
      permissionsProvider.clear()
    },
    checkError: async (error) => {
      const { status } = error
      if (status === 401) {
        throw new Error('Invalid credentials')
      }
    },
    checkAuth: async () => {},
    getPermissions: permissionsProvider.get,
  }
}
