import SvgIcon from '@mui/material/SvgIcon'

export const path =
  'M16.5 2.25C17.33 2.25 18 2.92 18 3.75C18 4.58 17.33 5.25 16.5 5.25C15.67 5.25 15 4.58 15 3.75C15 2.92 15.67 2.25 16.5 2.25M20 18C21.11 18 22 18.9 22 20C22 21.11 21.11 22 20 22C19.26 22 18.61 21.6 18.27 21H8.73C8.39 21.6 7.74 22 7 22C5.9 22 5 21.11 5 20C5 18.9 5.9 18 7 18C7.74 18 8.39 18.4 8.73 19H13V13.5L9.73 11.23L6.77 14C6.36 14.37 5.73 14.34 5.35 13.94L2.28 10.65C1.91 10.24 1.93 9.61 2.33 9.23C2.74 8.86 3.37 8.88 3.74 9.28L6.13 11.84L13.26 5.2L13.3 5.18C13.72 4.82 14.35 4.88 14.71 5.3L16.97 8H20C20.55 8 21 8.45 21 9C21 9.55 20.55 10 20 10H16.5C16.15 10 15.85 9.82 15.67 9.56L14.17 7.77L11.64 10.13L14.57 12.18H14.57C14.83 12.36 15 12.66 15 13V19H15.61C16.04 17.13 17.63 15.7 19.58 15.5L18.81 11H20.31L21.39 17.34C20.97 17.12 20.5 17 20 17C18.69 17 17.58 17.84 17.17 19H18.27C18.61 18.4 19.26 18 20 18Z'

const EStepIcon = (props) => (
  <SvgIcon {...props}>
    <path d={path} />
  </SvgIcon>
)

export default EStepIcon
