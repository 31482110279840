import { Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { useRecordContext } from 'react-admin'

import { parseLocalDate } from '../utils/dates'

const LocalDateField = ({ source, record }) => {
  const { [source]: dateStr } = useRecordContext({ record })
  const dt = parseLocalDate(dateStr)
  return (
    <Typography component="span" variant="body2">
      {dateStr ? dt.isValid ? <span>{dt.toLocaleString(DateTime.DATE_SHORT)}</span> : dateStr : 'n/a'}
    </Typography>
  )
}

export default LocalDateField
