import { makeStyles } from '@mui/styles'

export const usePayoutShowStyles = makeStyles((theme) => ({
  loadingContainer: {
    justifyContent: 'flex-start',
    marginTop: theme.spacing(4),
  },
  card: {
    marginBottom: theme.spacing(1),
  },
  listItemContainer: {
    height: 50,
    marginBottom: theme.spacing(1),
    paddingLeft: 12,
    borderWidth: 2,
    borderRadius: 6,
    borderColor: theme.palette.grey[400],
  },
  listItemRightContainer: {
    justifyContent: 'space-between',
    marginLeft: (props) => (props.isSmallScreen ? theme.spacing(1) : 0),
  },
  statusIcon: {
    marginRight: 12,
  },
  bankAccountContainer: {
    paddingRight: theme.spacing(1),
  },
  bankAccountDisplayName: {
    marginRight: theme.spacing(1),
  },
}))

export const usePayoutIconColorStyles = makeStyles((theme) => ({
  grey: {
    color: theme.palette.grey[500],
  },
  primary: {
    color: theme.palette.primary.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  error: {
    color: theme.palette.error.main,
  },
}))
