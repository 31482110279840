import { IconButton, TextField } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { makeStyles } from '@mui/styles'
import { InputHelperText, useInput, useResourceContext } from 'react-admin'
import { useTranslate } from 'ra-core'

import {
  formatTime,
  getISOFromDateTime,
  parseDateAsDateTime,
  parseLocalDate,
  parseTimeAsDateTime,
} from '../utils/dates'

export const DATETIME_INPUT_MODE_DATE = 'date'
export const DATETIME_INPUT_MODE_TIME = 'time'
const DATETIME_INPUT_MODE_DATETIME = 'datetime'

const useStyles = makeStyles((theme) => ({
  clearButton: {
    color: theme.palette.contrast,
    width: 24,
    height: 24,
    padding: 0,
  },
  clearIcon: {
    width: 16,
    height: 16,
  },
}))

const AdvancedDateTimeInput = ({
  label,
  maxDate,
  minDate,
  mode = DATETIME_INPUT_MODE_DATETIME,
  readOnly,
  ...props
}) => {
  const {
    field: { onChange, value, ...fieldProps },
    fieldState: { error, isTouched },
    formState: { isSubmitted },
    isRequired,
  } = useInput(props)

  const resource = useResourceContext()
  const classes = useStyles()
  const translate = useTranslate()
  label = translate(label ?? `resources.${resource}.fields.${props.source}`)

  const clearValue = (event) => {
    event.stopPropagation()
    onChange(null)
  }

  const renderInput = (params) => (
    <TextField
      {...params}
      size="small"
      required={isRequired}
      error={Boolean(error) && (isTouched || isSubmitted)}
      helperText={<InputHelperText touched={isTouched || isSubmitted} error={error?.message} />}
      sx={{ '& .MuiIconButton-edgeEnd': { marginLeft: '-10px' } }}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {mode !== DATETIME_INPUT_MODE_TIME && value && !readOnly ? (
              <IconButton
                className={classes.clearButton}
                disableRipple
                onClick={clearValue}
                title={translate('mymove.clearValue')}
              >
                <ClearIcon className={classes.clearIcon} />
              </IconButton>
            ) : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  )

  const commonProps = { ...fieldProps, renderInput, label, disabled: readOnly }
  const commonTimePickerProps = { ampm: false }
  const commonDatePickerProps = { componentsProps: { actionBar: { actions: !!value ? ['clear'] : [] } } }

  return (
    <>
      {mode === DATETIME_INPUT_MODE_DATE && (
        <DatePicker
          {...commonProps}
          {...commonDatePickerProps}
          minDate={minDate}
          maxDate={maxDate}
          inputFormat="dd/MM/yyyy"
          value={value ? parseLocalDate(value) : null}
          onChange={(dt) => onChange(dt ? dt.toISODate() : null)} // clear value if no datetime
        />
      )}
      {mode === DATETIME_INPUT_MODE_TIME && (
        <TimePicker
          {...commonProps}
          {...commonTimePickerProps}
          inputFormat="HH:mm"
          views={['hours', 'minutes']}
          value={value ? parseTimeAsDateTime(value) : null}
          onChange={(dt) => onChange(formatTime(dt))}
        />
      )}
      {mode === DATETIME_INPUT_MODE_DATETIME && (
        <DateTimePicker
          {...commonProps}
          {...commonDatePickerProps}
          {...commonTimePickerProps}
          minDateTime={minDate}
          maxDateTime={maxDate}
          inputFormat="dd/MM/yyyy HH:mm"
          value={value ? parseDateAsDateTime(value) : null}
          onChange={(dt) => onChange(dt ? getISOFromDateTime(dt) : null)} // clear value if no datetime
        />
      )}
    </>
  )
}

export default AdvancedDateTimeInput
