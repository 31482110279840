import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'

import BikeIcon, { path as bikeIconPath } from '../components/icons/BikeIcon'
import CarIcon, { path as carIconPath } from '../components/icons/CarIcon'
import EStepIcon, { path as eStepIconPath } from '../components/icons/EStepIcon'
import ScooterIcon, { path as scooterIconPath } from '../components/icons/ScooterIcon'

import { THEME_LINEAR_GRADIENT_GREEN, THEME_LINEAR_GRADIENT_ORANGE, THEME_LINEAR_GRADIENT_RED } from './theme'

// Vehicle types

export const VEHICLE_TYPE_BIKE = 'BIKE'
export const VEHICLE_TYPE_CAR = 'CAR'
export const VEHICLE_TYPE_MOTOR_SCOOTER = 'MOTOR_SCOOTER'
export const VEHICLE_TYPE_KICK_SCOOTER = 'KICK_SCOOTER'

export const VEHICLE_TYPES = {
  [VEHICLE_TYPE_BIKE]: 'resources.vehicles.enums.types.bike',
  [VEHICLE_TYPE_CAR]: 'resources.vehicles.enums.types.car',
  [VEHICLE_TYPE_MOTOR_SCOOTER]: 'resources.vehicles.enums.types.motorScooter',
  [VEHICLE_TYPE_KICK_SCOOTER]: 'resources.vehicles.enums.types.kickScooter',
}

export const VEHICLE_TYPES_ICONS = {
  [VEHICLE_TYPE_BIKE]: { type: BikeIcon, path: bikeIconPath },
  [VEHICLE_TYPE_CAR]: { type: CarIcon, path: carIconPath },
  [VEHICLE_TYPE_MOTOR_SCOOTER]: { type: ScooterIcon, path: scooterIconPath },
  [VEHICLE_TYPE_KICK_SCOOTER]: { type: EStepIcon, path: eStepIconPath },
}

// Vehicle gateways

export const VEHICLE_GATEWAY_FAKE = 'fake'
export const VEHICLE_GATEWAY_NIU = 'niu'
export const VEHICLE_GATEWAY_PON = 'pon'
export const VEHICLE_GATEWAY_STARGATE = 'stargate'

export const VEHICLE_GATEWAYS = {
  [VEHICLE_GATEWAY_FAKE]: 'Fake',
  [VEHICLE_GATEWAY_NIU]: 'NIU',
  [VEHICLE_GATEWAY_PON]: 'PON',
  [VEHICLE_GATEWAY_STARGATE]: 'Stargate',
}

// Vehicle blueprint sets (for cars)

export const VEHICLE_TYPE_CAR_BLUEPRINT_SET_CAR = 'CAR'
export const VEHICLE_TYPE_CAR_BLUEPRINT_SET_VAN = 'VAN'
export const VEHICLE_TYPE_CAR_BLUEPRINT_SET_HOLIDAY_VAN = 'HOLIDAY_VAN'

export const VEHICLE_TYPE_CAR_BLUEPRINT_SETS = {
  [VEHICLE_TYPE_CAR_BLUEPRINT_SET_CAR]: 'resources.vehicles.enums.blueprintSet.personalCar',
  [VEHICLE_TYPE_CAR_BLUEPRINT_SET_VAN]: 'resources.vehicles.enums.blueprintSet.van',
  [VEHICLE_TYPE_CAR_BLUEPRINT_SET_HOLIDAY_VAN]: 'resources.vehicles.enums.blueprintSet.holidayVan',
}

// Vehicle energy types

export const VEHICLE_ENERGY_TYPE_ELECTRIC = 'ELECTRIC'
export const VEHICLE_ENERGY_TYPE_FUEL = 'FUEL'

export const VEHICLE_ENERGY_TYPES = {
  [VEHICLE_ENERGY_TYPE_ELECTRIC]: 'resources.vehicles.enums.energyTypes.electric',
  [VEHICLE_ENERGY_TYPE_FUEL]: 'resources.vehicles.enums.energyTypes.fuel',
}

// Vehicle transmission types

export const VEHICLE_TRANSMISSION_TYPE_AUTOMATIC = 'AUTOMATIC'
export const VEHICLE_TRANSMISSION_TYPE_MANUAL = 'MANUAL'

export const VEHICLE_TRANSMISSION_TYPES = {
  [VEHICLE_TRANSMISSION_TYPE_AUTOMATIC]: 'resources.vehicles.enums.transmissionTypes.automatic',
  [VEHICLE_TRANSMISSION_TYPE_MANUAL]: 'resources.vehicles.enums.transmissionTypes.manual',
}

// Vehicle actions

export const VEHICLE_ACTION_FORCE_UNLOCK = 'FORCE_UNLOCK'
export const VEHICLE_ACTION_FORCE_LOCK = 'FORCE_LOCK'

export const VEHICLE_ACTIONS = {
  [VEHICLE_ACTION_FORCE_UNLOCK]: {
    name: 'unlock',
    icon: LockOpenIcon,
    refresh: true,
  },
  [VEHICLE_ACTION_FORCE_LOCK]: {
    name: 'lock',
    icon: LockIcon,
    refresh: true,
  },
}

// Vehicle special fields

export const VEHICLE_SPECIAL_FIELD_CODE_FUEL_CARD_PIN = 'fuel_card_pin'
export const VEHICLE_SPECIAL_FIELD_CODE_PARKING_SPOT = 'parking_spot'
export const VEHICLE_SPECIAL_FIELD_CODE_FUELING_INFO = 'fueling_info'
export const VEHICLE_SPECIAL_FIELD_CODE_CHARGING_INSTRUCTIONS = 'charging_instructions'

export const VEHICLE_SPECIAL_FIELDS = {
  [VEHICLE_SPECIAL_FIELD_CODE_FUEL_CARD_PIN]: 'resources.vehicles.enums.specialFieldCodes.fuelCardPin',
  [VEHICLE_SPECIAL_FIELD_CODE_PARKING_SPOT]: 'resources.vehicles.enums.specialFieldCodes.parkingSpot',
  [VEHICLE_SPECIAL_FIELD_CODE_FUELING_INFO]: 'resources.vehicles.enums.specialFieldCodes.fuelingInfo',
  [VEHICLE_SPECIAL_FIELD_CODE_CHARGING_INSTRUCTIONS]: 'resources.vehicles.enums.specialFieldCodes.chargingInstructions',
}

// Vehicle status codes

export const VEHICLE_STATUS_CODE_CRITICAL_AUTONOMY = 'CRITICAL_AUTONOMY'
export const VEHICLE_STATUS_CODE_LOW_AUTONOMY = 'LOW_AUTONOMY'
export const VEHICLE_STATUS_CODE_LOW_BATTERY = 'LOW_BATTERY'
export const VEHICLE_STATUS_CODE_OIL_END_OF_LIFE = 'OIL_END_OF_LIFE'
export const VEHICLE_STATUS_CODE_OUT_OF_ZONE_WITHOUT_BOOKING_OR_MAINTENANCE =
  'OUT_OF_ZONE_WITHOUT_BOOKING_OR_MAINTENANCE'
export const VEHICLE_STATUS_CODE_POOR_TIRE_PRESSURE = 'POOR_TIRE_PRESSURE'
export const VEHICLE_STATUS_CODE_UNLOCKED_WITHOUT_BOOKING = 'UNLOCKED_WITHOUT_BOOKING'
export const VEHICLE_STATUS_CODE_VEHICLE_OFFLINE = 'VEHICLE_OFFLINE'

export const VEHICLE_STATUS_CODES = {
  [VEHICLE_STATUS_CODE_CRITICAL_AUTONOMY]: 'resources.vehicles.enums.statusCodes.criticalAutonomy',
  [VEHICLE_STATUS_CODE_LOW_AUTONOMY]: 'resources.vehicles.enums.statusCodes.lowAutonomy',
  [VEHICLE_STATUS_CODE_LOW_BATTERY]: 'resources.vehicles.enums.statusCodes.lowBattery',
  [VEHICLE_STATUS_CODE_OIL_END_OF_LIFE]: 'resources.vehicles.enums.statusCodes.oilEndOfLife',
  [VEHICLE_STATUS_CODE_OUT_OF_ZONE_WITHOUT_BOOKING_OR_MAINTENANCE]:
    'resources.vehicles.enums.statusCodes.outOfZoneWithoutBookingOrMaintenance',
  [VEHICLE_STATUS_CODE_POOR_TIRE_PRESSURE]: 'resources.vehicles.enums.statusCodes.poorTirePressure',
  [VEHICLE_STATUS_CODE_UNLOCKED_WITHOUT_BOOKING]: 'resources.enums.vehicles.statusCodes.unlockedWithoutBooking',
  [VEHICLE_STATUS_CODE_VEHICLE_OFFLINE]: 'resources.vehicles.enums.statusCodes.vehicleOffline',
}

// Vehicle cleanliness levels

export const VEHICLE_CLEANLINESS_LEVEL_DIRTY = 'DIRTY'
export const VEHICLE_CLEANLINESS_LEVEL_NORMAL = 'NORMAL'
export const VEHICLE_CLEANLINESS_LEVEL_CLEAN = 'CLEAN'

export const VEHICLE_CLEANLINESS_LEVELS = {
  [VEHICLE_CLEANLINESS_LEVEL_DIRTY]: 'resources.vehicles.enums.cleanlinessLevels.dirty',
  [VEHICLE_CLEANLINESS_LEVEL_NORMAL]: 'resources.vehicles.enums.cleanlinessLevels.normal',
  [VEHICLE_CLEANLINESS_LEVEL_CLEAN]: 'resources.vehicles.enums.cleanlinessLevels.clean',
}

export const VEHICLE_CLEANLINESS_LEVELS_COLORS = {
  [VEHICLE_CLEANLINESS_LEVEL_DIRTY]: THEME_LINEAR_GRADIENT_RED,
  [VEHICLE_CLEANLINESS_LEVEL_NORMAL]: THEME_LINEAR_GRADIENT_ORANGE,
  [VEHICLE_CLEANLINESS_LEVEL_CLEAN]: THEME_LINEAR_GRADIENT_GREEN,
}
