import * as base from './base'
import * as development from './development'
import * as production from './production'
import * as productionBeta from './production.beta'
import * as preproduction from './preproduction'
import * as staging from './staging'
import * as belgianarmy from './belgianarmy'
import * as belgianarmyBeta from './belgianarmy.beta'
import * as louezunvehicule from './louezunvehicule'
import * as valckenier from './valckenier'

const all = {
  production,
  productionBeta,
  preproduction,
  staging,
  development,
  belgianarmy,
  belgianarmyBeta,
  louezunvehicule,
  valckenier,
}

const envNameByHostName = Object.fromEntries(Object.entries(all).map(([k, v]) => [v.HOST_NAME, v.ENV_NAME]))
const envName = envNameByHostName[window.location.hostname] || 'staging'

const env = Object.freeze({
  ...base,
  ...all[envName],
})

if ([preproduction, staging, development].map((env) => env.ENV_NAME).includes(env.ENV_NAME)) {
  console.log('env', env)
}

export default env
