import { omit } from 'lodash'
import { createContext, useCallback, useContext } from 'react'
import { fetchUtils, useRefresh, useSafeSetState } from 'react-admin'

const ApiContext = createContext()

export const ApiProvider = (props) => <ApiContext.Provider {...props} />

export const useApi = (path, { onSuccess, onFailure, refresh, ...options } = {}) => {
  const api = useContext(ApiContext)
  const triggerRefresh = useRefresh()
  const [state, setState] = useSafeSetState({
    data: undefined,
    error: undefined,
    isLoading: false,
  })

  const callback = useCallback(
    ({ path: pathOverride, onSuccess: onSuccessOverride, onFailure: onFailureOverride, ...optionsOverride } = {}) => {
      setState((prevState) => ({ ...prevState, isLoading: true }))

      let url = pathOverride ?? path
      const { params, ...opts } = options
      if (params) {
        const query = omit(params, ['filter', 'sort', 'pagination'])
        if (params.filter) {
          query.filter = JSON.stringify(params.filter)
        }
        if (params.sort) {
          const { field, order } = params.sort
          query.sort = JSON.stringify([field, order])
        }
        if (params.pagination) {
          const { page, perPage } = params.pagination
          query.range = JSON.stringify([(page - 1) * perPage, page * perPage - 1])
        }
        url = `${url}?${fetchUtils.queryParameters(query)}`
      }

      return api(url, { ...opts, ...optionsOverride })
        .then((response) => {
          setState({
            data: response.json,
            error: null,
            isLoading: false,
          })
          const onSuccessFunc = onSuccessOverride ?? onSuccess
          if (onSuccessFunc) onSuccessFunc(response)
          if (refresh) triggerRefresh()
          return { result: response.json }
        })
        .catch((error) => {
          setState({
            data: null,
            error,
            isLoading: false,
          })
          const onFailureFunc = onFailureOverride ?? onFailure
          if (onFailureFunc) onFailureFunc(error)
          return { error }
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setState, path, JSON.stringify(options), api, onSuccess, onFailure],
  )

  return [callback, state]
}

export default (baseUrl, apiClient) => (path, options) => apiClient(`${baseUrl}${path}`, options)
