import { useEffect } from 'react'
import { Filter, List } from 'react-admin'
import { useTheme } from '@mui/styles'

import AdvancedSearchFilter from '../../components/AdvancedSearchFilter'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import MobileAppVersionField from '../../components/MobileAppVersionField'
import StatusField from '../../components/StatusField'
import { getUserFullName } from '../../components/UserFullNameField'
import { GenericListLayout, ListActions, ListDatagrid } from '../common/list'
import { useApi } from '../../api/apiProvider'
import { getStatusStyles } from '../../domain/statuses'
import { isUserDeactivated } from '../../domain/users'
import { AdvancedCreateButton } from '../common/create'
import { AdvancedEditInDialogButton } from '../common/edit'

import config from './config'
import usersExporter from './exporter'
import { UserClosedFilter } from './filter'
import UserFormLayout, { transformValues } from './form'

const UsersFilters = (props) => (
  <Filter {...props}>
    <AdvancedSearchFilter alwaysOn />
    <UserClosedFilter alwaysOn />
  </Filter>
)

const userRowSx = (record) => {
  const isDeactivated = isUserDeactivated(record)
  return { opacity: isDeactivated ? 0.4 : 1 }
}

const UsersListLayout = () => {
  const [fetchMeInfo, { data: meInfo }] = useApi('/me')
  useEffect(() => {
    fetchMeInfo()
  }, [fetchMeInfo])

  const theme = useTheme()

  return meInfo ? (
    <GenericListLayout
      compactListLayout={
        <CompactList
          linkType="show"
          itemSx={userRowSx}
          icon={(record) => getUserFullName(record, { showInitials: true })}
          iconBadgeColor={(record) => getStatusStyles(record.status, theme).mainColor}
          title={(record) => getUserFullName(record)}
          body={() => (
            <>
              <AdvancedTextField source="email" />
              <br />
              <AdvancedTextField source="phone_number" />
            </>
          )}
        />
      }
      regularListLayout={
        <ListDatagrid rowSx={userRowSx}>
          <StatusField />
          <AdvancedTextField source="first_name" />
          <AdvancedTextField source="last_name" />
          <AdvancedTextField source="email" />
          <AdvancedTextField source="phone_number" />
          <MobileAppVersionField mobileAppLatestVersion={meInfo.mobile_app_latest_version} />
          <DateTooltipField source="created_on" addTime />
          <AdvancedEditInDialogButton transform={transformValues}>
            <UserFormLayout />
          </AdvancedEditInDialogButton>
        </ListDatagrid>
      }
    />
  ) : null
}

export default () => {
  return (
    <List
      sort={config.options.defaultSort}
      filters={<UsersFilters />}
      filterDefaultValues={config.options.defaultFilterValues}
      exporter={usersExporter}
      actions={
        <ListActions hasExport>
          <AdvancedCreateButton transform={transformValues}>
            <UserFormLayout />
          </AdvancedCreateButton>
        </ListActions>
      }
    >
      <UsersListLayout />
    </List>
  )
}
