import { Box, CardContent, CardHeader, Typography } from '@mui/material'
import { get } from 'lodash'
import { useTranslate } from 'ra-core'
import {
  EditButton,
  ReferenceField,
  SimpleShowLayout,
  TopToolbar,
  useGetOne,
  useRecordContext,
  useReference,
  useResourceContext,
} from 'react-admin'

import AdvancedBooleanField from '../../components/AdvancedBooleanField'
import AdvancedShow from '../../components/AdvancedShow'
import AdvancedTextField from '../../components/AdvancedTextField'
import BookingAdditionalDriversField from '../../components/BookingAdditionalDriversField'
import BookingJustificationField from '../../components/BookingJustificationField'
import BookingRatingField from '../../components/BookingRatingField'
import DateTooltipField from '../../components/DateTooltipField'
import FieldWrapper from '../../components/FieldWrapper'
import OperationalStatusSection from '../../components/OperationalStatusSection'
import PeriodField from '../../components/PeriodField'
import PriceField from '../../components/PriceField'
import ShowCardHeader from '../../components/ShowCardHeader'
import UnitField from '../../components/UnitField'
import VehicleInteriorRatingField from '../../components/VehicleInteriorRatingField'
import {
  BOOKING_BILLING_TYPE_SOURCE,
  BOOKING_BILLING_TYPES,
  BOOKING_ERAS,
  BOOKING_PAYMENT_STATUSES,
} from '../../config/bookings'
import { SYSTEM_PERMISSION_READ, SYSTEM_PERMISSION_UPDATE } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { isBookingEditable, isBookingPaid, isBookingPast, wasBookingCancelled } from '../../domain/bookings'
import { useResourcePermissions } from '../../domain/permissions'
import { isAllowed } from '../../utils'
import { formatDuration } from '../../utils/dates'
import { useSmallScreen } from '../../utils/theme'
import bookingDetailsConfig from '../bookingDetails/config'
import bookingTransactionsConfig from '../bookingTransactions/config'
import BookingTransactionsListLayout from '../bookingTransactions/list'
import { ListReference } from '../common/list'
import {
  ShowActions,
  ShowCard,
  ShowReference,
  ShowReferenceLinks,
  ShowSubSectionTitleCard,
  useShowStyles,
} from '../common/show'
import { DamageCheckShowLayout } from '../damageChecks/show'
import HubField from '../hubs/field'
import organisationsConfig from '../organisations/config'
import OrganisationField from '../organisations/field'
import usersConfig from '../users/config'
import { VehicleShowLayout } from '../vehicles/show'
import vouchersConfig from '../vouchers/config'
import VoucherField from '../vouchers/field'
import { CardTitle } from '../common'

import config from './config'
import { useGetBookingsJustifications } from './hooks'

export const BookingShowLayout = ({ title, hasRedirect = false, excluded = [], ...props }) => {
  const record = useRecordContext(props)
  const { data: bookingDetails } = useGetOne(bookingDetailsConfig.name, { id: record.id })
  const bookingsJustifications = useGetBookingsJustifications()
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  const [hasReadForVouchers] = useResourcePermissions(vouchersConfig.name, SYSTEM_PERMISSION_READ)

  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()

  const shouldDisplayOrganisation = isAllowed(excluded, 'organisations')
  const shouldDisplayHub = isAllowed(excluded, 'hubs')

  const simpleShowLayoutCommonProps = {
    record: { ...record, ...bookingDetails },
    className: showClasses.fieldContainer,
  }
  const operationalStatusSectionProps = { data: bookingDetails?.status_timeline }

  return (
    <>
      <ShowCardHeader hasRedirect={hasRedirect} record={record} title={title} />
      {bookingDetails && bookingsJustifications && (
        <>
          <div className={isSmallScreen ? null : showClasses.row}>
            <div className={showClasses.expanded}>
              <ShowSubSectionTitleCard text="bookingInformation" />
              {(shouldDisplayOrganisation || shouldDisplayHub || hasReadForUsers) && (
                <SimpleShowLayout {...simpleShowLayoutCommonProps} data-cy="relationshipShowLayout">
                  {shouldDisplayOrganisation && <OrganisationField />}
                  {shouldDisplayHub && <HubField />}
                  {hasReadForUsers && isAllowed(excluded, 'users') && (
                    <ReferenceField source="user_id" reference="users" link="show" />
                  )}
                  {hasReadForUsers && <BookingAdditionalDriversField source="additional_driver_user_ids" />}
                </SimpleShowLayout>
              )}
              <SimpleShowLayout {...simpleShowLayoutCommonProps} data-cy="scheduleShowLayout">
                <AdvancedTextField source="era" map={BOOKING_ERAS} />
                <PeriodField
                  startedOnSource="start_scheduled_on"
                  endedOnSource="end_scheduled_on"
                  label="resources.bookings.fields.scheduled_period"
                  addTime
                />
                <PeriodField
                  startedOnSource="started_on"
                  endedOnSource="ended_on"
                  label="resources.bookings.fields.used_period"
                  addTime
                />
                <DateTooltipField source="cancelled_on" addTime />
                <DateTooltipField source="created_on" addTime />
              </SimpleShowLayout>
              <SimpleShowLayout {...simpleShowLayoutCommonProps} data-cy="ratingShowLayout">
                <VehicleInteriorRatingField />
                <BookingRatingField source="user_rating" />
              </SimpleShowLayout>
              <SimpleShowLayout {...simpleShowLayoutCommonProps} data-cy="metricShowLayout">
                <AdvancedTextField source="number_of_trips" />
                <UnitField source="fuel_delta" unit="L" />
                <UnitField source="time_driven" formatValue={(v) => formatDuration(Math.round(v / 60))} unit="" />
                <UnitField source="number_of_km_travelled" unit="km" />
              </SimpleShowLayout>
              <SimpleShowLayout {...simpleShowLayoutCommonProps} data-cy="billingShowLayout">
                <AdvancedTextField source={BOOKING_BILLING_TYPE_SOURCE} map={BOOKING_BILLING_TYPES} />
                <BookingJustificationField
                  addLabel
                  source="justification"
                  bookingsJustifications={bookingsJustifications}
                />
                {isBookingPast(record) && !wasBookingCancelled(record) && record.voucher_id ? (
                  <FieldWrapper source="amount">
                    {({ source }) => (
                      <>
                        <PriceField
                          source="initial_amount"
                          sx={{ color: 'grey', fontStyle: 'italic', textDecoration: 'line-through' }}
                        />
                        {'\xa0\xa0'}
                        <PriceField source={source} sx={{ fontWeight: 500 }} />
                      </>
                    )}
                  </FieldWrapper>
                ) : (
                  <PriceField source="amount" />
                )}
                {hasReadForVouchers && <VoucherField />}
                {bookingDetails.payment && [
                  <AdvancedBooleanField
                    key="payment_required"
                    source="payment.required"
                    trueIcon={null}
                    falseIcon={null}
                  />,
                  bookingDetails.payment.status && [
                    <AdvancedTextField key="payment_status" source="payment.status" map={BOOKING_PAYMENT_STATUSES} />,
                    bookingDetails.payment.refusal_reason && (
                      <AdvancedTextField key="payment_refusal_reason" source="payment.refusal_reason" />
                    ),
                  ],
                ]}
              </SimpleShowLayout>
              {isSmallScreen && <OperationalStatusSection {...operationalStatusSectionProps} />}
              <ShowActions allowedActions={bookingDetails.allowed_actions} />
            </div>
            {!isSmallScreen && (
              <Box width="100%" maxWidth={400} className={commonClasses.borderLeft}>
                <OperationalStatusSection {...operationalStatusSectionProps} />
              </Box>
            )}
          </div>
          <ShowReferenceLinks excluded={excluded} record={record} />
        </>
      )}
    </>
  )
}

const BookingActions = () => {
  const record = useRecordContext()
  const resource = useResourceContext()
  const [hasEdit] = useResourcePermissions(resource, SYSTEM_PERMISSION_UPDATE)
  return hasEdit && isBookingEditable(record) ? (
    <TopToolbar>
      <EditButton />
    </TopToolbar>
  ) : (
    <Box mt={2} />
  )
}

const BookingAside = () => {
  const record = useRecordContext()
  const translate = useTranslate()
  const showClasses = useShowStyles()
  const { data: bookingDetails } = useGetOne(bookingDetailsConfig.name, { id: record.id })

  const [hasReadForOrganisations] = useResourcePermissions(organisationsConfig.name, SYSTEM_PERMISSION_READ)
  const { data: organisation } = useGetOne(
    organisationsConfig.name,
    { id: record.organisation_id },
    { enabled: hasReadForOrganisations && Boolean(record.organisation_id) },
  )
  const organisationRequiresDamageReportPrompt = get(organisation, 'requires_damage_report_prompt', false)
  const showDamageCheck =
    isBookingPast(record) && !wasBookingCancelled(record) && organisationRequiresDamageReportPrompt
  const { referenceRecord, isLoading } = useReference({
    reference: 'damage-checks',
    id: record.id,
    options: { enabled: showDamageCheck },
  })

  const missingDamageCheck = showDamageCheck && referenceRecord === undefined && isLoading === false

  if (!bookingDetails || !organisation) {
    return null
  }
  return (
    <>
      {isBookingPaid(record) && Boolean(bookingDetails.payment?.required) && (
        <ListReference
          reference={bookingTransactionsConfig.name}
          target={config.options.referenceKey}
          sort={bookingTransactionsConfig.options.defaultSort}
        >
          <BookingTransactionsListLayout bookingId={record.id} />
        </ListReference>
      )}
      <ShowReference reference="vehicles" source="vehicle_id">
        <VehicleShowLayout excluded={['organisations']} hasRedirect />
      </ShowReference>
      {showDamageCheck &&
        (missingDamageCheck ? (
          <ShowCard>
            <CardHeader title={<CardTitle text="resources.damage-checks.name" args={1} />} />
            <CardContent className={showClasses.fieldContainer}>
              <Typography component="p" variant="body2" ml={2} mt={2.5}>
                {translate('resources.damage-checks.missingForBooking')}
              </Typography>
            </CardContent>
          </ShowCard>
        ) : (
          <ShowReference reference="damage-checks" source="id">
            <DamageCheckShowLayout />
          </ShowReference>
        ))}
    </>
  )
}

export default () => (
  <AdvancedShow actions={<BookingActions />} aside={<BookingAside />}>
    <BookingShowLayout excluded={['vehicles']} />
  </AdvancedShow>
)
