import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classnames from 'classnames'
import { useTranslate } from 'ra-core'

import { useCommonStyles } from '../config/theme'
import { useShowStyles } from '../resources/common/show'

const useStyles = makeStyles({
  unorderedList: {
    margin: 16,
    paddingRight: 12,
    textAlign: 'justify',
  },
})

const DefinitionList = ({ items }) => {
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <ul className={classnames(showClasses.unorderedList, classes.unorderedList)}>
      {items.map((i) => (
        <li key={i.role} className={showClasses.unorderedListItem}>
          <Typography
            component="span"
            variant="body2"
            className={classnames(commonClasses.inline, showClasses.boldText)}
            color="textPrimary"
          >
            {translate(i.role)}
          </Typography>{' '}
          <Typography component="span" variant="body2" className={commonClasses.inline} color="textPrimary">
            {translate(i.permission)}
          </Typography>
        </li>
      ))}
    </ul>
  )
}

DefinitionList.defaultProps = {
  items: [],
}

export default DefinitionList
