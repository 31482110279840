import { ReferenceField } from 'react-admin'

import config from './config'

const OpsUserField = (props) => <ReferenceField {...props} />

OpsUserField.defaultProps = {
  reference: config.name,
  source: config.options.referenceKey,
  link: 'show',
}

export default OpsUserField
