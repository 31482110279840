import { SimpleShowLayout } from 'react-admin'

import AdvancedShow from '../../components/AdvancedShow'
import AdvancedTextField from '../../components/AdvancedTextField'
import DateTooltipField from '../../components/DateTooltipField'
import PeriodField from '../../components/PeriodField'
import ShowCardHeader from '../../components/ShowCardHeader'
import { VEHICLE_UNAVAILABILITY_TYPES } from '../../config/vehicleUnavailabilities'
import { ShowReference, ShowReferenceLinks, useShowStyles } from '../common/show'
import OrganisationField from '../organisations/field'
import { VehicleShowLayout } from '../vehicles/show'

const VehicleUnavailabilityShowLayout = () => {
  const showClasses = useShowStyles()
  return (
    <>
      <ShowCardHeader />
      <SimpleShowLayout className={showClasses.fieldContainer}>
        <OrganisationField />
        <AdvancedTextField source="type" map={VEHICLE_UNAVAILABILITY_TYPES} />
        <PeriodField
          startedOnSource="started_on"
          endedOnSource="ended_on"
          label="resources.maintenances.fields.period"
          addTime
        />
        <DateTooltipField source="created_on" addTime />
      </SimpleShowLayout>
      <SimpleShowLayout className={showClasses.fieldContainer}>
        <AdvancedTextField source="description" fallback="resources.maintenances.show.description.fallback" multiline />
      </SimpleShowLayout>
      <ShowReferenceLinks excluded={['vehicles']} />
    </>
  )
}

const VehicleUnavailabilityAside = () => (
  <ShowReference reference="vehicles" source="vehicle_id">
    <VehicleShowLayout excluded={['organisations']} hasRedirect />
  </ShowReference>
)

export default () => (
  <AdvancedShow aside={<VehicleUnavailabilityAside />}>
    <VehicleUnavailabilityShowLayout />
  </AdvancedShow>
)
