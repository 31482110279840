import { SelectInput } from 'react-admin'

import { BOOKING_ERAS } from '../../config/bookings'

const bookingPeriodChoices = Object.entries(BOOKING_ERAS).map(([k, v]) => ({ id: k, name: v }))
export const BookingPeriodFilter = (props) => <SelectInput {...props} choices={bookingPeriodChoices} />
BookingPeriodFilter.defaultProps = {
  source: 'era',
  label: 'resources.bookings.filters.era.name',
}

const bookingCancelledChoices = [
  { value: true, name: 'resources.bookings.filters.cancelled.values.true' },
  { value: false, name: 'resources.bookings.filters.cancelled.values.false' },
]
export const BookingCancelledFilter = (props) => (
  <SelectInput {...props} choices={bookingCancelledChoices} optionValue="value" />
)
BookingCancelledFilter.defaultProps = {
  source: 'cancelled',
  label: 'resources.bookings.filters.cancelled.name',
}
