export const API_VERSION = 'application/vnd.mymove.20200722+json'
export const FIREBASE = {
  apiKey: 'AIzaSyCYOWpSpnCCMRJJuyZNM8bOHXLw79mGLFE',
  authDomain: 'mymove-221113.firebaseapp.com',
  projectId: 'mymove-221113',
  appId: '1:162025601799:web:d11d7feb4e771fdd53e3a2',
}
export const LOCALE = 'en-BE'
export const CURRENCY_CODE = 'EUR'
export const CURRENCY_SYMBOL = '€'
export const TIMEZONE = 'Europe/Brussels'
export const SENTRY_DSN = 'https://3d47f1bc5a6e19a3045e071eb42284ca@o220921.ingest.us.sentry.io/4507503761686528'
