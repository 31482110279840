import { Box } from '@mui/material'
import { RecordRepresentation, ReferenceField, useRecordContext } from 'react-admin'

import { SYSTEM_PERMISSION_READ } from '../config/permissions'
import { useResourcePermissions } from '../domain/permissions'
import { getResourceByName } from '../resources'

const ReferenceValueTarget = ({ target }) => {
  const record = useRecordContext()
  return target(record)
}

const ReferenceValue = ({ reference, source, target, emptyText = 'n/a' }) => {
  const record = useRecordContext()
  const [hasReadForReference] = useResourcePermissions(reference, SYSTEM_PERMISSION_READ)
  const referenceConfig = getResourceByName(reference)
  source = source ?? referenceConfig.options.referenceKey

  if (!hasReadForReference) {
    return emptyText ?? ''
  }
  return (
    <Box display="inline-flex">
      <ReferenceField
        emptyText={emptyText}
        link={false}
        record={record}
        reference={reference}
        source={source}
        sx={{
          '& .MuiTypography-root': {
            fontSize: 'inherit !important',
            fontWeight: 'inherit',
            fontFamily: 'inherit',
            lineHeight: 'inherit',
            letterSpacing: 'inherit',
          },
        }}
      >
        {target ? <ReferenceValueTarget target={target} /> : <RecordRepresentation />}
      </ReferenceField>
    </Box>
  )
}

export default ReferenceValue
