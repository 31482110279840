import { SelectInput } from 'react-admin'

import { VOUCHER_TYPES } from '../../config/vouchers'

const voucherTypeChoices = Object.entries(VOUCHER_TYPES).map(([k, v]) => ({ value: k, name: v }))

export const VoucherTypeFilter = (props) => <SelectInput {...props} choices={voucherTypeChoices} optionValue="value" />

VoucherTypeFilter.defaultProps = {
  source: 'type',
  label: 'resources.vouchers.fields.type',
}
