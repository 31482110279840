import { BooleanInput, useResourceContext } from 'react-admin'

import { useHelperText } from '../resources/common/forms'

const AdvancedBooleanInput = ({ label, helperText, sx, ...props }) => {
  const resource = useResourceContext()
  const helperTextFromSource = useHelperText(props.source)
  return (
    <BooleanInput
      {...props}
      label={label ?? `resources.${resource}.fields.${props.source}`}
      helperText={helperText === undefined ? helperTextFromSource : helperText}
      sx={{ margin: '16px 0 0 16px', ...sx }}
    />
  )
}

export default AdvancedBooleanInput
