import { Typography } from '@mui/material'
import { Filter, List, ReferenceField, useGetIdentity, WrapperField } from 'react-admin'
import { useTranslate } from 'ra-core'

import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import ReferenceValue from '../../components/ReferenceValue'
import StaticText from '../../components/StaticText'
import VehicleIconField from '../../components/VehicleIconField'
import { DAMAGES_CATEGORIES, DAMAGES_STATUSES } from '../../config/damages'
import { OPS_USER_ROLE_LEASING_COMPANY, SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'
import { isAllowed } from '../../utils'
import { useCurrentAccountSelectors } from '../accounts/hooks'
import { AdvancedEditInDialogButton } from '../common/edit'
import useGetOrganisationWithHubFilters from '../common/filters'
import { GenericListLayout, ListActions, ListDatagrid } from '../common/list'
import HubField from '../hubs/field'
import OrganisationField from '../organisations/field'
import usersConfig from '../users/config'
import VehicleField from '../vehicles/field'
import VehiclesFilter from '../vehicles/filter'
import damageBlueprintsConfig from '../damageBlueprints/config'
import NoteField from '../../components/NoteField'
import { AdvancedCreateButton } from '../common/create'

import DamageReportFormLayout from './form'
import config from './config'
import damageReportsExporter from './exporter'
import MarkerField from './field'
import { DamageReportsStatusFilter } from './filter'

const DamageReportsFilters = (props) => {
  const organisationWithHubFilters = useGetOrganisationWithHubFilters()
  return (
    <Filter {...props}>
      {organisationWithHubFilters}
      <VehiclesFilter alwaysOn />
      <DamageReportsStatusFilter alwaysOn />
    </Filter>
  )
}

export const DamageReportsListLayout = ({ excluded = [], ...props }) => {
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  const { hasSingleOrganisation, hasSingleHub } = useCurrentAccountSelectors()
  const translate = useTranslate()

  const commonClasses = useCommonStyles()

  return (
    <GenericListLayout
      compactListLayout={
        <CompactList
          linkType="show"
          referencesSources={{ [usersConfig.options.referenceKey]: 'reporter_id' }}
          icon={
            <ReferenceValue reference="vehicles" target={(v) => <VehicleIconField record={v} />} emptyText={null} />
          }
          title={() => (
            <>
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textPrimary">
                <ReferenceValue reference="organisations" />
              </Typography>
              {isAllowed(excluded, 'vehicles') && (
                <Typography component="span" variant="body1" className={commonClasses.inline} color="textSecondary">
                  {' • '}
                  <ReferenceValue reference="vehicles" />
                </Typography>
              )}
            </>
          )}
          body={() => (
            <>
              <StaticText>{translate('resources.hubs.name', 1)}: </StaticText>
              <ReferenceValue reference="hubs" emptyText="n/a" />
              {hasReadForUsers && (
                <>
                  <br />
                  <StaticText>{'resources.damage-reports.fields.reporter_id'} </StaticText>
                  <ReferenceValue reference="users" source="reporter_id" />
                </>
              )}
              <br />
              <StaticText>{'resources.damage-reports.fields.category'}: </StaticText>
              <AdvancedTextField
                source="category"
                fallback="resources.damage-reports.enums.category.none"
                map={DAMAGES_CATEGORIES}
              />
              <br />
              <StaticText>{'resources.damage-reports.fields.status'}: </StaticText>
              <AdvancedTextField source="status" map={DAMAGES_STATUSES} />
            </>
          )}
        />
      }
      regularListLayout={
        <ListDatagrid>
          {isAllowed(excluded, 'organisations') && !hasSingleOrganisation && <OrganisationField sortable={false} />}
          {isAllowed(excluded, 'hubs') && !hasSingleHub && <HubField sortable={false} />}
          {isAllowed(excluded, 'vehicles') && <VehicleField />}
          <AdvancedTextField
            source="category"
            fallback="resources.damage-reports.enums.category.none"
            map={DAMAGES_CATEGORIES}
          />
          <MarkerField source={damageBlueprintsConfig.options.referenceKey} containerWidth={200} markerSize={16} />
          <AdvancedTextField source="status" map={DAMAGES_STATUSES} />
          {hasReadForUsers && <ReferenceField source="reporter_id" reference="users" emptyText="n/a" />}
          <DateTooltipField source="created_on" addTime />
          <NoteField source="note" sortable={false} />
          <WrapperField source={translate('ra.action.edit')} label={false}>
            <AdvancedEditInDialogButton>
              <DamageReportFormLayout />
            </AdvancedEditInDialogButton>
          </WrapperField>
        </ListDatagrid>
      }
      {...props}
    />
  )
}

export default () => {
  const { identity } = useGetIdentity()
  const translate = useTranslate()
  return (
    <List
      actions={
        <ListActions hasExport={identity && identity.role !== OPS_USER_ROLE_LEASING_COMPANY}>
          <AdvancedCreateButton>
            <DamageReportFormLayout />
          </AdvancedCreateButton>
        </ListActions>
      }
      title={translate('resources.damage-reports.name', 2)}
      sort={config.options.defaultSort}
      filters={<DamageReportsFilters />}
      filterDefaultValues={config.options.defaultFilterValues}
      exporter={damageReportsExporter}
    >
      <DamageReportsListLayout />
    </List>
  )
}
