import { Button } from '@mui/material'
import { useTranslate } from 'ra-core'
import { Toolbar, SaveButton, CREATE, useRecordContext } from 'react-admin'

import { useCommonStyles } from '../config/theme'
import { useHandleRedirection } from '../resources/common/hooks'

const CancelButton = () => {
  const handleRedirection = useHandleRedirection()
  const record = useRecordContext()
  const translate = useTranslate()

  const onClick = async () => {
    const closeButton = document.querySelector('.RaFormDialogTitle-closeButton')
    if (closeButton) {
      closeButton.click()
    } else {
      await handleRedirection(record)
    }
  }

  return (
    <Button variant="outlined" onClick={onClick}>
      {translate('ra.action.cancel')}
    </Button>
  )
}

const BasicFormToolbar = ({ alwaysEnableSaveButton, formType }) => {
  const classes = useCommonStyles()
  return (
    <Toolbar className={classes.toolbar}>
      <SaveButton alwaysEnable={formType === CREATE || alwaysEnableSaveButton} />
      <CancelButton />
    </Toolbar>
  )
}

export default BasicFormToolbar
