import { InputAdornment } from '@mui/material'
import { get } from 'lodash'
import { useRef } from 'react'
import {
  FormDataConsumer,
  maxValue,
  minValue,
  number,
  NumberInput,
  required,
  SelectInput,
  TextInput,
  UPDATE,
  useGetIdentity,
  useRecordContext,
} from 'react-admin'
import { useTranslate } from 'ra-core'

import AdvancedDateTimeInput from '../../components/AdvancedDateTimeInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import {
  VOUCHER_DISCOUNT_TYPE_MINUTE,
  VOUCHER_DISCOUNT_TYPE_PERCENTAGE,
  VOUCHER_DISCOUNT_TYPES,
  VOUCHER_TYPE_CAMPAIGN,
  VOUCHER_TYPE_PERSONAL,
  VOUCHER_TYPES,
} from '../../config/vouchers'
import { getRoundedNow, parseDateAsDateTime, validateEndDateAfterStartDate } from '../../utils/dates'
import { useCurrentAccountSelectors } from '../accounts/hooks'
import { FormDivider } from '../common'
import { AdvancedSimpleForm } from '../common/forms'
import OrganisationReferenceInput from '../organisations/input'
import UserReferenceInput from '../users/input'

import { VoucherCodeInput } from './input'

const voucherTypeChoices = Object.entries(VOUCHER_TYPES).map(([k, v]) => ({ id: k, name: v }))
const voucherDiscountTypeChoices = Object.entries(VOUCHER_DISCOUNT_TYPES).map(([k, v]) => ({ id: k, name: v }))

const SEVEN_DAYS_HOURS = 24 * 7

const discountValueConfig = {
  [VOUCHER_DISCOUNT_TYPE_PERCENTAGE]: {
    minValue: 1,
    maxValue: 100,
    unit: '%',
    validate: [
      required(),
      number('mymove.validation.number.invalid'),
      minValue(1, 'resources.vouchers.forms.validation.discount_value.notInPercentageRange'),
      maxValue(100, 'resources.vouchers.forms.validation.discount_value.notInPercentageRange'),
    ],
  },
  [VOUCHER_DISCOUNT_TYPE_MINUTE]: {
    minValue: 1,
    maxValue: SEVEN_DAYS_HOURS,
    unit: 'mymove.units.time.hours',
    validate: [
      required(),
      number('mymove.validation.number.invalid'),
      minValue(1 * 60, 'resources.vouchers.forms.validation.discount_value.minTime'),
      maxValue(SEVEN_DAYS_HOURS * 60, 'resources.vouchers.forms.validation.discount_value.maxTime'),
    ],
  },
}

export const transformValues = ({ organisation_id, user_id, ...restValues }) =>
  restValues
    ? {
        ...restValues,
        user_id: restValues.type === VOUCHER_TYPE_PERSONAL ? user_id : null,
        organisation_id: restValues.type === VOUCHER_TYPE_PERSONAL ? organisation_id : null,
      }
    : restValues

const VoucherFormLayout = ({ type = UPDATE }) => {
  const { hasSingleOrganisation, singleOrganisationId } = useCurrentAccountSelectors()
  const now = useRef(getRoundedNow())
  const record = useRecordContext()
  const translate = useTranslate()

  const { identity } = useGetIdentity()
  if (!identity) return null
  const currentUserRestrictedToOrganisationId = identity.restricted_to_organisation_id

  const defaultValues = {
    start_date: now.current,
    type: currentUserRestrictedToOrganisationId ? VOUCHER_TYPE_PERSONAL : VOUCHER_TYPE_CAMPAIGN,
    discount_type: VOUCHER_DISCOUNT_TYPE_PERCENTAGE,
    organisation_id: currentUserRestrictedToOrganisationId || (hasSingleOrganisation ? singleOrganisationId : null),
  }
  const hasBeenUsed = record?.number_of_uses > 0

  return (
    <AdvancedSimpleForm defaultValues={defaultValues} toolbar={<BasicFormToolbar formType={type} />} type={type}>
      <VoucherCodeInput validate={required()} readOnly={type === UPDATE && hasBeenUsed} />

      <TextInput source="name" validate={required()} />

      <FormDivider />

      <SelectInput
        source="type"
        choices={voucherTypeChoices}
        validate={required()}
        readOnly={!!currentUserRestrictedToOrganisationId || type === UPDATE}
      />

      <FormDataConsumer>
        {({ formData }) =>
          formData.type === VOUCHER_TYPE_PERSONAL && (
            <>
              <UserReferenceInput readOnly={type === UPDATE} />
              <OrganisationReferenceInput readOnly={!!currentUserRestrictedToOrganisationId || type === UPDATE} />
            </>
          )
        }
      </FormDataConsumer>

      <FormDivider />

      <SelectInput
        source="discount_type"
        choices={voucherDiscountTypeChoices}
        validate={required()}
        readOnly={type === UPDATE && hasBeenUsed}
      />

      <FormDataConsumer>
        {({ formData }) => {
          const config = discountValueConfig[formData.discount_type]
          return (
            <NumberInput
              source="discount_value"
              readOnly={type === UPDATE && hasBeenUsed}
              min={config.minValue}
              max={config.maxValue}
              validate={config.validate}
              parse={(v) => (formData.discount_type === VOUCHER_DISCOUNT_TYPE_MINUTE ? v * 60 : v)}
              format={(v) => (formData.discount_type === VOUCHER_DISCOUNT_TYPE_MINUTE ? v / 60 : v)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {translate(config.unit, get(formData, 'discount_value') / 60 || 1)}
                  </InputAdornment>
                ),
              }}
            />
          )
        }}
      </FormDataConsumer>

      <FormDivider />

      <AdvancedDateTimeInput
        source="start_date"
        validate={required()}
        readOnly={
          type === UPDATE &&
          (hasBeenUsed ||
            parseDateAsDateTime(record.start_date) < parseDateAsDateTime(Date.now()).minus({ minutes: 5 }))
        }
      />

      <FormDataConsumer>
        {({ formData }) => (
          <AdvancedDateTimeInput
            source="end_date"
            minDate={formData.start_date ? parseDateAsDateTime(formData.start_date) : undefined}
            validate={(v) => validateEndDateAfterStartDate(formData.start_date, v)}
          />
        )}
      </FormDataConsumer>
    </AdvancedSimpleForm>
  )
}

export default VoucherFormLayout
