import { capitalize, get } from 'lodash'
import { useTranslate } from 'ra-core'
import { useRecordContext } from 'react-admin'

import AdvancedTextField from './AdvancedTextField'

const AdvancedArrayField = ({ source, map, ...props }) => {
  const translate = useTranslate()
  const record = useRecordContext(props)
  const values = get(record, source, [])
  const value = values
    .map((item, index) => {
      const suffix = index < values.length - 1 ? ' / ' : ''
      return translate(get(map, item, capitalize(item))) + suffix
    })
    .join('')
  return <AdvancedTextField {...props} record={{ ...record, [source]: value }} source={source} />
}

export default AdvancedArrayField
