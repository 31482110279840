import CheckedIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'

import { STATUS_SEVERITY_WARNING, STATUS_SEVERITY_CRITICAL } from '../config/statuses'

export const getStatusStyles = (status, theme) => {
  if (!status || !theme) {
    return {
      icon: null,
      mainColor: null,
      gradient: null,
    }
  }

  let icon = null
  let mainColor = null
  let fadedColor = null

  switch (status.severity) {
    case STATUS_SEVERITY_CRITICAL:
      icon = ErrorIcon
      mainColor = theme.palette.error.main
      fadedColor = theme.palette.error.faded
      break
    case STATUS_SEVERITY_WARNING:
      icon = ErrorIcon
      mainColor = theme.palette.warning[700]
      fadedColor = theme.palette.warning[500]
      break
    default:
      icon = CheckedIcon
      mainColor = theme.palette.success.main
      fadedColor = theme.palette.success.faded
  }

  return {
    icon,
    mainColor,
    gradient: `linear-gradient(${fadedColor},${mainColor})`,
  }
}
