import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { ReferenceArrayField, ReferenceField, SingleFieldList } from 'react-admin'

import { useShowStyles } from '../resources/common/show'

const useStyles = makeStyles((theme) => ({
  unorderedListItem: {
    marginBottom: theme.spacing(0.5),
  },
}))

const BookingAdditionalDriversField = ({ source }) => {
  const classes = useStyles()
  const showClasses = useShowStyles()

  return (
    <ReferenceArrayField reference="users" source={source}>
      <SingleFieldList empty="n/a" linkType={false}>
        <li className={classnames(showClasses.unorderedListItem, classes.unorderedListItem)}>
          <ReferenceField reference="users" source="id" link="show" />
        </li>
      </SingleFieldList>
    </ReferenceArrayField>
  )
}

export default BookingAdditionalDriversField
