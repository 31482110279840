import { get } from 'lodash'

import { VEHICLE_TYPE_CAR, VEHICLE_ENERGY_TYPE_ELECTRIC, VEHICLE_ENERGY_TYPE_FUEL } from '../config/vehicles'

export const isVehicleInstalled = (vehicle) => get(vehicle, 'installed', false)

export const isVehicleCar = (vehicle) => {
  const vehicleType = get(vehicle, 'type', null)
  return vehicleType === VEHICLE_TYPE_CAR
}

export const isVehicleElectricCar = (vehicle) => {
  const vehicleEnergyType = get(vehicle, 'energy_type', null)
  return isVehicleCar(vehicle) && vehicleEnergyType === VEHICLE_ENERGY_TYPE_ELECTRIC
}

export const isVehicleFuelCar = (vehicle) => {
  const vehicleEnergyType = get(vehicle, 'energy_type', null)
  return isVehicleCar(vehicle) && vehicleEnergyType === VEHICLE_ENERGY_TYPE_FUEL
}
