import classnames from 'classnames'
import { Box } from '@mui/material'
import { useState } from 'react'
import {
  CREATE,
  FormDataConsumer,
  ImageField,
  ImageInput,
  required,
  SelectInput,
  TextInput,
  UPDATE,
  useReference,
} from 'react-admin'
import { useWatch } from 'react-hook-form'
import { useTranslate } from 'ra-core'

import AdvancedReferenceInput from '../../components/AdvancedReferenceInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import { DAMAGES_CATEGORIES, DAMAGES_STATUSES } from '../../config/damages'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'
import { useSmallScreen } from '../../utils/theme'
import { FormDivider } from '../common'
import { AdvancedSimpleForm } from '../common/forms'
import damageBlueprintsConfig from '../damageBlueprints/config'
import HubReferenceInput from '../hubs/input'
import OrganisationReferenceInput from '../organisations/input'
import usersConfig from '../users/config'
import UserReferenceInput from '../users/input'
import vehiclesConfig from '../vehicles/config'
import VehicleReferenceInput from '../vehicles/input'

import MarkerInput from './input'

const damageStatusChoices = Object.entries(DAMAGES_STATUSES).map(([k, v]) => ({ id: k, name: v }))
const damageCategoryChoices = Object.entries(DAMAGES_CATEGORIES).map(([k, v]) => ({ id: k, name: v }))

const BlueprintReferenceInput = ({ readOnly }) => {
  const translate = useTranslate()
  const vehicleId = useWatch({ name: 'vehicle_id' })
  const { referenceRecord: vehicleData } = useReference({
    reference: vehiclesConfig.name,
    id: vehicleId,
    options: { enabled: Boolean(vehicleId) },
  })
  const blueprintSet = vehicleData?.blueprint_set
  return (
    <AdvancedReferenceInput
      emptyText={translate(`resources.${damageBlueprintsConfig.name}.invisible`)}
      reference={damageBlueprintsConfig.name}
      source={damageBlueprintsConfig.options.referenceKey}
      readOnly={readOnly || !Boolean(vehicleId)}
      filter={{ set: blueprintSet }}
    />
  )
}

const DamageReportFormLayout = ({ type = UPDATE }) => {
  const commonClasses = useCommonStyles()
  const isSmallScreen = useSmallScreen()
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  const [isMarkerInputPristine, setIsMarkerInputPristine] = useState(true)

  return (
    <AdvancedSimpleForm
      toolbar={<BasicFormToolbar alwaysEnableSaveButton={!isMarkerInputPristine} formType={type} />}
      type={type}
    >
      <OrganisationReferenceInput readOnly={type === UPDATE} />
      <HubReferenceInput readOnly={type === UPDATE} />
      <VehicleReferenceInput readOnly={type === UPDATE} />
      {hasReadForUsers && (
        <UserReferenceInput source="reporter_id" readOnly={type === UPDATE} validate={null} defaultValue={null} />
      )}
      <FormDivider />
      <Box display="flex" sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
        <BlueprintReferenceInput readOnly={type === UPDATE} />
        <MarkerInput onNewMarker={() => setIsMarkerInputPristine(false)} />
      </Box>
      {type === CREATE && (
        <>
          <FormDivider />
          <FormDataConsumer>
            {({ formData }) => (
              <ImageInput
                accept="image/*"
                className={classnames(
                  isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput,
                  commonClasses.imageInput,
                )}
                helperText={false}
                source="picture_data"
                validate={Boolean(formData.blueprint_id) ? required() : null}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            )}
          </FormDataConsumer>
        </>
      )}
      <FormDivider />
      <SelectInput choices={damageStatusChoices} source="status" validate={required()} />
      <SelectInput choices={damageCategoryChoices} source="category" validate={required()} />
      <FormDivider />
      <FormDataConsumer>
        {({ formData }) => (
          <TextInput multiline source="note" validate={Boolean(formData.blueprint_id) ? null : required()} />
        )}
      </FormDataConsumer>
    </AdvancedSimpleForm>
  )
}

export default DamageReportFormLayout
