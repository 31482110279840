import { useResourceContext } from 'react-admin'
import { useTranslate } from 'ra-core'

import { PLACE_DETAILS_FIELD_FORMATTED_ADDRESS } from '../config/addresses'

import AddressInputBase from './AddressInputBase'

const AddressInputMain = (props) => {
  const resource = useResourceContext()
  const translate = useTranslate()

  const getOptionLabel = (option) => {
    if (option.description) return option.description
    else if (option[PLACE_DETAILS_FIELD_FORMATTED_ADDRESS]) return option[PLACE_DETAILS_FIELD_FORMATTED_ADDRESS]
    return ''
  }

  return (
    <AddressInputBase
      getOptionLabel={getOptionLabel}
      label={translate(props.label || `resources.${resource}.fields.${props.source}`)}
      {...props}
    />
  )
}

export default AddressInputMain
