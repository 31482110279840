import { alpha } from '@mui/material/styles'
import { Filter, List, useGetIdentity, useResourceContext } from 'react-admin'
import { useTranslate } from 'ra-core'

import AdvancedSearchFilter from '../../components/AdvancedSearchFilter'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import { getUserFullName } from '../../components/UserFullNameField'
import { OPS_USER_ROLES_NAMES, SYSTEM_PERMISSION_DELETE } from '../../config/permissions'
import { darkTheme } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'
import { GenericListLayout, ListActions, ListDatagrid } from '../common/list'
import OrganisationField from '../organisations/field'
import { AdvancedCreateButton } from '../common/create'
import { AdvancedEditInDialogButton } from '../common/edit'

import OpsUserFormLayout from './form'
import { DeleteOpsUserButton } from './buttons'
import config from './config'

const OpsUsersFilters = (props) => {
  return (
    <Filter {...props}>
      <AdvancedSearchFilter alwaysOn />
    </Filter>
  )
}

const opsUserRowSx = (record, currentUserId) => {
  const isCurrentUser = record.id === currentUserId
  return {
    backgroundColor: isCurrentUser ? alpha(darkTheme.palette.secondary.main, 0.1) : 'none',
    '&:hover': {
      backgroundColor: isCurrentUser ? `${alpha(darkTheme.palette.secondary.main, 0.2)} !important` : undefined,
    },
  }
}

const OpsUsersListLayout = () => {
  const { identity } = useGetIdentity()
  const currentUserId = identity?.id
  const translate = useTranslate()

  const resource = useResourceContext()
  const [hasDelete] = useResourcePermissions(resource, SYSTEM_PERMISSION_DELETE)

  return (
    <GenericListLayout
      compactListLayout={
        <CompactList
          linkType="show"
          itemSx={(record) => opsUserRowSx(record, currentUserId)}
          icon={(record) => getUserFullName(record, { showInitials: true })}
          title={(record) => getUserFullName(record)}
          body={() => (
            <>
              <AdvancedTextField source="email" />
              <br />
              <AdvancedTextField source="role" map={OPS_USER_ROLES_NAMES} />
            </>
          )}
        />
      }
      regularListLayout={
        <ListDatagrid rowSx={(record) => opsUserRowSx(record, currentUserId)}>
          <AdvancedTextField source="first_name" />
          <AdvancedTextField source="last_name" />
          <AdvancedTextField source="email" />
          <AdvancedTextField source="role" map={OPS_USER_ROLES_NAMES} />
          <OrganisationField
            sortable={false}
            source="restricted_to_organisation_id"
            emptyText={translate('resources.ops-users.allOrganisations')}
          />
          <DateTooltipField source="created_on" addTime />
          <AdvancedEditInDialogButton>
            <OpsUserFormLayout />
          </AdvancedEditInDialogButton>
          {hasDelete && <DeleteOpsUserButton condition={(record) => record.id !== currentUserId} />}
        </ListDatagrid>
      }
    />
  )
}

export default () => {
  return (
    <List
      sort={config.options.defaultSort}
      filters={<OpsUsersFilters />}
      actions={
        <ListActions>
          <AdvancedCreateButton>
            <OpsUserFormLayout />
          </AdvancedCreateButton>
        </ListActions>
      }
    >
      <OpsUsersListLayout />
    </List>
  )
}
