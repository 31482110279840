export const ENV_NAME = 'louezunvehicule'
export const HOST_NAME = 'ops.louez-un-vehicule.mymove.be'
export const API_URL = 'https://louez-un-vehicule.mymove.be/ops-api'
export const FIREBASE = {
  apiKey: 'AIzaSyCfkuof3Ph1CT0s1p2_2t0bY4SQJXieRKw',
  authDomain: 'louez-un-vehicule.firebaseapp.com',
  projectId: 'louez-un-vehicule',
  appId: '1:1003662209141:web:a9f44bc008084b7224083c',
}
export const LOCALE = 'en-BE'
export const CURRENCY_CODE = 'EUR'
export const CURRENCY_SYMBOL = '€'
export const TIMEZONE = 'Europe/Brussels'
