import { ReferenceField } from 'react-admin'

import AdvancedTextField from '../../components/AdvancedTextField'

import config from './config'

const VehicleField = (props) => (
  <ReferenceField {...props}>
    <AdvancedTextField source="designation" />
  </ReferenceField>
)

VehicleField.defaultProps = {
  source: config.options.referenceKey,
  reference: config.name,
  link: 'show',
}

export default VehicleField
