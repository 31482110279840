import Icon from '@mui/icons-material/People'

import { getUserFullName } from '../../components/UserFullNameField'
import { USER_ACTIONS } from '../../config/users'

export default {
  name: 'users',
  recordRepresentation: getUserFullName,
  icon: Icon,
  options: {
    referenceKey: 'user_id',
    defaultSort: { field: 'last_name', order: 'ASC' },
    defaultFilterValues: { closed: false },
    actions: USER_ACTIONS,
    badge: true,
  },
}
