import get from 'lodash/get'

import { BOOKING_ERA_PAST, BOOKING_BILLING_TYPE_PAID, BOOKING_BILLING_TYPE_SOURCE } from '../config/bookings'

export const isBookingPast = (record) => get(record, 'era') === BOOKING_ERA_PAST

export const wasBookingCancelled = (record) => Boolean(get(record, 'cancelled_on'))

export const isBookingEditable = (record) => {
  if (!record) {
    return false
  }
  return record.era && !isBookingPast(record) && !wasBookingCancelled(record)
}

export const isBookingPaid = (record) => get(record, BOOKING_BILLING_TYPE_SOURCE) === BOOKING_BILLING_TYPE_PAID
