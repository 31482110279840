import { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useRecordContext } from 'react-admin'

import { COMMON_INPUT_WIDTH, THEME_MODE_DARK } from '../config/theme'

const BLUEPRINT_TYPE_SMALL = 'SMALL'
const BLUEPRINT_TYPE_LARGE = 'LARGE'

const getMarkerStyle = (markerSize, light) => ({
  height: `${markerSize}px`,
  width: `${markerSize}px`,
  backgroundColor: '#c61616',
  opacity: light ? 0.4 : 0.8,
  borderRadius: '50%',
  pointerEvents: 'none',
})

const getRatioWithoutOverflow = (ratio, imageDimension, markerSize) => {
  const minRatio = markerSize / 2 / imageDimension
  let newRatio = ratio
  if (newRatio < minRatio) {
    newRatio = minRatio
  } else if (newRatio > 1 - minRatio) {
    newRatio = 1 - minRatio
  }
  return newRatio
}

const renderMarker = (width, height, xRatio, yRatio, markerSize, light = false) => {
  if (width && height) {
    const style = getMarkerStyle(markerSize, light)
    const newXRatio = getRatioWithoutOverflow(xRatio, width, markerSize)
    const newYRatio = getRatioWithoutOverflow(yRatio, height, markerSize)
    return (
      <div
        key={newXRatio + newYRatio}
        style={{
          ...style,
          position: 'absolute',
          left: newXRatio * width - markerSize / 2,
          top: newYRatio * height - markerSize / 2,
        }}
      />
    )
  }
}

const useStyles = makeStyles((theme) => ({
  blueprint: {
    width: '100%',
    height: '100%',
    filter: theme.palette.mode === THEME_MODE_DARK ? 'invert(100%)' : 'none',
  },
}))

const Blueprint = (props) => {
  const record = useRecordContext()
  const { blueprint, containerWidth, editable, markerSize, onNewMarker, reports } = props
  const { url, type, width: blueprintWidth, height: blueprintHeight } = blueprint || record

  let containerFinalWidth
  if (type === BLUEPRINT_TYPE_SMALL) {
    containerFinalWidth = containerWidth / 2
  } else if (type === BLUEPRINT_TYPE_LARGE) {
    containerFinalWidth = containerWidth
  }
  const containerFinalHeight = containerFinalWidth / (blueprintWidth / blueprintHeight)
  const classes = useStyles()

  const [newMarker, setNewMarker] = useState(false)
  useEffect(() => {
    newMarker && onNewMarker(newMarker)
  })

  if (record === null) {
    return null
  }

  return (
    <div
      style={{
        position: 'relative',
        width: containerFinalWidth,
        height: containerFinalHeight,
      }}
    >
      <img
        src={url}
        alt="blueprint"
        className={classes.blueprint}
        onClick={(e) => {
          const xRatio = e.nativeEvent.offsetX / containerFinalWidth
          const yRatio = e.nativeEvent.offsetY / containerFinalHeight
          editable && setNewMarker({ xRatio, yRatio })
        }}
      />
      {reports.map((report) =>
        renderMarker(
          containerFinalWidth,
          containerFinalHeight,
          report.marker_x_ratio,
          report.marker_y_ratio,
          markerSize,
          editable,
        ),
      )}
      {newMarker &&
        renderMarker(containerFinalWidth, containerFinalHeight, newMarker.xRatio, newMarker.yRatio, markerSize)}
    </div>
  )
}

Blueprint.defaultProps = {
  blueprint: null,
  reports: [],
  containerWidth: COMMON_INPUT_WIDTH,
  markerSize: 20,
  editable: false,
  onNewMarker: () => {},
}

export default Blueprint
