import { useRedirect, useResourceContext, useResourceDefinitions } from 'react-admin'
import { useNavigate } from 'react-router-dom'

export const useHandleRedirection = () => {
  const navigate = useNavigate()
  const redirect = useRedirect()
  const resource = useResourceContext()
  const resources = useResourceDefinitions()

  const handleRedirection = async (record) => {
    if (record?.id && resources[resource].hasShow) {
      await redirect('show', resource, record.id)
    } else if (resources[resource].hasList) {
      await redirect('list', resource)
    } else {
      await navigate(-1)
    }
  }
  return handleRedirection
}
