import { isEmpty } from 'lodash'
import { makeStyles } from '@mui/styles'
import { useCallback, useState } from 'react'
import { required, SelectInput, TextInput, useTranslate } from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'
import ReplayIcon from '@mui/icons-material/Replay'
import { IconButton, Tooltip } from '@mui/material'

import {
  BOOKING_BILLING_SETTINGS_SOURCE,
  BOOKING_BILLING_TYPE_AUTO,
  BOOKING_BILLING_TYPE_FREE,
  BOOKING_BILLING_TYPE_PRO,
  BOOKING_BILLING_TYPE_SOURCE,
  BOOKING_MAKE_BOOKING_FREE_SOURCE,
} from '../config/bookings'
import { ACCOUNT_FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_SOURCE } from '../config/accounts'

const useStyles = makeStyles({
  resetButton: {
    width: 26,
    height: 26,
    marginLeft: 6,
    marginTop: 15,
    marginRight: -8,
  },
  resetIcon: {
    width: 20,
    height: 20,
  },
})

const OPTION_CODE_OTHER = 'OTHER'

const BookingJustificationInput = ({ bookingsJustifications, source }) => {
  const [justification, billingType, billingSettings, makeBookingFree] = useWatch({
    name: [source, BOOKING_BILLING_TYPE_SOURCE, BOOKING_BILLING_SETTINGS_SOURCE, BOOKING_MAKE_BOOKING_FREE_SOURCE],
  })
  const classes = useStyles()
  const translate = useTranslate()
  const { setValue } = useFormContext()

  const hasBookingsJustifications = !isEmpty(bookingsJustifications)
  const [optionCode, setOptionCode] = useState(
    Boolean(justification) && hasBookingsJustifications
      ? bookingsJustifications.find((j) => j.code === justification)?.code ?? OPTION_CODE_OTHER
      : null,
  )

  const onSelectInputChange = useCallback(
    ({ target: { value } }) => {
      setOptionCode(value)
      if (value === OPTION_CODE_OTHER) {
        setValue(source, null)
      }
    },
    [setValue, source],
  )

  const resetInput = useCallback(() => {
    setValue(source, null)
    setOptionCode(null)
  }, [setValue, source])

  const isBillingTypeFree = makeBookingFree || billingType === BOOKING_BILLING_TYPE_FREE
  const shouldDisplayJustificationInput =
    [BOOKING_BILLING_TYPE_PRO, BOOKING_BILLING_TYPE_AUTO].includes(billingType) ||
    (isBillingTypeFree && billingSettings[ACCOUNT_FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_SOURCE])

  if (!shouldDisplayJustificationInput || !Boolean(billingSettings)) {
    return null
  }

  const requiredErrorMessage = isBillingTypeFree
    ? 'resources.bookings.forms.validation.justification.requiredForFreeBookings'
    : 'resources.bookings.forms.validation.justification.requiredForProAutoBookings'
  const commonProps = { source, validate: required(requiredErrorMessage) }

  const bookingsJustificationsChoices = bookingsJustifications
    .sort((a, b) => (a.code === OPTION_CODE_OTHER) - (b.code === OPTION_CODE_OTHER)) // put 'OTHER' option at the end
    .map(({ code, label }) => ({ id: code, name: label }))

  return hasBookingsJustifications && optionCode !== OPTION_CODE_OTHER ? (
    <SelectInput
      {...commonProps}
      choices={bookingsJustificationsChoices}
      onChange={onSelectInputChange}
      value={optionCode}
    />
  ) : (
    <>
      <TextInput {...commonProps} />
      {hasBookingsJustifications && optionCode === OPTION_CODE_OTHER && (
        <Tooltip title={translate('mymove.goBackToList')}>
          <IconButton className={classes.resetButton} onClick={resetInput}>
            <ReplayIcon className={classes.resetIcon} />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

export default BookingJustificationInput
