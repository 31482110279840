import { Tooltip, Typography } from '@mui/material'
import FalseIcon from '@mui/icons-material/Clear'
import TrueIcon from '@mui/icons-material/Done'
import { makeStyles } from '@mui/styles'
import get from 'lodash/get'
import { useTranslate } from 'ra-core'
import { useRecordContext } from 'react-admin'

const useStyles = makeStyles({
  icon: {
    verticalAlign: 'bottom',
    marginRight: '0.25em',
  },
})

const AdvancedBooleanField = ({
  className,
  color,
  falseIcon,
  falseLabel,
  falseTooltip = '',
  overrideLabel,
  source,
  sx,
  tooltip: defaultTooltip = '',
  trueIcon,
  trueLabel,
  trueTooltip = '',
  ...props
}) => {
  const record = useRecordContext(props)
  const translate = useTranslate()
  const classes = useStyles()
  const isTrue = Boolean(get(record, source))
  const tooltip = (isTrue ? trueTooltip : falseTooltip) || defaultTooltip
  const Icon = isTrue ? trueIcon : falseIcon
  const label = overrideLabel || (isTrue ? trueLabel : falseLabel)

  return (
    <Typography component="span" variant="body2" className={className} color={color} sx={sx}>
      <Tooltip title={translate(tooltip)}>
        <span>
          {Icon && <Icon className={classes.icon} />}
          {translate(label)}
        </span>
      </Tooltip>
    </Typography>
  )
}

AdvancedBooleanField.defaultProps = {
  trueIcon: TrueIcon,
  falseIcon: FalseIcon,
  trueLabel: 'ra.boolean.true',
  falseLabel: 'ra.boolean.false',
}

export default AdvancedBooleanField
