const mocks = {}

export const mock =
  (name, func) =>
  (...args) => {
    const resource = args[0]
    if (mocks?.[resource]?.[name]) {
      console.log('Mocked', name, ...args)
      return mocks[resource][name](...args)
    }
    return func(...args)
  }
