import { parsePhoneNumberFromString } from 'libphonenumber-js/max'

import env from '../config/env'
import { BOOKING_MOBILITY_BUDGET_BILLING_TYPES } from '../config/bookings'

export const DEFAULT_COUNTRY_CODE = 'BE'

const getHexFromComponent = (c) => {
  const hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

const getHexFromRGB = (r, g, b) => {
  return '#' + getHexFromComponent(r) + getHexFromComponent(g) + getHexFromComponent(b)
}

const getRGBFromHSL = (h, s, l) => {
  var r, g, b

  if (s === 0) {
    r = g = b = l // achromatic
  } else {
    var hue2rgb = function hue2rgb(p, q, t) {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s
    var p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
}

export const getHexFromHSL = (h, s, l) => {
  const [r, g, b] = getRGBFromHSL(h, s, l)
  return getHexFromRGB(r, g, b)
}

export const getRandomString = (length = '6') => {
  let randomString = ''
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  for (let i = 0; i < length; i++) {
    randomString += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return randomString
}

export const parsePhoneNumber = (value, countryCode, acceptedTypes = ['FIXED_LINE_OR_MOBILE', 'MOBILE']) => {
  const parsed = parsePhoneNumberFromString(value, countryCode ?? DEFAULT_COUNTRY_CODE)
  if (parsed && parsed.isValid() && acceptedTypes.includes(parsed.getType())) {
    return parsed.format('E.164')
  }
  return null
}

export const toSentence = (words) => words.join(', ')

export const isAllowed = (excluded, item) => !excluded.includes(item)

export const isDisabled = (disabled, item) => disabled.includes(item)

export const serializeFile = (file) => {
  const serializeData = (file, params = {}) => ({ 'mime-type': file.type, ...params })
  return new Promise((resolve, reject) => {
    if (!file) {
      reject('No file to serialize')
      return
    }
    const reader = new FileReader()
    reader.onload = () => resolve(serializeData(file, { data: reader.result.split(';base64,')[1] }))
    reader.onerror = () => reject('Cannot read file')
    reader.readAsDataURL(file)
  })
}

export const formatPrice = (priceInCents) => new Intl.NumberFormat(env.LOCALE).format(priceInCents / 100)

export const formatUnit = (unit, value) => (value < -1 || value > 1 || (!value && value !== 0) ? unit + 's' : unit)

export const renameKeysInObject = (object, keys) => {
  // The "keys" argument needs to be an array of arrays, with old key & new key inside nested arrays
  // In other words, keys = [[oldKey1, newKey1], [oldKey2, newKey2], [oldKey3, newKey3]]
  const newObject = Object.assign({}, object)
  keys.forEach(([oldKey, newKey]) => {
    if (Boolean(newObject[oldKey])) {
      Object.defineProperty(newObject, newKey, Object.getOwnPropertyDescriptor(newObject, oldKey))
      delete newObject[oldKey]
    }
  })
  return newObject
}

export const hasBillingType = (billingTypesArray, billingType) => billingTypesArray?.indexOf(billingType) > -1

export const hasMobilityBudgetBillingType = (billingTypesArray) =>
  BOOKING_MOBILITY_BUDGET_BILLING_TYPES.some((billingType) => hasBillingType(billingTypesArray, billingType))

export const hasOnlyBillingType = (billingTypesArray, billingType) =>
  hasBillingType(billingTypesArray, billingType) && billingTypesArray?.length === 1
