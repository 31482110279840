import { SimpleShowLayout, useGetRecordRepresentation, useRecordContext, useResourceContext } from 'react-admin'
import { useTranslate } from 'ra-core'
import React from 'react'

import { CardTitle } from '../common'
import { ListReference } from '../common/list'
import { useShowStyles } from '../common/show'
import eventsConfig from '../events/config'
import { EventsListLayout } from '../events/list'
import OrganisationField from '../organisations/field'
import AdvancedTextField from '../../components/AdvancedTextField'
import AdvancedShow from '../../components/AdvancedShow'
import DateTooltipField from '../../components/DateTooltipField'
import ShowCardHeader from '../../components/ShowCardHeader'
import { OPS_USER_ROLES_NAMES } from '../../config/permissions'

const OpsUserShowLayout = () => {
  const showClasses = useShowStyles()
  const translate = useTranslate()

  return (
    <>
      <ShowCardHeader />
      <div className={showClasses.expanded}>
        <SimpleShowLayout className={showClasses.fieldContainer}>
          <AdvancedTextField source="first_name" />
          <AdvancedTextField source="last_name" />
          <AdvancedTextField source="email" />
          <AdvancedTextField source="role" map={OPS_USER_ROLES_NAMES} />
          <OrganisationField
            sortable={false}
            source="restricted_to_organisation_id"
            emptyText={translate('resources.ops-users.allOrganisations')}
          />
          <DateTooltipField source="created_on" addTime />
        </SimpleShowLayout>
      </div>
    </>
  )
}

const OpsUserAside = () => {
  const record = useRecordContext()
  const resource = useResourceContext()
  const getRecordRepresentation = useGetRecordRepresentation(resource)
  return (
    <ListReference reference={eventsConfig.name} target="author_id" sort={eventsConfig.options.defaultSort}>
      <EventsListLayout
        excludedFields={['author', 'channel']}
        title={
          <CardTitle
            text={`resources.events.show.activity-performed-by`}
            args={{ name: getRecordRepresentation(record) }}
          />
        }
      />
    </ListReference>
  )
}

export default () => (
  <AdvancedShow aside={<OpsUserAside />}>
    <OpsUserShowLayout />
  </AdvancedShow>
)
