import { Box, Tooltip, Typography } from '@mui/material'
import { useTranslate } from 'ra-core'
import { useRecordContext } from 'react-admin'

import { VEHICLE_TYPES, VEHICLE_TYPES_ICONS } from '../config/vehicles'

const VehicleIconField = ({ color, source, ...props }) => {
  const translate = useTranslate()
  const record = useRecordContext(props)
  const type = record[source]
  const tooltip = VEHICLE_TYPES[type]
  const icon = VEHICLE_TYPES_ICONS[type]
  return icon ? (
    <Typography component="span" variant="body2" color={color}>
      <Tooltip title={translate(tooltip)}>
        <Box sx={{ marginTop: { xs: '4px', md: 0 } }}>
          <icon.type />
        </Box>
      </Tooltip>
    </Typography>
  ) : null
}

VehicleIconField.defaultProps = {
  source: 'type',
}

export default VehicleIconField
