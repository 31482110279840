import { onAuthStateChanged } from 'firebase/auth'
import { fetchUtils } from 'react-admin'

import env from '../config/env'

export default (firebaseClient) => {
  const onAuthLoad = () =>
    new Promise((resolve) => {
      onAuthStateChanged(firebaseClient.auth, resolve)
    })

  return async (url, options = {}) => {
    await onAuthLoad()
    const currentUser = firebaseClient.auth.currentUser
    if (currentUser) {
      const token = await currentUser.getIdToken()
      if (token) {
        options.user = {
          authenticated: true,
          token: `Bearer ${token}`,
        }
      }
    }

    options.headers = new Headers({
      ...Object.fromEntries(options?.headers ?? []),
      Accept: env.API_VERSION,
      'x-app-code': env.ENV_NAME,
    })

    return fetchUtils.fetchJson(url, options)
  }
}
