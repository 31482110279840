import { isEmpty } from 'lodash'
import { useGetOne } from 'react-admin'

import bookingDetailsConfig from '../bookingDetails/config'
import { AdvancedCreateButton } from '../common/create'

import { useGetTransactionsListForBooking } from './hooks'
import BookingTransactionFormLayout, { transformValues } from './form'
import config from './config'

export const CreateBookingTransactionButton = ({ bookingId }) => {
  const { data: bookingDetails } = useGetOne(
    bookingDetailsConfig.name,
    { id: bookingId },
    { enabled: Boolean(bookingId) },
  )
  const { data: bookingTransactions } = useGetTransactionsListForBooking(bookingId)

  return !isEmpty(bookingDetails?.allowed_transaction_actions) && !isEmpty(bookingTransactions) ? (
    <AdvancedCreateButton
      defaultValues={{ booking_id: bookingId }}
      isFloatingOnSmallScreen={false}
      resource={config.name}
      transform={transformValues}
    >
      <BookingTransactionFormLayout />
    </AdvancedCreateButton>
  ) : null
}
