import { useEffect } from 'react'

import { useApi } from '../../api/apiProvider'
import {
  PAYOUT_SETUP_STATUS_COMPLETED,
  PAYOUT_SETUP_STATUS_IN_PROGRESS,
  PAYOUT_SETUP_STATUS_NOT_STARTED,
} from '../../config/payout'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'

import config, { PAYOUT_API_REPORTS_PATH, PAYOUT_API_SELLER_ACCOUNT_PATH, PAYOUT_API_SETTINGS_PATH } from './config'

export const useGetPayoutSellerAccount = (includes = []) => {
  const [hasRead] = useResourcePermissions(config.name, SYSTEM_PERMISSION_READ)
  const [fetchSellerAccount, { data: sellerAccount }] = useApi(PAYOUT_API_SELLER_ACCOUNT_PATH, {
    method: 'GET',
    params: { _fragments: ['kycstatus', 'ubo', 'extacc', 'accounts', 'docs', ...includes].join(',') },
  })
  useEffect(() => {
    if (hasRead) fetchSellerAccount()
  }, [hasRead, fetchSellerAccount])
  return sellerAccount
}

export const useGetPayoutSettings = () => {
  const [hasRead] = useResourcePermissions(config.name, SYSTEM_PERMISSION_READ)
  const [fetchSettings, { data: settings }] = useApi(PAYOUT_API_SETTINGS_PATH, { method: 'GET' })
  useEffect(() => {
    if (hasRead) fetchSettings()
  }, [hasRead, fetchSettings])
  return settings
}

export const useGetPayoutReports = () => {
  const [hasRead] = useResourcePermissions(config.name, SYSTEM_PERMISSION_READ)
  const [fetchReports, { data: reports }] = useApi(PAYOUT_API_REPORTS_PATH, { method: 'GET' })
  useEffect(() => {
    if (hasRead) fetchReports()
  }, [hasRead, fetchReports])
  return reports
}

export const useGetPayoutSetupStatus = () => {
  const sellerAccount = useGetPayoutSellerAccount()
  let status
  // If we receive a 404 error, we consider that setup has not started
  if (sellerAccount === null) status = PAYOUT_SETUP_STATUS_NOT_STARTED
  // We set status according to status codes that we receive from the backend (coming from Payaut)
  // When we create a seller account, we receive "FAILED" as status code in the response coming from Payaut
  // To let the ops user go on with the flow, we consider "FAILED" as "IN_PROGRESS"
  if (sellerAccount?.status?.code) {
    switch (sellerAccount.status.code) {
      case 'MISSING_DATA':
      case 'IN_PROGRESS':
      case 'FAILED':
        status = PAYOUT_SETUP_STATUS_IN_PROGRESS
        break
      case 'ACTIVE':
        status = PAYOUT_SETUP_STATUS_COMPLETED
        break
      default:
        break
    }
  }
  return { status }
}
