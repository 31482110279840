import RejectedIcon from '@mui/icons-material/Clear'
import AcceptedIcon from '@mui/icons-material/Done'
import MissingIcon from '@mui/icons-material/HelpOutline'
import PendingIcon from '@mui/icons-material/HourglassEmptyRounded'

import AccountOffIcon from '../components/icons/AccountOffIcon'
import AccountReactivateIcon from '../components/icons/AccountReactivateIcon'
import { deactivateAccountConfirm, reactivateAccountConfirm } from '../resources/users/actions'

// Sources for fields & inputs

export const ACCOUNT_BILLING_OVERRIDE_SOURCE = 'account_billing_override'
export const HAS_MOBILITY_BUDGET_OVERRIDE_SOURCE = 'has_mobility_budget_override'
export const REQUIRES_PAYMENT_OVERRIDE_SOURCE = 'requires_payment_override'
export const BILLING_TYPES_OVERRIDE_SOURCE = 'billing_types_override'
export const DEFAULT_BILLING_TYPE_OVERRIDE_SOURCE = 'default_billing_type_override'
export const FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_OVERRIDE_SOURCE =
  'free_billing_type_requires_justification_override'

// User roles & permissions

export const USER_ROLE_USER = 'USER'
export const USER_ROLE_SUPERUSER = 'SUPERUSER'

export const USER_ROLES = {
  [USER_ROLE_USER]: 'resources.users.enums.roles.user.value',
  [USER_ROLE_SUPERUSER]: 'resources.users.enums.roles.superUser.value',
}

export const USERS_PERMISSIONS = [
  {
    role: 'resources.users.enums.roles.superUser.role',
    permission: 'resources.users.enums.roles.superUser.permission',
  },
  {
    role: 'resources.users.forms.helperTexts.is_trusted.role',
    permission: 'resources.users.forms.helperTexts.is_trusted.permission',
  },
]

// Language for SMS at user creation

export const USER_SMS_LANGUAGE_EN = 'en'
export const USER_SMS_LANGUAGE_FR = 'fr'
export const USER_SMS_LANGUAGE_NL = 'nl'
export const USER_SMS_LANGUAGE_DE = 'de'

export const USER_SMS_LANGUAGES = {
  [USER_SMS_LANGUAGE_EN]: 'resources.users.enums.smsLanguages.english',
  [USER_SMS_LANGUAGE_FR]: 'resources.users.enums.smsLanguages.french',
  [USER_SMS_LANGUAGE_NL]: 'resources.users.enums.smsLanguages.dutch',
  [USER_SMS_LANGUAGE_DE]: 'resources.users.enums.smsLanguages.german',
}

// Document types

export const USER_DOCUMENT_TYPE_IDENTIFICATION = 'IDENTIFICATION'
export const USER_DOCUMENT_TYPE_SELFIE = 'SELFIE'
export const USER_DOCUMENT_TYPE_CAR_DRIVER_LICENSE = 'CAR_DRIVER_LICENSE'

export const USER_DOCUMENT_TYPES = {
  [USER_DOCUMENT_TYPE_IDENTIFICATION]: 'resources.users.enums.documentTypes.idCard',
  [USER_DOCUMENT_TYPE_SELFIE]: 'resources.users.enums.documentTypes.selfie',
  [USER_DOCUMENT_TYPE_CAR_DRIVER_LICENSE]: 'resources.users.enums.documentTypes.driverLicense',
}

// Document statuses

export const USER_DOCUMENT_STATUS_MISSING = 'MISSING'
export const USER_DOCUMENT_STATUS_PENDING = 'PENDING'
export const USER_DOCUMENT_STATUS_REJECTED = 'REJECTED'
export const USER_DOCUMENT_STATUS_ACCEPTED = 'ACCEPTED'

export const USER_DOCUMENT_STATUSES = {
  [USER_DOCUMENT_STATUS_MISSING]: 'resources.users.enums.documentStatus.missing',
  [USER_DOCUMENT_STATUS_PENDING]: 'resources.users.enums.documentStatus.pending',
  [USER_DOCUMENT_STATUS_REJECTED]: 'resources.users.enums.documentStatus.rejected',
  [USER_DOCUMENT_STATUS_ACCEPTED]: 'resources.users.enums.documentStatus.accepted',
}

export const USER_DOCUMENT_STATUSES_ICONS = {
  [USER_DOCUMENT_STATUS_MISSING]: MissingIcon,
  [USER_DOCUMENT_STATUS_PENDING]: PendingIcon,
  [USER_DOCUMENT_STATUS_REJECTED]: RejectedIcon,
  [USER_DOCUMENT_STATUS_ACCEPTED]: AcceptedIcon,
}

// Payment method statuses

export const USER_PAYMENT_METHOD_STATUS_VALID = 'VALID'
export const USER_PAYMENT_METHOD_STATUS_INVALID = 'INVALID'
export const USER_PAYMENT_METHOD_STATUS_EXPIRED = 'EXPIRED'
export const USER_PAYMENT_METHOD_STATUS_BLOCKED = 'BLOCKED'
export const USER_PAYMENT_METHOD_STATUS_INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS'

export const USER_PAYMENT_METHOD_STATUSES = {
  [USER_PAYMENT_METHOD_STATUS_VALID]: 'resources.users.enums.paymentMethodStatus.valid',
  [USER_PAYMENT_METHOD_STATUS_INVALID]: 'resources.users.enums.paymentMethodStatus.invalid',
  [USER_PAYMENT_METHOD_STATUS_EXPIRED]: 'resources.users.enums.paymentMethodStatus.expired',
  [USER_PAYMENT_METHOD_STATUS_BLOCKED]: 'resources.users.enums.paymentMethodStatus.blocked',
  [USER_PAYMENT_METHOD_STATUS_INSUFFICIENT_FUNDS]: 'resources.users.enums.paymentMethodStatus.insufficientFunds',
}

// Accounts

export const USER_ACCOUNTS_CLOSED_BY_GRAPHQL = 'GRAPHQL'
export const USER_ACCOUNTS_CLOSED_BY_INTERNAL = 'INTERNAL'
export const USER_ACCOUNTS_CLOSED_BY_OPS = 'OPS'
export const USER_ACCOUNTS_CLOSED_BY_PAPI = 'PAPI'

export const USER_ACCOUNTS_CLOSED_BY = {
  [USER_ACCOUNTS_CLOSED_BY_GRAPHQL]: 'resources.users.enums.accountClosedBy.user',
  [USER_ACCOUNTS_CLOSED_BY_INTERNAL]: 'resources.users.enums.accountClosedBy.system',
  [USER_ACCOUNTS_CLOSED_BY_OPS]: 'resources.users.enums.accountClosedBy.dashboardUser',
  [USER_ACCOUNTS_CLOSED_BY_PAPI]: 'resources.users.enums.accountClosedBy.externalApi',
}

export const USER_ACCOUNTS_CLOSING_REASON_USER_REQUEST = 'USER_REQUEST'
export const USER_ACCOUNTS_CLOSING_REASON_SUSPICIOUS_ACTIVITY = 'SUSPICIOUS_ACTIVITY'

export const USER_ACCOUNTS_CLOSING_REASONS = {
  [USER_ACCOUNTS_CLOSING_REASON_USER_REQUEST]: 'resources.users.enums.accountClosingReasons.userRequest',
  [USER_ACCOUNTS_CLOSING_REASON_SUSPICIOUS_ACTIVITY]: 'resources.users.enums.accountClosingReasons.suspiciousActivity',
}

// Actions

export const USER_ACTION_DEACTIVATE_ACCOUNT = 'CLOSE_USER_ACCOUNT'
export const USER_ACTION_REACTIVATE_ACCOUNT = 'REOPEN_USER_ACCOUNT'

export const USER_ACTIONS = {
  [USER_ACTION_DEACTIVATE_ACCOUNT]: {
    name: 'deactivateAccount',
    label: 'resources.users.actions.deactivateAccount.label',
    icon: AccountOffIcon,
    refresh: true,
    confirm: deactivateAccountConfirm,
  },
  [USER_ACTION_REACTIVATE_ACCOUNT]: {
    name: 'reactivateAccount',
    label: 'resources.users.actions.reactivateAccount.label',
    icon: AccountReactivateIcon,
    refresh: true,
    confirm: reactivateAccountConfirm,
  },
}

// Identification data keys & labels

export const USER_IDENTIFICATION_DATA_KEY_FULL_NAME = 'full_name'
export const USER_IDENTIFICATION_DATA_KEY_DATE_OF_BIRTH = 'date_of_birth'
export const USER_IDENTIFICATION_DATA_KEY_DOCUMENT_NUMBER = 'document_number'
export const USER_IDENTIFICATION_DATA_KEY_COUNTRY = 'country'
export const USER_IDENTIFICATION_DATA_KEY_NATIONAL_REGISTRATION_NUMBER = 'national_registration_number'

export const USER_IDENTIFICATION_DATA_LABELS = {
  [USER_IDENTIFICATION_DATA_KEY_FULL_NAME]: 'resources.users.show.identificationData.fullName',
  [USER_IDENTIFICATION_DATA_KEY_DATE_OF_BIRTH]: 'resources.users.show.identificationData.dateOfBirth',
  [USER_IDENTIFICATION_DATA_KEY_DOCUMENT_NUMBER]: 'resources.users.show.identificationData.documentNumber',
  [USER_IDENTIFICATION_DATA_KEY_COUNTRY]: 'resources.users.show.identificationData.countryCode',
  [USER_IDENTIFICATION_DATA_KEY_NATIONAL_REGISTRATION_NUMBER]:
    'resources.users.show.identificationData.nationalRegistrationNumber',
}

// User status codes

export const USER_STATUS_CODE_NEGATIVE_BALANCE = 'NEGATIVE_BALANCE'

export const USER_STATUS_CODES = {
  [USER_STATUS_CODE_NEGATIVE_BALANCE]: 'resources.users.enums.statusCodes.negativePaymentBalance',
}

// User NFC cards

export const USER_NFC_CARD_TYPE_AWID = 'AWID'
export const USER_NFC_CARD_TYPE_EM_410X = 'EM_410X'
export const USER_NFC_CARD_TYPE_HID_ICLASS = 'HID_ICLASS'
export const USER_NFC_CARD_TYPE_HID_PROX = 'HID_PROX'
export const USER_NFC_CARD_TYPE_HITAG_1 = 'HITAG_1'
export const USER_NFC_CARD_TYPE_ISO14443A_4 = 'ISO14443A_4'
export const USER_NFC_CARD_TYPE_ISO14443A_MIFARE = 'ISO14443A_MIFARE'
export const USER_NFC_CARD_TYPE_ISO14443B_4 = 'ISO14443B_4'
export const USER_NFC_CARD_TYPE_ISO14443B_PRIME = 'ISO14443B_PRIME'
export const USER_NFC_CARD_TYPE_ISO15693 = 'ISO15693'
export const USER_NFC_CARD_TYPE_LEGIC = 'LEGIC'

export const USER_NFC_CARD_TYPES = {
  [USER_NFC_CARD_TYPE_AWID]: 'AWID',
  [USER_NFC_CARD_TYPE_EM_410X]: 'EM410x',
  [USER_NFC_CARD_TYPE_HID_ICLASS]: 'HID iCLASS',
  [USER_NFC_CARD_TYPE_HID_PROX]: 'HID Proximity',
  [USER_NFC_CARD_TYPE_HITAG_1]: 'HITAG 1',
  [USER_NFC_CARD_TYPE_ISO14443A_4]: 'ISO14443A-4',
  [USER_NFC_CARD_TYPE_ISO14443A_MIFARE]: 'ISO14443A-Mifare',
  [USER_NFC_CARD_TYPE_ISO14443B_4]: 'ISO14443B-4',
  [USER_NFC_CARD_TYPE_ISO14443B_PRIME]: 'ISO14443B-Prime',
  [USER_NFC_CARD_TYPE_ISO15693]: 'ISO15693',
  [USER_NFC_CARD_TYPE_LEGIC]: 'LEGIC',
}
