import Icon from '@mui/icons-material/DirectionsCar'

import { getVehicleName } from '../../components/VehicleNameField'
import { VEHICLE_ACTIONS } from '../../config/vehicles'

export default {
  name: 'vehicles',
  recordRepresentation: (record) => {
    if (!record) return '\u00a0'
    return `${getVehicleName(record)} (${record.designation})`.trim()
  },
  icon: Icon,
  options: {
    referenceKey: 'vehicle_id',
    defaultSort: { field: 'status', order: 'DESC' },
    defaultFilterValues: { active: true },
    // TODO later in sync with backend: modify maintenances name
    references: ['organisations', 'bookings', 'maintenances', 'damage-reports'],
    actions: VEHICLE_ACTIONS,
    badge: true,
  },
}
