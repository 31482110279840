import { required } from 'react-admin'

import AdvancedReferenceInput from '../../components/AdvancedReferenceInput'

import config from './config'

const UserReferenceInput = (props) => <AdvancedReferenceInput {...props} />

UserReferenceInput.defaultProps = {
  reference: config.name,
  source: config.options.referenceKey,
  sort: config.options.defaultSort,
  filter: config.options.defaultFilterValues,
  excludedIds: [],
  validate: required(),
}

export default UserReferenceInput
