export const ENV_NAME = 'belgianarmy'
export const HOST_NAME = 'ops.mil.mymove.be'
export const API_URL = 'https://mil.mymove.be/ops-api'
export const FIREBASE = {
  apiKey: 'AIzaSyA38TqrJLYLQOij5ovzFdoLb7MJYEoAIt4',
  authDomain: 'belgian-army.firebaseapp.com',
  projectId: 'belgian-army',
  appId: '1:807797450740:web:a680875710269c2699fba1',
}
export const LOCALE = 'en-BE'
export const CURRENCY_CODE = 'EUR'
export const CURRENCY_SYMBOL = '€'
export const TIMEZONE = 'Europe/Brussels'
