import { Typography } from '@mui/material'

import AdvancedBooleanField from '../../components/AdvancedBooleanField'
import AdvancedTextField from '../../components/AdvancedTextField'
import DateTooltipField from '../../components/DateTooltipField'
import LocalDateField from '../../components/LocalDateField'
import StaticText from '../../components/StaticText'
import { useCommonStyles } from '../../config/theme'
import { GenericListLayout, ListDatagrid } from '../common/list'
import CompactList from '../../components/CompactList'
import { AdvancedCreateButton } from '../common/create'

import config from './config'
import { PricingStrategyPanel, PricingStrategyTable } from './show'
import PricingStrategyFormLayout from './form'

export const PricingStrategiesListLayout = ({ organisationId, ...props }) => {
  const commonClasses = useCommonStyles()
  return (
    <GenericListLayout
      action={
        <AdvancedCreateButton
          defaultValues={{ organisation_id: organisationId }}
          isFloatingOnSmallScreen={false}
          label="resources.pricing-strategies.actions.create.name"
          resource={config.name}
          sx={{ '& .MuiDialog-paper': { maxWidth: '1280px' } }}
        >
          <PricingStrategyFormLayout />
        </AdvancedCreateButton>
      }
      compactListLayout={
        <CompactList
          icon={<config.icon />}
          title={() => (
            <>
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textPrimary">
                <AdvancedTextField source="name" />
              </Typography>{' '}
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textSecondary">
                <AdvancedBooleanField source="active" falseIcon={null} trueLabel={null} falseLabel={null} />
              </Typography>
            </>
          )}
          body={() => (
            <>
              <StaticText>{'resources.pricing-strategies.fields.start_date'}: </StaticText>
              <LocalDateField source="start_date" />
              <br />
              <StaticText>{'resources.pricing-strategies.fields.created_on'} </StaticText>
              <DateTooltipField source="created_on" addTime />
            </>
          )}
          collapsibleBody={<PricingStrategyTable />}
        />
      }
      regularListLayout={
        <ListDatagrid expand={<PricingStrategyPanel />}>
          <AdvancedTextField source="name" />
          <LocalDateField source="start_date" />
          <DateTooltipField source="created_on" addTime />
          <AdvancedBooleanField source="active" falseIcon={null} sortable={false} trueLabel={null} falseLabel={null} />
        </ListDatagrid>
      }
      {...props}
    />
  )
}
