export const HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE = 'autocomplete'
export const HUB_ADDRESS_FILLING_METHOD_MANUAL = 'manual'

export const HUB_ADDRESS_FILLING_METHODS = [
  {
    id: HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE,
    name: `resources.hubs.forms.addressFillingMethod.${HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE}`,
  },
  {
    id: HUB_ADDRESS_FILLING_METHOD_MANUAL,
    name: `resources.hubs.forms.addressFillingMethod.${HUB_ADDRESS_FILLING_METHOD_MANUAL}`,
  },
]

export const PLACE_DETAILS_FIELD_PLACE_ID = 'place_id'
export const PLACE_DETAILS_FIELD_FORMATTED_ADDRESS = 'formatted_address'
export const PLACE_DETAILS_FIELD_ADDRESS_COMPONENTS = 'address_components'
export const PLACE_DETAILS_FIELD_GEOMETRY = 'geometry'

export const PLACE_DETAILS_FIELDS = [
  PLACE_DETAILS_FIELD_PLACE_ID,
  PLACE_DETAILS_FIELD_FORMATTED_ADDRESS,
  PLACE_DETAILS_FIELD_ADDRESS_COMPONENTS,
  PLACE_DETAILS_FIELD_GEOMETRY,
]

export const ADDRESS_FIELD_COUNTRY = 'country'
export const ADDRESS_FIELD_COUNTRY_CODE = 'country_code'
export const ADDRESS_FIELD_POSTAL_CODE = 'postal_code'
export const ADDRESS_FIELD_LOCALITY = 'locality'
export const ADDRESS_FIELD_POSTAL_TOWN = 'postal_town'
export const ADDRESS_FIELD_ROUTE = 'route'
export const ADDRESS_FIELD_STREET_NUMBER = 'street_number'

export const ADDRESS_MAIN_FIELDS = [
  ADDRESS_FIELD_COUNTRY,
  ADDRESS_FIELD_POSTAL_CODE,
  ADDRESS_FIELD_LOCALITY,
  ADDRESS_FIELD_ROUTE,
  ADDRESS_FIELD_STREET_NUMBER,
]
