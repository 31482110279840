import { required } from 'react-admin'

import AdvancedReferenceInput from '../../components/AdvancedReferenceInput'

import config from './config'

const OrganisationReferenceInput = (props) => {
  return (
    <AdvancedReferenceInput
      reference={config.name}
      source={config.options.referenceKey}
      sort={config.options.defaultSort}
      filter={config.options.defaultFilterValues}
      validate={required()}
      {...props}
    />
  )
}

export default OrganisationReferenceInput
