import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { useTranslate } from 'ra-core'
import { useRecordContext } from 'react-admin'

import { ORGANISATION_EMAIL_NOTIFICATIONS } from '../config/organisations'
import { useShowStyles } from '../resources/common/show'

import StaticText from './StaticText'

const useStyles = makeStyles((theme) => ({
  unorderedListItem: {
    marginBottom: theme.spacing(0.5),
  },
}))

const OrganisationEmailNotificationsField = () => {
  const record = useRecordContext()
  const showClasses = useShowStyles()
  const classes = useStyles()
  const translate = useTranslate()

  if (ORGANISATION_EMAIL_NOTIFICATIONS.every((n) => record[n.code] === false)) {
    return <StaticText>resources.organisations.emailNotifications.empty</StaticText>
  }

  return (
    <ul className={showClasses.unorderedList}>
      {ORGANISATION_EMAIL_NOTIFICATIONS.map((n) =>
        record[n.code] === true ? (
          <li key={n.code} className={classnames(showClasses.unorderedListItem, classes.unorderedListItem)}>
            <p>{translate(n.label)}</p>
          </li>
        ) : null,
      )}
    </ul>
  )
}

OrganisationEmailNotificationsField.defaultProps = {
  label: 'resources.organisations.fields.has_email_notifications',
}

export default OrganisationEmailNotificationsField
