import { Tooltip, Typography } from '@mui/material'
import get from 'lodash/get'
import { useTranslate } from 'ra-core'
import { useRecordContext } from 'react-admin'

const AdvancedTextField = ({
  className,
  fallback = 'n/a',
  map,
  multiline = false,
  source,
  sx,
  tooltip = '',
  ...props
}) => {
  const record = useRecordContext(props)
  const translate = useTranslate()
  let value = get(record, source)
  value = (map ? map[value] : value) || fallback
  if (multiline) {
    value = value.split('\\n').join('\n')
  }
  return (
    <Typography component="span" variant="body2" className={className} sx={sx}>
      <Tooltip title={tooltip}>
        <span style={multiline ? { whiteSpace: 'pre-wrap' } : null}>{translate(value, { _: value })}</span>
      </Tooltip>
    </Typography>
  )
}

export default AdvancedTextField
