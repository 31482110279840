import { Tooltip as MuiTooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import { useTranslate } from 'ra-core'
import { FunctionField, useRecordContext } from 'react-admin'

import { STATUS_CODES } from '../config/statuses'
import { useCommonStyles } from '../config/theme'
import { getStatusStyles } from '../domain/statuses'

const useStyles = makeStyles((theme) => ({
  dot: (props) => ({
    backgroundImage: getStatusStyles(props.status, theme).gradient,
    borderRadius: 7,
    height: 14,
    width: 14,
  }),
  tooltipList: {
    marginLeft: 6,
    marginRight: 6,
    marginTop: 4,
    marginBottom: 4,
    paddingLeft: 8,
  },
  tooltipListItem: {
    fontSize: 12,
    paddingTop: 3,
    paddingBottom: 3,
  },
}))

const CodesTooltip = ({ codes }) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <ul className={classes.tooltipList}>
      {codes.map((code) => (
        <li key={code} className={classes.tooltipListItem}>
          {translate(STATUS_CODES[code])}
        </li>
      ))}
    </ul>
  )
}

const StatusField = (props) => {
  const { [props.source]: status } = useRecordContext()
  const classes = useStyles({ status })
  const commonClasses = useCommonStyles()

  if (!status) {
    return null
  }

  return (
    <FunctionField
      {...props}
      render={() => (
        <div className={classnames(commonClasses.flexRowCenterContainer, commonClasses.center)}>
          {Boolean(status.severity) && !isEmpty(status.codes) ? (
            <MuiTooltip title={<CodesTooltip codes={status.codes} />} placement="right">
              <div className={classes.dot} />
            </MuiTooltip>
          ) : (
            <div className={classes.dot} />
          )}
        </div>
      )}
    />
  )
}

StatusField.defaultProps = {
  source: 'status',
}

export default StatusField
