import { fetchUtils } from 'react-admin'

import env from '../config/env'

// Bypass Firebase when performing MyMove API calls.
// The backend server must be configured to allow authentication bypass.

export default () => {
  return async (url, options = {}) => {
    let token = env.LOCAL_TOKEN_OVERRIDE
    if (!token) {
      throw new Error('Token undefined in local development mode')
    }

    options.user = {
      authenticated: true,
      token: `Bearer ${token}`,
    }

    return fetchUtils.fetchJson(url, options)
  }
}
