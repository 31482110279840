import { Typography } from '@mui/material'
import { useTranslate } from 'ra-core'

const StaticText = ({ children, sx }) => {
  const translate = useTranslate()

  const childrenArray = Array.isArray(children) ? children : [children]

  return (
    <Typography component="span" variant="body2" sx={sx}>
      {childrenArray.map((child) => translate(child, { _: child }))}
    </Typography>
  )
}

export default StaticText
