import { ReferenceField, useRecordContext } from 'react-admin'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { GenericListLayout, ListDatagrid } from '../common/list'
import { useShowStyles } from '../common/show'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import PeriodField from '../../components/PeriodField'
import PriceField from '../../components/PriceField'
import StaticText from '../../components/StaticText'
import { PAYMENT_ACTION_STATUSES, PAYMENT_ACTIONS } from '../../config/paymentActions'
import { useCommonStyles } from '../../config/theme'

import config from './config'

const useStyles = makeStyles({
  unorderedList: {
    paddingLeft: 20,
  },
})

const PaymentActionPanel = () => {
  const record = useRecordContext()
  const classes = useStyles()
  const showClasses = useShowStyles()

  return (
    <ul className={classes.unorderedList}>
      <li className={showClasses.unorderedListItem}>
        <StaticText>{'resources.payment-actions.fields.psp_reference'}: </StaticText>
        <AdvancedTextField source="psp_reference" />
      </li>
      <li className={showClasses.unorderedListItem}>
        <StaticText>{'resources.payment-actions.fields.original_psp_reference'}: </StaticText>
        <AdvancedTextField source="original_psp_reference" />
      </li>
      <li className={showClasses.unorderedListItem}>
        <StaticText>{'resources.payment-actions.fields.payloads_request'}: </StaticText>
        {record.payloads?.request ? <pre>{JSON.stringify(record.payloads.request, undefined, 2)}</pre> : 'n/a'}
      </li>
      <li className={showClasses.unorderedListItem}>
        <StaticText>{'resources.payment-actions.fields.payloads_response'}: </StaticText>
        {record.payloads?.response ? <pre>{JSON.stringify(record.payloads.response, undefined, 2)}</pre> : 'n/a'}
      </li>
    </ul>
  )
}

const PaymentActionsListLayout = (props) => {
  const commonClasses = useCommonStyles()
  return (
    <GenericListLayout
      compactListLayout={
        <CompactList
          icon={<config.icon />}
          title={() => (
            <>
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textPrimary">
                <AdvancedTextField source="action" map={PAYMENT_ACTIONS} />
              </Typography>
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textSecondary">
                {' • '}
                <DateTooltipField source="created_on" addTime />
              </Typography>
            </>
          )}
          body={(record) => (
            <>
              {!!record.amount && (
                <>
                  <StaticText>{'resources.payment-actions.fields.amount'}: </StaticText>
                  <PriceField source="amount" />
                  <br />
                </>
              )}
              <StaticText>{'resources.payment-actions.fields.status'}: </StaticText>
              <AdvancedTextField source="status" map={PAYMENT_ACTION_STATUSES} />
              {!!record.refusal_reason && (
                <>
                  <br />
                  <StaticText>{'resources.payment-actions.fields.refusal_reason'}: </StaticText>
                  <AdvancedTextField source="refusal_reason" />
                </>
              )}
            </>
          )}
        />
      }
      regularListLayout={
        <ListDatagrid expand={<PaymentActionPanel />}>
          <ReferenceField source="booking_id" reference="bookings" link="show" sortable={false}>
            <PeriodField startedOnSource="start_scheduled_on" endedOnSource="end_scheduled_on" addTime />
          </ReferenceField>
          <DateTooltipField source="created_on" addTime sortable={false} />
          <AdvancedTextField source="action" map={PAYMENT_ACTIONS} sortable={false} />
          <PriceField source="amount" sortable={false} />
          <AdvancedTextField source="status" map={PAYMENT_ACTION_STATUSES} sortable={false} />
          <AdvancedTextField source="refusal_reason" sortable={false} />
        </ListDatagrid>
      }
      {...props}
    />
  )
}

export default PaymentActionsListLayout
