import { SelectInput } from 'react-admin'

import { VEHICLE_TYPES } from '../../config/vehicles'

import config from './config'
import VehicleReferenceInput from './input'

const vehicleTypeChoices = Object.entries(VEHICLE_TYPES).map(([k, v]) => ({ value: k, name: v }))

export const VehicleTypeFilter = (props) => <SelectInput {...props} choices={vehicleTypeChoices} optionValue="value" />

VehicleTypeFilter.defaultProps = { source: 'type', label: 'resources.vehicles.fields.type' }

const vehicleLockedChoices = [
  { value: true, name: 'resources.vehicles.enums.locked.true' },
  { value: false, name: 'resources.vehicles.enums.locked.false' },
]

export const VehicleLockFilter = (props) => (
  <SelectInput {...props} choices={vehicleLockedChoices} label="resources.vehicles.fields.locked" optionValue="value" />
)

VehicleLockFilter.defaultProps = { source: 'locked' }

const vehicleActiveChoices = [
  { value: false, name: 'resources.vehicles.filters.active.values.false' },
  { value: true, name: 'resources.vehicles.filters.active.values.true' },
]

export const VehicleActiveFilter = (props) => (
  <SelectInput
    {...props}
    label="resources.vehicles.filters.active.name"
    choices={vehicleActiveChoices}
    optionValue="value"
  />
)

VehicleActiveFilter.defaultProps = { source: 'active' }

const VehiclesFilter = (props, filter) => (
  <VehicleReferenceInput validate={null} filter={{ active: null, ...filter }} {...props} />
)

VehiclesFilter.defaultProps = { source: config.options.referenceKey }

export default VehiclesFilter
