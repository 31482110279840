import Icon from '@mui/icons-material/Business'

import { ORGANISATION_ACTIONS } from '../../config/organisations'

export default {
  name: 'organisations',
  recordRepresentation: 'name',
  icon: Icon,
  options: {
    referenceKey: 'organisation_id',
    defaultSort: { field: 'name', order: 'ASC' },
    defaultFilterValues: { is_archived: false },
    // TODO later in sync with backend: modify maintenances name
    references: ['vehicles', 'bookings', 'maintenances', 'damage-reports'],
    actions: ORGANISATION_ACTIONS,
  },
}
