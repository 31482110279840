// Sources for fields & inputs

export const ACCOUNT_BILLING_TYPES_SOURCE = 'billing_types'
export const ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE = 'default_billing_type'
export const ACCOUNT_FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_SOURCE = 'free_billing_type_requires_justification'

export const ACCOUNT_BILLING_SETTINGS_SOURCES = [
  ACCOUNT_BILLING_TYPES_SOURCE,
  ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE,
  ACCOUNT_FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_SOURCE,
]
