import { useGetList } from 'react-admin'

import config from './config'

export const useGetTransactionsListForBooking = (bookingId) =>
  useGetList(
    config.name,
    { pagination: { page: 1, perPage: 100 }, sort: config.options.defaultSort, filter: { booking_id: bookingId } },
    { enabled: Boolean(bookingId) },
  )
