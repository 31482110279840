import Icon from '@mui/icons-material/Payment'
import { useTranslate } from 'ra-core'

import PriceField from '../../components/PriceField'
import { useCommonStyles } from '../../config/theme'
import { getHumanisedBookingTransactionType } from '../../domain/bookingTransactions'

export default {
  name: 'booking-transactions',
  recordRepresentation: (record) => {
    const BookingTransactionRecordRepresentation = () => {
      const commonClasses = useCommonStyles()
      const translate = useTranslate()
      return (
        <>
          {translate(getHumanisedBookingTransactionType(record))}
          {'\u00a0•\u00a0'}
          <PriceField className={commonClasses.inheritedFont} source="amount" />
        </>
      )
    }
    return <BookingTransactionRecordRepresentation />
  },
  options: {
    defaultSort: { field: 'created_on', order: 'DESC' },
    showResource: 'bookings',
  },
  icon: Icon,
}
