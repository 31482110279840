// Sentry initialization should be imported first!
import './vendor/sentry.js'

import { StrictMode } from 'react'
import ReactDOM from 'react-dom'

import App from './App.jsx'

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
)
