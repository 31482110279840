// Sources for fields & inputs

export const HUB_HAS_PICK_UP_AND_DROP_OFF_HOURS_SOURCE = 'has_pick_up_and_drop_off_hours'
export const HUB_PICK_UP_AND_DROP_OFF_HOURS_SOURCE = 'pick_up_and_drop_off_hours'

// Hub pick-up and drop-off hours types

export const HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_OPEN_ALL_DAY = 'OPEN_ALL_DAY'
export const HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CLOSED_ALL_DAY = 'CLOSED_ALL_DAY'
export const HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM = 'CUSTOM'

export const HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPES = {
  [HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_OPEN_ALL_DAY]:
    'resources.hubs.pickUpAndDropOffHours.openingTypes.values.openAllDay',
  [HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CLOSED_ALL_DAY]:
    'resources.hubs.pickUpAndDropOffHours.openingTypes.values.closedAllDay',
  [HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM]: 'resources.hubs.pickUpAndDropOffHours.openingTypes.values.custom',
}
