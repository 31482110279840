import { Children, cloneElement } from 'react'

const FieldWrapper = ({ children, ...props }) => {
  if (typeof children === 'function') {
    return children(props)
  }
  return Children.map(children, (child) => cloneElement(child, props))
}

export default FieldWrapper
