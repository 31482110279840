import { CardContent, Typography } from '@mui/material'
import CheckedIcon from '@mui/icons-material/CheckCircle'
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab'
import { makeStyles, useTheme } from '@mui/styles'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import { SimpleShowLayout } from 'react-admin'
import { useTranslate } from 'ra-core'

import { STATUS_CODES } from '../config/statuses'
import { THEME_MODE_LIGHT, useCommonStyles } from '../config/theme'
import { getStatusStyles } from '../domain/statuses'
import { CardTitle } from '../resources/common'
import { useShowStyles } from '../resources/common/show'
import { formatDateTime } from '../utils/dates'

import StaticText from './StaticText'

const useStyles = makeStyles((theme) => {
  const { mode, success, grey } = theme.palette
  const isLightMode = mode === THEME_MODE_LIGHT

  return {
    checkedIconContainer: {
      height: 28,
      width: 28,
      minWidth: 28,
      borderRadius: 14,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
      backgroundColor: success.main,
    },
    statusIcon: {
      color: 'white',
      width: 20,
      height: 20,
    },
    timelineContainer: {
      maxHeight: 328,
      overflow: 'auto',
      paddingBottom: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    timeline: {
      margin: 0,
    },
    timelineDot: {
      width: 16,
      height: 16,
      boxShadow: 'none',
      backgroundColor: (props) => getStatusStyles(props.status, theme).mainColor,
    },
    timelineDotOutline: {
      outline: 'dashed',
      outlineWidth: 2,
      outlineOffset: 3,
      outlineColor: isLightMode ? grey[500] : grey[300],
    },
    firstTimelineDot: {
      marginBottom: theme.spacing(1.5),
    },
    timelineContent: {
      paddingTop: 10,
      paddingBottom: 20,
      marginLeft: theme.spacing(1),
    },
    codesList: {
      marginTop: 6,
      paddingLeft: 20,
    },
    codesListItem: {
      fontSize: 14,
      marginBottom: 5,
    },
  }
})

const StatusTimelineDot = ({ status, index }) => {
  const theme = useTheme()
  const classes = useStyles({ status })
  const commonClasses = useCommonStyles()
  const { icon: StatusIcon } = getStatusStyles(status, theme)

  return (
    <TimelineDot
      classes={{
        root: classnames(
          commonClasses.center,
          classes.timelineDot,
          index === 0 ? classnames(classes.timelineDotOutline, classes.firstTimelineDot) : null,
        ),
      }}
    >
      <StatusIcon className={classes.statusIcon} />
    </TimelineDot>
  )
}

const StatusTimeline = ({ data }) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <Timeline className={classes.timeline}>
      {data.map((status, index) => {
        const { timestamp, codes } = status
        const isLastItem = index + 1 === data.length
        return (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <StatusTimelineDot status={status} index={index} />
              {!isLastItem && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContent}>
              <Typography variant="subtitle2">{formatDateTime(timestamp)}</Typography>
              {!isEmpty(codes) && (
                <ul className={classes.codesList}>
                  {codes.map((code) => (
                    <li key={code} className={classes.codesListItem}>
                      {translate(STATUS_CODES[code])}
                    </li>
                  ))}
                </ul>
              )}
            </TimelineContent>
          </TimelineItem>
        )
      })}
    </Timeline>
  )
}

const OperationalStatusSection = ({ data }) => {
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const classes = useStyles()

  return (
    <>
      <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
        <CardTitle text="mymove.operationalStatus.name" size={17} />
      </CardContent>
      <SimpleShowLayout
        className={classnames(commonClasses.borderTop, classes.timelineContainer)}
        sx={{ '& .RaSimpleShowLayout-row': { margin: 0, width: '100%' } }}
      >
        {Boolean(data) ? (
          isEmpty(data) ? (
            <div className={commonClasses.flexRowCenterContainer}>
              <div className={classnames(classes.checkedIconContainer, classes.timelineDotOutline)}>
                <CheckedIcon className={classes.statusIcon} />
              </div>
              <StaticText>mymove.operationalStatus.emptyMessage</StaticText>
            </div>
          ) : (
            <StatusTimeline data={data} />
          )
        ) : (
          <StaticText>mymove.operationalStatus.missingData</StaticText>
        )}
      </SimpleShowLayout>
    </>
  )
}

export default OperationalStatusSection
