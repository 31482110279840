import { Divider, Typography } from '@mui/material'
import { FormDataConsumer } from 'react-admin'
import { useTranslate } from 'ra-core'

export const getResourceSingleLabel = (resourceConfig) =>
  resourceConfig?.options?.singleLabel ? resourceConfig.options.singleLabel : 'Resource'

export const CardTitle = ({ text, size, sx = {}, args = {} }) => {
  const translate = useTranslate()
  return (
    <Typography variant="h6" sx={{ fontSize: size, fontWeight: 500, ...sx }}>
      {translate(text, args)}
    </Typography>
  )
}

export const FormDivider = ({ condition }) => (
  <FormDataConsumer>{({ formData }) => (!condition || condition(formData) ? <Divider /> : null)}</FormDataConsumer>
)
