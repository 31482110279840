import { Typography, Tooltip as MuiTooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { useTranslate } from 'ra-core'
import { FunctionField, useRecordContext } from 'react-admin'

import {
  THEME_LINEAR_GRADIENT_GREEN,
  THEME_LINEAR_GRADIENT_ORANGE,
  THEME_LINEAR_GRADIENT_RED,
  THEME_LINEAR_GRADIENT_YELLOW,
  useCommonStyles,
  useLinearGradientCommonStyles,
} from '../config/theme'

const VERSION_DIFFERENCE_MAJOR = 'major'
const VERSION_DIFFERENCE_MINOR = 'minor'
const VERSION_DIFFERENCE_PATCH = 'patch'
const VERSION_DIFFERENCE_NONE = 'none'

const VERSION_DIFFERENCE_COLORS = {
  [VERSION_DIFFERENCE_MAJOR]: THEME_LINEAR_GRADIENT_RED,
  [VERSION_DIFFERENCE_MINOR]: THEME_LINEAR_GRADIENT_ORANGE,
  [VERSION_DIFFERENCE_PATCH]: THEME_LINEAR_GRADIENT_YELLOW,
  [VERSION_DIFFERENCE_NONE]: THEME_LINEAR_GRADIENT_GREEN,
}

const useStyles = makeStyles({
  tooltip: {
    padding: 4,
  },
  tooltipFirstElement: {
    marginBottom: 2,
  },
})

const parseSemVer = (version) => {
  const parts = version.split('.')
  const [major, minor, patch] = parts.map((n) => parseInt(n, 10))
  return { major, minor, patch }
}

const getAppVersionDifferenceLevelFromLatestVersion = (appVersion, latestVersion) => {
  const { major: appMajor, minor: appMinor, patch: appPatch } = parseSemVer(appVersion)
  const { major: latestMajor, minor: latestMinor, patch: latestPatch } = parseSemVer(latestVersion)

  if (latestMajor > appMajor) {
    return VERSION_DIFFERENCE_MAJOR
  } else if (latestMajor < appMajor) {
    return VERSION_DIFFERENCE_NONE
  }
  if (latestMinor > appMinor) {
    return VERSION_DIFFERENCE_MINOR
  } else if (latestMinor < appMinor) {
    return VERSION_DIFFERENCE_NONE
  }
  if (latestPatch > appPatch) {
    return VERSION_DIFFERENCE_PATCH
  }
  return VERSION_DIFFERENCE_NONE
}

const NotUpToDateTooltip = ({ latestVersion }) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <div className={classes.tooltip}>
      <div className={classes.tooltipFirstElement}>{translate('resources.users.show.mobileAppNotUpToDate')}</div>
      <div>
        {translate('resources.users.show.latestVersion')}: v{latestVersion}
      </div>
    </div>
  )
}

const MobileAppVersionField = ({ mobileAppLatestVersion, ...props }) => {
  const { [props.source]: userAgent } = useRecordContext()
  const displayedVersion = userAgent?.replace(/^(com|be)\.(.*)\sv/, 'v')
  const shortVersion = userAgent?.replace(/^(com|be)\.(.*)\sv/, '').replace(/\s(.*)/, '')
  const versionDifferenceLevel = shortVersion
    ? getAppVersionDifferenceLevelFromLatestVersion(shortVersion, mobileAppLatestVersion)
    : null
  const versionDifferenceColor = VERSION_DIFFERENCE_COLORS[versionDifferenceLevel]

  const translate = useTranslate()
  const commonClasses = useCommonStyles()
  const linearGradientCommonClasses = useLinearGradientCommonStyles()

  const tooltip =
    versionDifferenceLevel === VERSION_DIFFERENCE_NONE ? (
      translate('resources.users.show.mobileAppUpToDate')
    ) : (
      <NotUpToDateTooltip latestVersion={mobileAppLatestVersion} />
    )

  return (
    <FunctionField
      {...props}
      render={() =>
        userAgent ? (
          <div className={commonClasses.flexRowCenterContainer}>
            <MuiTooltip title={tooltip} placement="left">
              <div
                className={classnames(commonClasses.smallDot, linearGradientCommonClasses[versionDifferenceColor])}
              />
            </MuiTooltip>
            <Typography variant="body2">{displayedVersion}</Typography>
          </div>
        ) : (
          'n/a'
        )
      }
    />
  )
}

MobileAppVersionField.defaultProps = {
  source: 'user_agent',
  mobileAppLatestVersion: '0.0.0',
}

export default MobileAppVersionField
