import * as React from 'react'
import { useRecordContext, useRedirect } from 'react-admin'
import { IconButton } from '@mui/material'
import RedirectIcon from '@mui/icons-material/LaunchRounded'

import { useCommonStyles } from '../config/theme'

const ShowButton = ({ resource }) => {
  const record = useRecordContext()
  const commonClasses = useCommonStyles()
  const redirect = useRedirect()
  if (!record?.id) {
    return null
  }
  return (
    <IconButton
      className={commonClasses.primaryColor}
      onClick={() => redirect('show', resource, record.id)}
      size="large"
    >
      <RedirectIcon />
    </IconButton>
  )
}

export default ShowButton
