import { SelectInput } from 'react-admin'

const userClosedChoices = [
  { value: false, name: 'resources.users.filters.accountStatus.values.active' },
  { value: true, name: 'resources.users.filters.accountStatus.values.deactivated' },
]

export const UserClosedFilter = (props) => <SelectInput {...props} choices={userClosedChoices} optionValue="value" />

UserClosedFilter.defaultProps = {
  source: 'closed',
  label: 'resources.users.filters.accountStatus.name',
}
