export const VOUCHER_TYPE_CAMPAIGN = 'CAMPAIGN'
export const VOUCHER_TYPE_PERSONAL = 'PERSONAL'

export const VOUCHER_TYPES = {
  [VOUCHER_TYPE_CAMPAIGN]: 'resources.vouchers.enums.types.campaign',
  [VOUCHER_TYPE_PERSONAL]: 'resources.vouchers.enums.types.personal',
}

export const VOUCHER_DISCOUNT_TYPE_PERCENTAGE = 'PERCENTAGE'
export const VOUCHER_DISCOUNT_TYPE_MINUTE = 'MINUTE'

export const VOUCHER_DISCOUNT_TYPES = {
  [VOUCHER_DISCOUNT_TYPE_PERCENTAGE]: 'resources.vouchers.enums.discountTypes.percentage',
  [VOUCHER_DISCOUNT_TYPE_MINUTE]: 'resources.vouchers.enums.discountTypes.time',
}
