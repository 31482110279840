import PaidIcon from '@mui/icons-material/AttachMoney'
import ProIcon from '@mui/icons-material/BusinessCenter'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import PrivateIcon from '@mui/icons-material/Face'
import AutoIcon from '@mui/icons-material/FlashOn'
import FreeIcon from '@mui/icons-material/MoneyOff'
import StartIcon from '@mui/icons-material/PlayCircleOutlineOutlined'

// Sources for fields & inputs

export const BOOKING_BILLING_SETTINGS_SOURCE = 'billing_settings'
export const BOOKING_BILLING_TYPE_SOURCE = 'billing_type'
export const BOOKING_MAKE_BOOKING_FREE_SOURCE = 'make_booking_free'

// Billing types

// Billing types for 'mobility budget' organisations
export const BOOKING_BILLING_TYPE_PRIVATE = 'PRIVATE'
export const BOOKING_BILLING_TYPE_PRO = 'PRO'
export const BOOKING_BILLING_TYPE_AUTO = 'AUTO'

// Billing types for 'free-to-join' organisations
export const BOOKING_BILLING_TYPE_FREE = 'FREE'
export const BOOKING_BILLING_TYPE_PAID = 'PAID'

export const BOOKING_BILLING_TYPES = {
  [BOOKING_BILLING_TYPE_PRIVATE]: 'resources.bookings.enums.billingTypes.private',
  [BOOKING_BILLING_TYPE_PRO]: 'resources.bookings.enums.billingTypes.pro',
  [BOOKING_BILLING_TYPE_AUTO]: 'resources.bookings.enums.billingTypes.auto',
  [BOOKING_BILLING_TYPE_FREE]: 'resources.bookings.enums.billingTypes.free',
  [BOOKING_BILLING_TYPE_PAID]: 'resources.bookings.enums.billingTypes.paid',
}

export const BOOKING_MOBILITY_BUDGET_BILLING_TYPES = [
  BOOKING_BILLING_TYPE_PRIVATE,
  BOOKING_BILLING_TYPE_PRO,
  BOOKING_BILLING_TYPE_AUTO,
]

export const BOOKING_BILLING_TYPES_ICONS = {
  [BOOKING_BILLING_TYPE_PRIVATE]: PrivateIcon,
  [BOOKING_BILLING_TYPE_PRO]: ProIcon,
  [BOOKING_BILLING_TYPE_AUTO]: AutoIcon,
  [BOOKING_BILLING_TYPE_FREE]: FreeIcon,
  [BOOKING_BILLING_TYPE_PAID]: PaidIcon,
}

export const BOOKING_BILLING_TYPES_COMBINATIONS_MOBILITY_BUDGET = [
  {
    id: `${BOOKING_BILLING_TYPE_PRIVATE}/${BOOKING_BILLING_TYPE_PRO}/${BOOKING_BILLING_TYPE_AUTO}`,
    name: 'resources.bookings.enums.billingTypes.private/pro/auto',
  },
  { id: BOOKING_BILLING_TYPE_PRIVATE, name: BOOKING_BILLING_TYPES[BOOKING_BILLING_TYPE_PRIVATE] },
  { id: BOOKING_BILLING_TYPE_PRO, name: BOOKING_BILLING_TYPES[BOOKING_BILLING_TYPE_PRO] },
]

export const BOOKING_BILLING_TYPES_COMBINATIONS_STANDARD = [
  {
    id: `${BOOKING_BILLING_TYPE_FREE}/${BOOKING_BILLING_TYPE_PAID}`,
    name: 'resources.bookings.enums.billingTypes.free/paid',
  },
  { id: BOOKING_BILLING_TYPE_FREE, name: BOOKING_BILLING_TYPES[BOOKING_BILLING_TYPE_FREE] },
  { id: BOOKING_BILLING_TYPE_PAID, name: BOOKING_BILLING_TYPES[BOOKING_BILLING_TYPE_PAID] },
]

// Booking periods

export const BOOKING_ERA_UPCOMING = 'UPCOMING'
export const BOOKING_ERA_CURRENT = 'CURRENT'
export const BOOKING_ERA_PAST = 'PAST'

export const BOOKING_ERAS = {
  [BOOKING_ERA_UPCOMING]: 'resources.bookings.enums.era.upcoming',
  [BOOKING_ERA_CURRENT]: 'resources.bookings.enums.era.current',
  [BOOKING_ERA_PAST]: 'resources.bookings.enums.era.past',
}

// Booking payment statuses

export const BOOKING_PAYMENT_STATUS_AUTHORIZATION_PENDING = 'AUTHORIZATION_PENDING'
export const BOOKING_PAYMENT_STATUS_AUTHORIZATION_SUCCESS = 'AUTHORIZATION_SUCCESS'
export const BOOKING_PAYMENT_STATUS_AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR'
export const BOOKING_PAYMENT_STATUS_PAYMENT_PENDING = 'PAYMENT_PENDING'
export const BOOKING_PAYMENT_STATUS_PAYMENT_SUCCESS = 'PAYMENT_SUCCESS'
export const BOOKING_PAYMENT_STATUS_PAYMENT_ERROR = 'PAYMENT_ERROR'

export const BOOKING_PAYMENT_STATUSES = {
  [BOOKING_PAYMENT_STATUS_AUTHORIZATION_PENDING]: 'resources.bookings.enums.paymentStatus.pendingAuthorisation',
  [BOOKING_PAYMENT_STATUS_AUTHORIZATION_SUCCESS]: 'resources.bookings.enums.paymentStatus.successfulAuthorisation',
  [BOOKING_PAYMENT_STATUS_AUTHORIZATION_ERROR]: 'resources.bookings.enums.paymentStatus.unsuccessfulAuthorisation',
  [BOOKING_PAYMENT_STATUS_PAYMENT_PENDING]: 'resources.bookings.enums.paymentStatus.pendingPayment',
  [BOOKING_PAYMENT_STATUS_PAYMENT_SUCCESS]: 'resources.bookings.enums.paymentStatus.successfulPayment',
  [BOOKING_PAYMENT_STATUS_PAYMENT_ERROR]: 'resources.bookings.enums.paymentStatus.unsuccessfulPayment',
}

// Booking actions

export const BOOKING_ACTION_CANCEL = 'CANCEL'
export const BOOKING_ACTION_START = 'START'
export const BOOKING_ACTION_END = 'END'

export const BOOKING_ACTIONS = {
  [BOOKING_ACTION_CANCEL]: {
    name: 'cancel',
    icon: CancelIcon,
    refresh: true,
  },
  [BOOKING_ACTION_START]: {
    name: 'start',
    icon: StartIcon,
    refresh: true,
  },
  [BOOKING_ACTION_END]: {
    name: 'end',
    icon: CancelIcon,
    refresh: true,
  },
}

// Booking status codes

export const BOOKING_STATUS_CODE_PAYMENT_PRE_AUTH_ISSUE = 'PAYMENT_PRE_AUTH_ISSUE'
export const BOOKING_STATUS_CODE_PAYMENT_ISSUE = 'PAYMENT_ISSUE'
export const BOOKING_STATUS_CODE_FAILED_FACE_CHECK = 'FAILED_FACE_CHECK'
export const BOOKING_STATUS_CODE_LATE_USER = 'LATE_USER'
export const BOOKING_STATUS_CODE_LATE_BOOKING = 'LATE_BOOKING'
export const BOOKING_STATUS_CODE_DELTA_FUEL = 'DELTA_FUEL'
export const BOOKING_STATUS_CODE_BOOKING_CONFLICT = 'BOOKING_CONFLICT'

export const BOOKING_STATUS_CODES = {
  [BOOKING_STATUS_CODE_PAYMENT_PRE_AUTH_ISSUE]: 'resources.bookings.enums.statusCodes.paymentPreAuthorisationIssue',
  [BOOKING_STATUS_CODE_PAYMENT_ISSUE]: 'resources.bookings.enums.statusCodes.paymentIssue',
  [BOOKING_STATUS_CODE_FAILED_FACE_CHECK]: 'resources.bookings.enums.statusCodes.selfieCheckFailed',
  [BOOKING_STATUS_CODE_LATE_USER]: 'resources.bookings.enums.statusCodes.lateUser',
  [BOOKING_STATUS_CODE_LATE_BOOKING]: 'resources.bookings.enums.statusCodes.lateBooking',
  [BOOKING_STATUS_CODE_DELTA_FUEL]: 'resources.bookings.enums.statusCodes.deltaFuel',
  [BOOKING_STATUS_CODE_BOOKING_CONFLICT]: 'resources.bookings.enums.statusCodes.bookingConflict',
}
