import PlusIcon from '@mui/icons-material/AddCircleOutline'
import { Box, Button } from '@mui/material'
import { FormDataConsumer, SimpleForm, usePermissions, useResourceContext, useTranslate } from 'react-admin'
import { Children, cloneElement } from 'react'

import { GenericCardTitle, useShowStyles } from './show'

export const FormSubTitle = ({ text, condition, isFirstChild = false }) => (
  <FormDataConsumer>
    {({ formData }) =>
      !condition || condition(formData) ? (
        <GenericCardTitle
          text={text}
          sx={{
            padding: '16px',
            paddingTop: isFirstChild ? '6px' : '16px',
            marginTop: isFirstChild ? 0 : 2,
            marginBottom: '10px',
            borderTop: isFirstChild ? null : '1px solid',
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        />
      ) : null
    }
  </FormDataConsumer>
)

export const SimpleFormIteratorAddButton = ({ label = 'ra.action.add', ...props }) => {
  const translate = useTranslate()
  return (
    <Button color="primary" size="small" startIcon={<PlusIcon />} {...props}>
      {translate(label)}
    </Button>
  )
}

export const useHelperText = (source) => {
  const translate = useTranslate()
  const resource = useResourceContext()

  const helperTextKey = `resources.${resource}.forms.helperTexts.${source}`
  const helperText = translate(helperTextKey)

  // Check if the translated text is the same as the key (which happens when no translation is found)
  return helperText !== helperTextKey ? helperText : null
}

export const AdvancedSimpleForm = ({ children, type, ...props }) => {
  const resource = useResourceContext()
  const showClasses = useShowStyles()
  const { permissions } = usePermissions()

  if (!permissions) return null

  const currentActionPermissions = permissions.resources[resource][type]

  const applyRestrictionsRecursively = (child) => {
    if (!child || !child.props) return child

    const isFormDataConsumer = child.type === FormDataConsumer
    if (isFormDataConsumer) {
      return cloneElement(child, {
        children: (formDataProps) => Children.map(child.props.children(formDataProps), applyRestrictionsRecursively),
      })
    }

    const isRestrictedInputField = currentActionPermissions.deny?.includes(child.props.source)
    if (isRestrictedInputField) {
      return cloneElement(child, { readOnly: true })
    }

    return child
  }

  const clonedChildren = Children.map(children, applyRestrictionsRecursively)

  return (
    <SimpleForm className={showClasses.fieldContainerWrapper} mode="onBlur" {...props}>
      <Box>{clonedChildren}</Box>
    </SimpleForm>
  )
}
