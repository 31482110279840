import { Typography } from '@mui/material'
import classnames from 'classnames'
import get from 'lodash/get'
import { useTranslate } from 'ra-core'
import { FunctionField, useRecordContext } from 'react-admin'

import { useCommonStyles, useLinearGradientCommonStyles } from '../config/theme'
import { VEHICLE_CLEANLINESS_LEVELS, VEHICLE_CLEANLINESS_LEVELS_COLORS } from '../config/vehicles'

const VehicleInteriorRatingField = (props) => {
  const record = useRecordContext()
  const translate = useTranslate()
  const level = get(record, props.source)
  const levelColor = VEHICLE_CLEANLINESS_LEVELS_COLORS[level]
  const commonClasses = useCommonStyles()
  const linearGradientCommonClasses = useLinearGradientCommonStyles()

  return (
    <FunctionField
      {...props}
      render={() =>
        level ? (
          <div className={commonClasses.flexRowCenterContainer}>
            <div className={classnames(commonClasses.smallDot, linearGradientCommonClasses[levelColor])} />
            <Typography variant="body2">{translate(VEHICLE_CLEANLINESS_LEVELS[level])}</Typography>
          </div>
        ) : (
          'n/a'
        )
      }
    />
  )
}

VehicleInteriorRatingField.defaultProps = {
  label: 'resources.vehicles.fields.vehicle_interior_rating',
  source: 'vehicle_interior_rating',
}

export default VehicleInteriorRatingField
