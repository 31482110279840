import GenericCreateForm from '../common/create'

import HubFormLayout, { transformValues } from './form'
import { HubTitle } from './show'

export default () => (
  <GenericCreateForm
    titleComponent={<HubTitle />}
    formLayoutComponent={<HubFormLayout />}
    transformValues={transformValues}
  />
)
