import SvgIcon from '@mui/material/SvgIcon'

export const path =
  'M 24.2 14.1 V 15.9 C 24.2 16.6 23.7 17.1 23 17.1 H 21.7 C 21.4 18.8 19.9 20.1 18.1 20.1 C 16.3 20.1 14.9 18.8 14.6 17.1 H 9.6 C 9.3 18.8 7.8 20.1 6 20.1 C 4.2 20.1 2.8 18.8 2.5 17.1 H 1.2 C 0.5 17.1 0 16.6 0 15.9 V 11.7 C 0 10.7 0.6 9.8 1.5 9.4 L 3.1 5.5 C 3.7 4.1 5 3.2 6.5 3.2 H 13.4 C 14.5 3.2 15.5 3.7 16.2 4.6 L 20 9.3 C 22.3 9.6 24.2 11.6 24.2 14.1 V 14.1 Z M 6.5 5.6 C 6 5.6 5.5 5.9 5.4 6.4 L 4.2 9.3 H 8.5 V 5.6 H 6.5 Z M 10.3 9.3 H 16.8 L 14.3 6.1 C 14.1 5.8 13.7 5.6 13.4 5.6 H 10.3 V 9.3 Z M 19.9 17.1 C 19.9 16.9 20 16.7 20 16.5 C 20 15.5 19.1 14.7 18.1 14.7 C 17.1 14.7 16.3 15.5 16.3 16.5 C 16.3 16.7 16.3 16.9 16.4 17.1 C 16.7 17.8 17.4 18.3 18.1 18.3 C 18.9 18.3 19.6 17.8 19.9 17.1 Z M 7.8 17.1 C 7.8 16.9 7.9 16.7 7.9 16.5 C 7.9 15.5 7 14.7 6 14.7 C 5 14.7 4.2 15.5 4.2 16.5 C 4.2 16.7 4.2 16.9 4.3 17.1 C 4.6 17.8 5.3 18.3 6 18.3 C 6.8 18.3 7.5 17.8 7.8 17.1 Z'

const SideCarIcon = (props) => (
  <SvgIcon {...props}>
    <path d={path} />
  </SvgIcon>
)

export default SideCarIcon
