import get from 'lodash/get'

import {
  BOOKING_TRANSACTION_TYPE_CHARGE_EXTRA,
  BOOKING_TRANSACTION_TYPE_REFUND,
  BOOKING_TRANSACTION_TYPE_RELEASE_PRE_AUTHORIZATION,
  BOOKING_TRANSACTION_TYPE_CHARGE_BOOKING_PRICE,
  BOOKING_TRANSACTION_ALL_TYPES,
} from '../config/bookingTransactions'

const getBookingTransactionType = (transaction) => get(transaction, 'type')

export const getHumanisedBookingTransactionType = (transaction) =>
  BOOKING_TRANSACTION_ALL_TYPES[getBookingTransactionType(transaction)]

export const isBookingTransactionTypeChargeExtra = (record) =>
  getBookingTransactionType(record) === BOOKING_TRANSACTION_TYPE_CHARGE_EXTRA

export const isBookingTransactionTypeRefund = (record) =>
  getBookingTransactionType(record) === BOOKING_TRANSACTION_TYPE_REFUND

export const isBookingTransactionTypeReleasePreAuth = (record) =>
  getBookingTransactionType(record) === BOOKING_TRANSACTION_TYPE_RELEASE_PRE_AUTHORIZATION

export const isBookingTransactionTypeChargeBookingPrice = (record) =>
  getBookingTransactionType(record) === BOOKING_TRANSACTION_TYPE_CHARGE_BOOKING_PRICE
