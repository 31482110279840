import { useGetList, useGetOne } from 'react-admin'

import { useAccounts } from '../../api/accountsProvider'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'
import hubsConfig from '../hubs/config'
import organisationsConfig from '../organisations/config'

import config from './config'

export const useGetCurrentAccount = () => {
  const { currentAccount } = useAccounts()
  const [hasRead] = useResourcePermissions(config.name, SYSTEM_PERMISSION_READ)
  const { data: account } = useGetOne(
    config.name,
    { id: currentAccount?.id },
    { enabled: hasRead && Boolean(currentAccount?.id) },
  )
  return account
}

export const useCurrentAccountSelectors = () => {
  const [hasReadForOrganisations] = useResourcePermissions(organisationsConfig.name, SYSTEM_PERMISSION_READ)
  const [hasReadForHubs] = useResourcePermissions(hubsConfig.name, SYSTEM_PERMISSION_READ)

  const pagination = { page: 1, perPage: 1 }

  const organisations = useGetList(
    organisationsConfig.name,
    { pagination, filter: { ...organisationsConfig.options.defaultFilterValues } },
    { enabled: hasReadForOrganisations },
  )
  const hubs = useGetList(
    hubsConfig.name,
    { pagination, sort: hubsConfig.options.defaultSort },
    { enabled: hasReadForHubs },
  )

  const hasSingleOrganisation = organisations.total ? organisations.total === 1 : undefined
  const singleOrganisationId = hasSingleOrganisation ? organisations.data[0].id : null
  const hasSingleHub = hubs.total ? hubs.total === 1 : undefined

  return { hasSingleOrganisation, singleOrganisationId, hasSingleHub }
}
