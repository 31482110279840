import { DeleteButton as DeleteButtonBase, useNotify, useRecordContext } from 'react-admin'

export const DeleteButton = ({ condition, confirmTitle, successMessage }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const onSuccess = () => notify(successMessage, { type: 'info' })
  if (!record || (typeof condition === 'function' && !condition(record))) {
    return null
  }
  return (
    <DeleteButtonBase
      confirmColor="warning"
      confirmTitle={confirmTitle}
      mutationMode="optimistic"
      mutationOptions={{ onSuccess }}
    />
  )
}
