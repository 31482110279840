export const BOOKING_TRANSACTION_TYPE_CHARGE_EXTRA = 'CHARGE_EXTRA'
export const BOOKING_TRANSACTION_TYPE_REFUND = 'REFUND'
export const BOOKING_TRANSACTION_TYPE_RELEASE_PRE_AUTHORIZATION = 'RELEASE_PRE_AUTHORIZATION'
export const BOOKING_TRANSACTION_TYPE_ACQUIRE_PRE_AUTHORIZATION = 'ACQUIRE_PRE_AUTHORIZATION'
export const BOOKING_TRANSACTION_TYPE_ADJUST_PRE_AUTHORIZATION = 'ADJUST_PRE_AUTHORIZATION'
export const BOOKING_TRANSACTION_TYPE_CHARGE_BOOKING_PRICE = 'CHARGE_BOOKING_PRICE'

export const BOOKING_TRANSACTION_ALL_TYPES = {
  [BOOKING_TRANSACTION_TYPE_CHARGE_EXTRA]: 'resources.booking-transactions.enums.types.chargeExtra',
  [BOOKING_TRANSACTION_TYPE_REFUND]: 'resources.booking-transactions.enums.types.refund',
  [BOOKING_TRANSACTION_TYPE_RELEASE_PRE_AUTHORIZATION]:
    'resources.booking-transactions.enums.types.releasePreAuthorisation',
  [BOOKING_TRANSACTION_TYPE_ACQUIRE_PRE_AUTHORIZATION]:
    'resources.booking-transactions.enums.types.acquirePreAuthorisation',
  [BOOKING_TRANSACTION_TYPE_ADJUST_PRE_AUTHORIZATION]:
    'resources.booking-transactions.enums.types.adjustPreAuthorisation',
  [BOOKING_TRANSACTION_TYPE_CHARGE_BOOKING_PRICE]: 'resources.booking-transactions.enums.types.chargeBookingPrice',
}

export const BOOKING_TRANSACTION_FORM_TYPES = Object.fromEntries(
  Object.entries(BOOKING_TRANSACTION_ALL_TYPES).filter(([k]) =>
    [
      BOOKING_TRANSACTION_TYPE_CHARGE_EXTRA,
      BOOKING_TRANSACTION_TYPE_REFUND,
      BOOKING_TRANSACTION_TYPE_RELEASE_PRE_AUTHORIZATION,
    ].includes(k),
  ),
)

export const BOOKING_TRANSACTION_STATUS_TO_PROCESS = 'TO_PROCESS'
export const BOOKING_TRANSACTION_STATUS_PENDING = 'PENDING'
export const BOOKING_TRANSACTION_STATUS_SUCCESS = 'SUCCESS'
export const BOOKING_TRANSACTION_STATUS_ERROR = 'ERROR'

export const BOOKING_TRANSACTION_STATUSES = {
  [BOOKING_TRANSACTION_STATUS_TO_PROCESS]: 'resources.booking-transactions.enums.status.toProcess',
  [BOOKING_TRANSACTION_STATUS_PENDING]: 'resources.booking-transactions.enums.status.pending',
  [BOOKING_TRANSACTION_STATUS_SUCCESS]: 'resources.booking-transactions.enums.status.success',
  [BOOKING_TRANSACTION_STATUS_ERROR]: 'resources.booking-transactions.enums.status.error',
}
