import isDisposablePhoneNumber from '@ip1sms/is-disposable-phone-number'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import { required, email } from 'react-admin'

import { checkVATNumber } from '../vendor/vat_validation'

import { DEFAULT_COUNTRY_CODE, parsePhoneNumber, toSentence } from '.'

// Email address validation

export const validateEmail = (isRequired = true) => [isRequired ? required() : false, email()]

// Phone number validation

export const validatePhoneNumber = ({ countryCode, acceptedTypes, isRequired = false }) => [
  isRequired ? required() : false,
  (value) => {
    if (value) {
      if (!parsePhoneNumber(value, countryCode, acceptedTypes)) {
        return 'mymove.validation.invalidFormat'
      }
      const parsed = parsePhoneNumberFromString(value, countryCode ?? DEFAULT_COUNTRY_CODE)
      // We check the phone number without country code here to follow external lib's usage
      if (isDisposablePhoneNumber(parsed.nationalNumber)) {
        return 'mymove.validation.disposablePhoneNumber'
      }
    }
    return false
  },
]

// UUID validation

export const isUuid4 = (string) => {
  var re = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  return re.test(string)
}

export const validateUuid = (label) => [
  (value) => (value && !isUuid4(value) ? { message: 'mymove.validation.uuid.invalid', args: { value: label } } : false),
]

// VAT validation

export const validateVAT = (isRequired = true) => [
  isRequired ? required() : false,
  (value) => (checkVATNumber(value) ? false : 'mymove.validation.vatNumber.invalid'),
]

// Address validation

export const getMissingFieldsFromObject = (object) =>
  Object.entries(object)
    .filter(([_, v]) => !v)
    .map(([field, _]) => field)

export const validateAddress = (isRequired = true) => [
  isRequired ? required() : false,
  (value) => {
    if (value) {
      const missingFields = getMissingFieldsFromObject(value)
      if (missingFields.length > 0) {
        const formattedMissingFields = missingFields.map((field) => field.replace('_', ' '))
        return {
          message: 'mymove.validation.address.incomplete',
          args: { missingFields: toSentence(formattedMissingFields) },
        }
      }
    }
    return false
  },
]

// IBAN (bank account) validation

const ibanCodeLengths = {
  AD: 24,
  AE: 23,
  AL: 28,
  AT: 20,
  AZ: 28,
  BA: 20,
  BE: 16,
  BG: 22,
  BH: 22,
  BI: 28,
  BR: 29,
  BY: 28,
  CH: 21,
  CR: 22,
  CY: 28,
  CZ: 24,
  DE: 22,
  DK: 18,
  DO: 28,
  EE: 20,
  EG: 29,
  ES: 24,
  LC: 32,
  FI: 18,
  FO: 18,
  FR: 27,
  GB: 22,
  GE: 22,
  GI: 23,
  GL: 18,
  GR: 27,
  GT: 28,
  HR: 21,
  HU: 28,
  IE: 22,
  IL: 23,
  IQ: 23,
  IS: 26,
  IT: 27,
  JO: 30,
  KW: 30,
  KZ: 20,
  LB: 28,
  LI: 21,
  LT: 20,
  LU: 20,
  LV: 21,
  LY: 25,
  MC: 27,
  MD: 24,
  ME: 22,
  MK: 19,
  MR: 27,
  MT: 31,
  MU: 30,
  NL: 18,
  NO: 15,
  PK: 24,
  PL: 28,
  PS: 29,
  PT: 25,
  QA: 29,
  RO: 24,
  RS: 22,
  SA: 24,
  SC: 31,
  SD: 18,
  SE: 24,
  SI: 19,
  SK: 24,
  SM: 27,
  ST: 25,
  SV: 28,
  TL: 23,
  TN: 24,
  TR: 26,
  UA: 29,
  VA: 22,
  VG: 24,
  XK: 20,
}

const mod97 = (digital) => {
  digital = digital.toString()
  let checksum = digital.slice(0, 2)
  let fragment = ''
  for (let offset = 2; offset < digital.length; offset += 7) {
    fragment = checksum + digital.substring(offset, offset + 7)
    checksum = parseInt(fragment, 10) % 97
  }
  return checksum
}

export const validateIBAN = (isRequired = true) => [
  isRequired ? required() : false,
  (value) => {
    if (value) {
      const iban = value.toUpperCase().replace(/[^A-Z0-9]/g, '')
      const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/)
      if (!code || iban.length !== ibanCodeLengths[code[1]]) {
        return 'mymove.validation.invalidFormat'
      }
      const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => {
        return (letter.charCodeAt(0) - 55).toString()
      })
      if (mod97(digits) !== 1) {
        return 'mymove.validation.invalidFormat'
      }
    }
    return false
  },
]

// VIN validation

export const validateVIN = (isRequired = true) => [
  isRequired ? required() : false,
  (value) => (!value || String(value).length === 17 ? false : 'mymove.validation.vin.invalid'),
]
