export const BOOKING_END_CHECK_KEY_IN_ARMREST = 'KEY_IN_ARMREST'
export const BOOKING_END_CHECK_FUEL_CARD_IN_SUN_VISOR = 'FUEL_CARD_IN_SUN_VISOR'
export const BOOKING_END_CHECK_TANK_FILLED_UP = 'TANK_FILLED_UP'
export const BOOKING_END_CHECK_VEHICLE_PLUGGED_IN = 'VEHICLE_PLUGGED_IN'
export const BOOKING_END_CHECK_PARKED_IN_SAME_SPOT = 'PARKED_IN_SAME_SPOT'
export const BOOKING_END_CHECK_NOTHING_LEFT = 'NOTHING_LEFT'

export const BOOKING_END_CHECKS = {
  [BOOKING_END_CHECK_KEY_IN_ARMREST]: 'resources.organisations.enums.bookingEndChecks.keyInArmrest',
  [BOOKING_END_CHECK_FUEL_CARD_IN_SUN_VISOR]: 'resources.organisations.enums.bookingEndChecks.fuelCardInSunVisor',
  [BOOKING_END_CHECK_TANK_FILLED_UP]: 'resources.organisations.enums.bookingEndChecks.tankFilledUp',
  [BOOKING_END_CHECK_VEHICLE_PLUGGED_IN]: 'resources.organisations.enums.bookingEndChecks.vehiclePluggedIn',
  [BOOKING_END_CHECK_PARKED_IN_SAME_SPOT]: 'resources.organisations.enums.bookingEndChecks.parkedInSameSpot',
  [BOOKING_END_CHECK_NOTHING_LEFT]: 'resources.organisations.enums.bookingEndChecks.nothingLeft',
}
