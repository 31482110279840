import Icon from '../../components/icons/OpsUsersIcon'
import { getUserFullName } from '../../components/UserFullNameField'

export default {
  name: 'ops-users',
  recordRepresentation: getUserFullName,
  options: {
    referenceKey: 'ops_user_id',
    defaultSort: { field: 'created_on', order: 'DESC' },
  },
  icon: Icon,
}
