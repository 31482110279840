import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslate } from 'ra-core'

import { useShowStyles } from '../common/show'
import { getUserFullName } from '../../components/UserFullNameField'
import { USER_ACCOUNTS_CLOSING_REASON_SUSPICIOUS_ACTIVITY, USER_ACCOUNTS_CLOSING_REASONS } from '../../config/users'

const DeactivateAccountConfirmContent = ({ record, update, state, isPristine, error }) => {
  const showClasses = useShowStyles()
  const translate = useTranslate()
  return (
    <>
      <Typography>
        {translate('resources.users.actions.deactivateAccount.helperText', {
          userFullName: getUserFullName(record),
        })}
        <br />
        {translate('resources.users.actions.deactivateAccount.helperText2')}
      </Typography>
      <FormControl style={{ marginTop: '1.5em', display: 'block' }}>
        <FormLabel component="legend" focused={false} style={{ marginBottom: '0.5em' }}>
          {translate('resources.users.fields.user_account.closing_reason')}
        </FormLabel>
        <RadioGroup name="closing_reason" required onChange={(event) => update({ closing_reason: event.target.value })}>
          {Object.entries(USER_ACCOUNTS_CLOSING_REASONS).map(([k, v]) => (
            <FormControlLabel key={k} value={k} control={<Radio />} label={translate(v)} />
          ))}
        </RadioGroup>
        <FormHelperText error>{!isPristine && translate(error?.closing_reason)}</FormHelperText>
      </FormControl>
      {state.closing_reason === USER_ACCOUNTS_CLOSING_REASON_SUSPICIOUS_ACTIVITY && (
        <FormControl style={{ marginTop: '1.5em', display: 'block' }}>
          <TextField
            name="closing_justification"
            label={translate('resources.users.fields.user_account.closing_justification')}
            onChange={(event) => update({ closing_justification: event.target.value })}
            className={showClasses.textFieldWithFullWidth}
            size="small"
            multiline
            required
          />
          <FormHelperText error>{!isPristine && translate(error?.closing_justification)}</FormHelperText>
        </FormControl>
      )}
    </>
  )
}
export const deactivateAccountConfirm = ({ record }) => ({
  validate: (state) => {
    if (!state.closing_reason) {
      return { closing_reason: 'resources.users.actions.deactivateAccount.validate.closingReason' }
    }
    if (state.closing_reason === USER_ACCOUNTS_CLOSING_REASON_SUSPICIOUS_ACTIVITY && !state.closing_justification) {
      return { closing_justification: 'resources.users.actions.deactivateAccount.validate.closingJustification' }
    }
    return null
  },
  content: (props) => <DeactivateAccountConfirmContent record={record} {...props} />,
  confirmLabel: 'resources.users.actions.deactivateAccount.confirmLabel',
})

const ReactivateAccountConfirmContent = ({ wasClosedRecently, recentDelay, record }) => {
  const translate = useTranslate()
  return (
    <>
      {wasClosedRecently ? (
        <Typography>
          {translate('resources.users.actions.reactivateAccount.helperText', {
            userFullName: getUserFullName(record),
          })}
        </Typography>
      ) : (
        <Typography>
          {translate('resources.users.actions.reactivateAccount.helperText2', { recentDelay: recentDelay })}
        </Typography>
      )}
    </>
  )
}

export const reactivateAccountConfirm = ({ record }) => {
  const recentDelay = 48 // hours
  const wasClosedRecently =
    record.user_account.closed_on &&
    new Date().getTime() < new Date(record.user_account.closed_on).getTime() + recentDelay * 3600 * 1000

  return {
    content: (props) => (
      <ReactivateAccountConfirmContent
        {...props}
        recentDelay={recentDelay}
        wasClosedRecently={wasClosedRecently}
        record={record}
      />
    ),
    confirmLabel: wasClosedRecently
      ? 'resources.users.actions.reactivateAccount.confirmLabel1'
      : 'resources.users.actions.reactivateAccount.confirmLabel2',
  }
}
