import { Card } from '@mui/material'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import {
  RecordContextProvider,
  ResourceContextProvider,
  ShowButton,
  Title,
  TopToolbar,
  useNotify,
  useRedirect,
  useUpdate,
} from 'react-admin'

import { SYSTEM_PERMISSION_UPDATE } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'

import config, { ACCOUNT_BASE_PATH } from './config'
import AccountFormLayout, { transformValues } from './form'
import { useGetCurrentAccount } from './hooks'
import { AccountShowPage } from './show'

// TODO later: relying on "EditBase" (from React-Admin) would greatly simplify the content of this page
export const AccountEditPage = () => {
  const account = useGetCurrentAccount()
  const notify = useNotify()
  const redirect = useRedirect()
  const [hasEdit] = useResourcePermissions(config.name, SYSTEM_PERMISSION_UPDATE)
  const translate = useTranslate()

  const [update] = useUpdate()
  const onFormSubmit = useMemo(
    () => (values) => {
      if (!account) return
      update(
        config.name,
        { id: account.id, data: transformValues(values), previousData: account },
        {
          onSuccess: () => {
            notify('resources.accounts.forms.update.success')
            redirect(AccountShowPage.path)
          },
          onError: () => notify('resources.accounts.forms.update.failure', { type: 'error' }),
        },
      )
    },
    [JSON.stringify(account)], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return hasEdit && account ? (
    <ResourceContextProvider value={config.name}>
      <RecordContextProvider value={account}>
        <Title title={translate('resources.accounts.name', 1)} />
        <TopToolbar sx={{ flex: '0 !important' }}>
          <ShowButton to={AccountShowPage.path} />
        </TopToolbar>
        <Card>
          <AccountFormLayout onSubmit={onFormSubmit} />
        </Card>
      </RecordContextProvider>
    </ResourceContextProvider>
  ) : (
    <Title title={translate('resources.accounts.name', 1)} />
  )
}

AccountEditPage.path = `${ACCOUNT_BASE_PATH}/edit`
