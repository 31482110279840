import Icon from '@mui/icons-material/Healing'

import ReferenceValue from '../../components/ReferenceValue'
import { DAMAGES_STATUS_REPORTED } from '../../config/damages'
import organisationsConfig from '../organisations/config'
import vehiclesConfig from '../vehicles/config'

export default {
  name: 'damage-reports',
  recordRepresentation: (
    <>
      <ReferenceValue reference={organisationsConfig.name} emptyText={null} />
      {'\u00a0•\u00a0'}
      <ReferenceValue reference={vehiclesConfig.name} emptyText={null} />
    </>
  ),
  options: {
    defaultSort: { field: 'created_on', order: 'DESC' },
    defaultFilterValues: { status: DAMAGES_STATUS_REPORTED },
    references: ['organisations', 'users', 'vehicles'],
    badge: true,
  },
  icon: Icon,
}
