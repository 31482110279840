import get from 'lodash/get'
import { usePermissions, useResourceContext } from 'react-admin'

export const hasResourcePermission = (permissions, resource, ...args) => {
  const resourcePermissions = get(permissions, ['resources', resource])
  if (!resourcePermissions) {
    return false
  }
  return [...args].every((permission) => (Object.keys(resourcePermissions) || []).includes(permission))
}

export const useResourcePermissions = (resource, ...args) => {
  const { permissions } = usePermissions()

  // Get the first part of the resource name (e.g. 'organisations/pressure' -> 'organisations')
  const match = resource.match(/^[^/]+/)
  resource = match ? match[0] : resource

  return [...args].map((permission) =>
    Boolean(permissions) ? hasResourcePermission(permissions, resource, permission) : undefined,
  )
}

export const useInputDenyPermission = (inputSource, formType) => {
  const resource = useResourceContext()
  const { permissions } = usePermissions()
  const currentActionPermissions = permissions.resources[resource][formType]
  const isRestrictedInput = currentActionPermissions.deny?.includes(inputSource)
  return isRestrictedInput
}
