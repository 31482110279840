import { isEmpty } from 'lodash'
import { FunctionField, useTranslate } from 'react-admin'

const BookingJustificationField = ({ bookingsJustifications, source }) => {
  const translate = useTranslate()
  return (
    <FunctionField
      render={(record) => {
        const justification = record[source]
        if (!justification) {
          return 'n/a'
        }
        if (isEmpty(bookingsJustifications)) {
          return justification
        }
        return (
          bookingsJustifications.find((j) => j.code === justification)?.label ??
          `${translate('mymove.other')} ${justification}`
        )
      }}
    />
  )
}

export default BookingJustificationField
