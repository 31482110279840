import { BOOKING_STATUS_CODES } from './bookings'
import { USER_STATUS_CODES } from './users'
import { VEHICLE_STATUS_CODES } from './vehicles'

export const STATUS_SEVERITY_WARNING = 'WARNING'
export const STATUS_SEVERITY_CRITICAL = 'CRITICAL'

export const STATUS_CODES = {
  ...USER_STATUS_CODES,
  ...VEHICLE_STATUS_CODES,
  ...BOOKING_STATUS_CODES,
}
