import {
  DateField,
  Filter,
  List,
  RecordRepresentation,
  ReferenceField,
  useCreatePath,
  useGetResourceLabel,
  useRecordContext,
  useResourceContext,
} from 'react-admin'
import { useTranslate } from 'ra-core'
import { Box, Tooltip } from '@mui/material'
import { createElement } from 'react'

import { AdvancedDatesFilter } from '../../components/AdvancedDatesFilter'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import { EnumSelectInput } from '../../components/EnumSelectInput'
import ReferenceValue from '../../components/ReferenceValue'
import StaticText from '../../components/StaticText'
import {
  EVENT_ACTIONS,
  EVENT_CHANNEL_GRAPHQL,
  EVENT_CHANNEL_ICONS,
  EVENT_CHANNEL_OPS,
  EVENT_CHANNEL_WEBHOOK,
  EVENT_CHANNELS,
} from '../../config/events'
import { useSmallScreen } from '../../utils/theme'
import { AccountShowPage } from '../accounts/show'
import { GenericListLayout, ListActions, ListDatagrid } from '../common/list'
import { getResourceByName } from '../index'

import config from './config'
import eventsExporter from './exporter'

const useGetTargetLink = () => {
  const createPath = useCreatePath()
  const getLink = (record, reference) => {
    if (!record || !reference) return null
    let link = createPath({ resource: reference, id: record.id, type: 'show' })
    const referenceConfig = getResourceByName(reference)
    const showResource = referenceConfig.options?.showResource
    if (reference === 'accounts') {
      link = AccountShowPage.path
    } else if (showResource) {
      const showResourceConfig = getResourceByName(showResource)
      const showResourceReferenceKey = showResourceConfig.options.referenceKey
      if (showResourceReferenceKey && record[showResourceReferenceKey]) {
        link = createPath({ resource: showResource, id: record[showResourceReferenceKey], type: 'show' })
      }
    }
    return link
  }
  return getLink
}

const ChannelFilter = () => <EnumSelectInput source="channel" options={EVENT_CHANNELS} />
const ActionFilter = () => {
  const translate = useTranslate()
  return (
    <EnumSelectInput
      source="action"
      options={EVENT_ACTIONS}
      translateArgs={{ resource: translate('resources.events.fields.entity_id') }}
    />
  )
}

const EventsFilters = (props) => (
  <Filter {...props}>
    <AdvancedDatesFilter source={['date_gte', 'date_lte']} alwaysOn />
    <ActionFilter alwaysOn />
    <ChannelFilter alwaysOn />
  </Filter>
)

export const EventsListLayout = ({ excludedFields = [], ...props }) => {
  const getTargetLink = useGetTargetLink()
  const translate = useTranslate()

  const isAuthorIncluded = !excludedFields.includes('author')
  const isChannelIncluded = !excludedFields.includes('channel')
  const isTargetIncluded = !excludedFields.includes('entity_id')

  return (
    <GenericListLayout
      compactListLayout={
        <CompactList
          icon={(record) => {
            const recordConfig = getResourceByName(record.resource)
            return <recordConfig.icon />
          }}
          title={() => <EventActionField source="action" />}
          body={() => (
            <>
              <StaticText>{translate('resources.events.fields.date')} </StaticText>
              <DateField source="date" showTime />
              {isAuthorIncluded && (
                <>
                  <br />
                  <StaticText>{translate('resources.events.fields.author')} </StaticText>
                  <EventAuthorField source="author" />
                </>
              )}
              {isChannelIncluded && (
                <>
                  <br />
                  <StaticText>{translate('resources.events.fields.channel')}: </StaticText>
                  <AdvancedEnumTextField source="channel" />
                </>
              )}
            </>
          )}
          referenceLink={
            isTargetIncluded
              ? {
                  getLink: (referenceRecord, reference) => getTargetLink(referenceRecord, reference),
                  getReference: (record) => record.resource,
                  source: 'entity_id',
                }
              : null
          }
        />
      }
      regularListLayout={
        <ListDatagrid rowClick={false}>
          <DateField source="date" showTime />
          <EventActionField source="action" />
          {isTargetIncluded && <EventTargetField source="entity_id" />}
          {isAuthorIncluded && <EventAuthorField sortable={false} source="author" />}
          {isChannelIncluded && <AdvancedEnumTextField source="channel" icons={EVENT_CHANNEL_ICONS} />}
        </ListDatagrid>
      }
      {...props}
    />
  )
}

const EventActionField = ({ source }) => {
  const record = useRecordContext()
  const getResourceLabel = useGetResourceLabel()
  return <AdvancedEnumTextField source={source} translateArgs={{ resource: getResourceLabel(record.resource, 1) }} />
}

const ResourceIconField = (props) => {
  const resource = useResourceContext(props)
  const resourceConfig = getResourceByName(resource)
  const getResourceLabel = useGetResourceLabel()
  return (
    <Tooltip title={getResourceLabel(resource)} placement="left">
      {createElement(resourceConfig.icon, { sx: { marginRight: 1 } })}
    </Tooltip>
  )
}

const EventTargetField = ({ source }) => {
  const getTargetLink = useGetTargetLink()
  const record = useRecordContext()
  return (
    <ReferenceField reference={record.resource} source={source} link={getTargetLink} sx={{ display: 'flex' }}>
      <Box display="flex" alignItems="center">
        <ResourceIconField />
        <RecordRepresentation />
      </Box>
    </ReferenceField>
  )
}

const AdvancedEnumTextField = ({ source, icons, translateArgs = {} }) => {
  const record = useRecordContext()
  const resource = useResourceContext()
  const translate = useTranslate()
  const value = translate(`resources.${resource}.enums.${source}.values.${record[source].toLowerCase()}`, translateArgs)
  return icons ? (
    <Box display="flex" alignItems="center">
      <Tooltip title={value} placement="left">
        {createElement(icons[record[source]])}
      </Tooltip>
    </Box>
  ) : (
    value
  )
}

const EventAuthorField = () => {
  const record = useRecordContext()
  const isSmallScreen = useSmallScreen()
  if (![EVENT_CHANNEL_OPS, EVENT_CHANNEL_GRAPHQL, EVENT_CHANNEL_WEBHOOK].includes(record.channel)) {
    return <AdvancedTextField source="author.fullName" />
  }
  const resource = record.channel === EVENT_CHANNEL_OPS ? 'ops-users' : 'users'
  const commonProps = { reference: resource, source: 'author.id' }
  return isSmallScreen ? <ReferenceValue {...commonProps} /> : <ReferenceField {...commonProps} link="show" />
}

export default () => (
  <List
    sort={config.options.defaultSort}
    filters={<EventsFilters />}
    actions={<ListActions hasExport />}
    exporter={eventsExporter}
  >
    <EventsListLayout />
  </List>
)
