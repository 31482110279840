import { isEmpty } from 'lodash'
import { useTranslate } from 'ra-core'
import { cloneElement, Fragment, useCallback, useState } from 'react'
import {
  MenuItemLink,
  RecordContextProvider,
  ResourceContextProvider,
  useCreatePath,
  useListContext,
  usePermissions,
  useRecordContext,
  useReference,
  useResourceContext,
} from 'react-admin'
import { Link } from 'react-router-dom'
import {
  Avatar,
  Badge,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import EditIcon from '@mui/icons-material/Edit'
import MoreIcon from '@mui/icons-material/MoreVert'

import { SYSTEM_PERMISSION_READ, SYSTEM_PERMISSION_UPDATE } from '../config/permissions'
import { useCommonStyles } from '../config/theme'
import { hasResourcePermission, useResourcePermissions } from '../domain/permissions'
import { getResourceByName } from '../resources'

import { ReferenceMenuItem } from './ReferenceLink'

const useStyles = makeStyles((theme) => ({
  iconDotBadge: {
    backgroundColor: (props) => props.iconBadgeColor,
    borderRadius: 5,
    width: 10,
    height: 10,
  },
  menuItemLink: {
    color: theme.palette.text.primary,
  },
}))

const getRecordValue = (record, source) => {
  if (!record || !source) {
    return ''
  }
  if (typeof source === 'string') {
    return record[source]
  }
  if (typeof source === 'function') {
    return source(record)
  }
  return cloneElement(source, { record })
}

const CompactListItem = ({
  body,
  collapsibleBody,
  icon,
  iconBadgeColor,
  image,
  isResourceEditable,
  itemSx,
  linkType,
  referenceLink,
  references,
  referencesFilterValues,
  referencesSources = {},
  title,
}) => {
  const record = useRecordContext()
  const resource = useResourceContext()

  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const hasCollapsibleBody = !!collapsibleBody
  const [hasEditPermission] = useResourcePermissions(resource, SYSTEM_PERMISSION_UPDATE)
  const hasEdit = (isResourceEditable ? isResourceEditable(record) : true) && hasEditPermission
  const hasLink = !!referenceLink || !!linkType
  const shouldDisplayPaddingInMenu = (hasEdit && !isEmpty(references)) || (!hasEdit && references.length >= 2)

  const createPath = useCreatePath()
  const getRecordLink = useCallback(
    (type) => (!type && !linkType ? null : createPath({ resource, id: record.id, type: type ?? linkType })),
    [linkType, createPath, resource, record.id],
  )

  let link = getRecordLink()
  const referenceLinkRef = getRecordValue(record, referenceLink?.getReference)
  const referenceLinkId = getRecordValue(record, referenceLink?.source)
  const { referenceRecord: referenceLinkRecord } = useReference({
    reference: referenceLinkRef,
    id: referenceLinkId,
    options: { enabled: !!referenceLinkRef && !!referenceLinkId },
  })
  if (referenceLinkRecord) {
    link = referenceLink.getLink(referenceLinkRecord, referenceLinkRef)
  }

  const openMenu = (event) => setAnchorEl(event.currentTarget)
  const onMenuClose = () => setAnchorEl(null)

  const titleValue = getRecordValue(record, title)
  const iconEl = getRecordValue(record, icon)
  const iconBadgeColorValue = getRecordValue(record, iconBadgeColor)
  const imageSrc = getRecordValue(record, image)
  const bodyValue = getRecordValue(record, body)

  const classes = useStyles({ iconBadgeColor: iconBadgeColorValue })
  const commonClasses = useCommonStyles()

  return (
    <ListItem sx={{ padding: 0 }}>
      <ListItemButton
        alignItems={bodyValue && titleValue ? 'flex-start' : 'center'}
        component={hasLink ? Link : undefined}
        disabled={!hasLink && !hasCollapsibleBody}
        onClick={hasCollapsibleBody ? () => setIsCollapsibleOpen(!isCollapsibleOpen) : null}
        selected={isCollapsibleOpen}
        sx={{ flexWrap: 'wrap', ...(itemSx ? itemSx(record) : null) }}
        to={link}
      >
        {(imageSrc || iconEl) && (
          <ListItemAvatar>
            {imageSrc ? (
              <Avatar className={commonClasses.whiteLogo} variant="square" alt={titleValue} src={imageSrc} />
            ) : (
              <Badge
                variant="dot"
                overlap="circular"
                invisible={!iconBadgeColorValue}
                classes={{ dot: classes.iconDotBadge }}
              >
                <Avatar>{iconEl}</Avatar>
              </Badge>
            )}
          </ListItemAvatar>
        )}
        <ListItemText primary={titleValue} secondary={bodyValue} />
        {hasCollapsibleBody && <Collapse in={isCollapsibleOpen}>{collapsibleBody}</Collapse>}
      </ListItemButton>
      {(hasEdit || !isEmpty(references)) && (
        <ListItemSecondaryAction sx={{ opacity: itemSx ? itemSx(record).opacity : undefined }}>
          <IconButton
            aria-label="more"
            aria-controls={'menu-' + record.id}
            aria-haspopup="true"
            edge="end"
            onClick={openMenu}
            size="large"
          >
            <MoreIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id={'menu-' + record.id}
            keepMounted
            onClose={onMenuClose}
            open={open}
            sx={{ '& .MuiMenu-list': { padding: shouldDisplayPaddingInMenu ? undefined : 0 } }}
          >
            {hasEdit && (
              <MenuItemLink
                to={getRecordLink('edit')}
                className={classes.menuItemLink}
                leftIcon={<EditIcon />}
                primaryText="ra.action.edit"
              />
            )}
            {references
              .map((reference) => {
                const referenceConfig = getResourceByName(reference)
                const referenceKey = referenceConfig.options.referenceKey
                return referenceConfig ? (
                  <ReferenceMenuItem
                    filterValues={referencesFilterValues}
                    key={referenceConfig.name}
                    record={record}
                    reference={referenceConfig.name}
                    source={referencesSources[referenceKey] ?? referenceKey}
                  />
                ) : null
              })
              .filter(Boolean)}
          </Menu>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}

// TODO later: relying on "SimpleList" (from React-Admin) would greatly simplify the content of this component
const CompactList = ({ isChildOfListReference, ...props }) => {
  const { data } = useListContext()
  const translate = useTranslate()

  const { permissions } = usePermissions()
  const resource = useResourceContext()
  const resourceConfig = getResourceByName(resource)
  const filteredReferences = (resourceConfig.options.references || []).filter((r) =>
    hasResourcePermission(permissions, r, SYSTEM_PERMISSION_READ),
  )

  return !isEmpty(data) ? (
    <List sx={{ padding: 0 }}>
      {data.map((record, index) => (
        <Fragment key={'item-' + record.id}>
          <ResourceContextProvider value={resource}>
            <RecordContextProvider value={record}>
              <CompactListItem {...props} references={filteredReferences} />
            </RecordContextProvider>
          </ResourceContextProvider>
          {(isChildOfListReference || index < data.length - 1) && <Divider sx={{ margin: 0 }} />}
        </Fragment>
      ))}
    </List>
  ) : (
    <CardContent>
      <Typography variant="body2">{translate('ra.navigation.no_results')}</Typography>
    </CardContent>
  )
}

export default CompactList
