import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslate } from 'ra-core'

import { useShowStyles } from '../resources/common/show'

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    width: 94,
    height: 34,
  },
  circularProgress: {
    marginRight: theme.spacing(1),
  },
}))

const SensitiveDataAccessDialog = ({
  handleClose,
  handleConfirm,
  isConfirmDisabled,
  isOpen,
  loading,
  onJustificationChange,
  translationPrefix,
}) => {
  const classes = useStyles()
  const showClasses = useShowStyles()
  const translate = useTranslate()
  const cancelLabel = translate('ra.action.cancel', { _: 'ra.action.cancel' })
  const confirmLabel = translate('ra.action.confirm', { _: 'ra.action.confirm' })

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{translate(`${translationPrefix}.title`)}</DialogTitle>
      <DialogContent>
        <Typography>{translate(`${translationPrefix}.justificationText`)}</Typography>
        <FormControl style={{ marginTop: '1.5em', display: 'block' }}>
          <TextField
            name="justification"
            label={translate(`${translationPrefix}.justification`)}
            className={showClasses.textFieldWithFullWidth}
            onChange={onJustificationChange}
            size="small"
            multiline
            required
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button aria-label={cancelLabel} onClick={handleClose}>
          {cancelLabel}
        </Button>
        <Button
          data-testid="confirm-button"
          aria-label={confirmLabel}
          disabled={isConfirmDisabled}
          variant="outlined"
          color="primary"
          className={classes.confirmButton}
          onClick={() => handleConfirm()}
        >
          {loading ? <CircularProgress className={classes.circularProgress} size={18} /> : confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SensitiveDataAccessDialog
