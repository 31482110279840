import { get, capitalize } from 'lodash'

export default {
  name: 'damage-blueprints',
  recordRepresentation: (record) => {
    const name = get(record, 'name', '')
    return name === '' ? '\u00a0' : capitalize(name)
  },
  options: {
    referenceKey: 'blueprint_id',
  },
}
