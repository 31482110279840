import React from 'react'
import { useListContext } from 'react-admin'

import { useCurrentAccountSelectors } from '../accounts/hooks'
import OrganisationsFilter from '../organisations/filter'
import HubsFilter from '../hubs/filter'

const useGetOrganisationWithHubFilters = () => {
  const { hasSingleOrganisation, hasSingleHub } = useCurrentAccountSelectors()
  const { filterValues } = useListContext()

  const filters = []
  if (!hasSingleOrganisation) {
    filters.push(<OrganisationsFilter key="orgFilter" alwaysOn />)
  }
  if ((filterValues.organisation_id || hasSingleOrganisation) && !hasSingleHub) {
    filters.push(<HubsFilter key="hubFilter" alwaysOn />)
  }
  return filters
}

export default useGetOrganisationWithHubFilters
