import Icon from '@mui/icons-material/LocalOffer'

export default {
  name: 'vouchers',
  recordRepresentation: (record) => `${record?.name} (${record?.code})`,
  options: {
    referenceKey: 'voucher_id',
    defaultSort: { field: 'created_on', order: 'DESC' },
  },
  icon: Icon,
}
