import ArchiveIcon from '@mui/icons-material/Archive'

import { archiveOrganisationConfirm } from '../resources/organisations/actions'

// Email notifications

export const ORGANISATION_EMAIL_NOTIFICATION_BOOKING_INTERACTION = 'booking_interaction_notification'
export const ORGANISATION_EMAIL_NOTIFICATION_CRITICAL_ISSUE = 'critical_issue_notification'
export const ORGANISATION_EMAIL_NOTIFICATION_VEHICLE_RETURNED = 'vehicle_returned_notification'

export const ORGANISATION_EMAIL_NOTIFICATIONS = [
  {
    code: ORGANISATION_EMAIL_NOTIFICATION_BOOKING_INTERACTION,
    label: 'resources.organisations.emailNotifications.bookingInteraction.label',
    helperText: 'resources.organisations.emailNotifications.bookingInteraction.helperText',
  },
  {
    code: ORGANISATION_EMAIL_NOTIFICATION_CRITICAL_ISSUE,
    label: 'resources.organisations.emailNotifications.criticalIssue.label',
    helperText: 'resources.organisations.emailNotifications.criticalIssue.helperText',
    recommended: true,
  },
  {
    code: ORGANISATION_EMAIL_NOTIFICATION_VEHICLE_RETURNED,
    label: 'resources.organisations.emailNotifications.vehicleReturned.label',
    helperText: 'resources.organisations.emailNotifications.vehicleReturned.helperText',
  },
]

// Default rental T&Cs' variables

export const ORGANISATION_DEFAULT_RENTAL_TCS_LEGAL_NAME = '[ORGANISATION_LEGAL_NAME]'
export const ORGANISATION_DEFAULT_RENTAL_TCS_DISPLAY_NAME = '[ORGANISATION_DISPLAY_NAME]'
export const ORGANISATION_DEFAULT_RENTAL_TCS_START_DATE = '[START_DATE]'
export const ORGANISATION_DEFAULT_RENTAL_TCS_EMAIL_ADDRESS = '[ORGANISATION_EMAIL_ADDRESS]'

// Actions

const ORGANISATION_ACTION_ARCHIVE = 'ARCHIVE'

export const ORGANISATION_ACTIONS = {
  [ORGANISATION_ACTION_ARCHIVE]: {
    name: 'archive',
    label: 'resources.organisations.actions.archive.label',
    icon: ArchiveIcon,
    refresh: true,
    confirm: archiveOrganisationConfirm,
  },
}
