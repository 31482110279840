// Payment actions for payment methods
export const PAYMENT_ACTION_ADD = 'ADD'
export const PAYMENT_ACTION_HARD_DELETE = 'HARD_DELETE'
export const PAYMENT_ACTION_SOFT_DELETE = 'SOFT_DELETE'

// Payment actions for payments
export const PAYMENT_ACTION_PRE_AUTHORIZATION = 'PRE_AUTHORIZATION'
export const PAYMENT_ACTION_CANCELLATION_SEND = 'CANCELLATION_SEND'
export const PAYMENT_ACTION_CANCELLATION = 'CANCELLATION'
export const PAYMENT_ACTION_CAPTURE_SEND = 'CAPTURE_SEND'
export const PAYMENT_ACTION_CAPTURE = 'CAPTURE'
export const PAYMENT_ACTION_ADJUST_SEND = 'ADJUST_SEND'
export const PAYMENT_ACTION_ADJUST = 'ADJUST'

export const PAYMENT_ACTIONS = {
  [PAYMENT_ACTION_ADD]: 'resources.payment-actions.enums.types.addPaymentMethod',
  [PAYMENT_ACTION_HARD_DELETE]: 'resources.payment-actions.enums.types.hardDeletePaymentMethod',
  [PAYMENT_ACTION_SOFT_DELETE]: 'resources.payment-actions.enums.types.softDeletePaymentMethod',
  [PAYMENT_ACTION_PRE_AUTHORIZATION]: 'resources.payment-actions.enums.types.preAuthorisation',
  [PAYMENT_ACTION_CANCELLATION_SEND]: 'resources.payment-actions.enums.types.cancellationSend',
  [PAYMENT_ACTION_CANCELLATION]: 'resources.payment-actions.enums.types.cancellation',
  [PAYMENT_ACTION_CAPTURE_SEND]: 'resources.payment-actions.enums.types.captureSend',
  [PAYMENT_ACTION_CAPTURE]: 'resources.payment-actions.enums.types.capture',
  [PAYMENT_ACTION_ADJUST_SEND]: 'resources.payment-actions.enums.types.adjustSend',
  [PAYMENT_ACTION_ADJUST]: 'resources.payment-actions.enums.types.adjust',
}

export const PAYMENT_ACTION_STATUS_SUCCESS = 'SUCCESS'
export const PAYMENT_ACTION_STATUS_CHALLENGE = 'CHALLENGE'
export const PAYMENT_ACTION_STATUS_ERROR = 'ERROR'

export const PAYMENT_ACTION_STATUSES = {
  [PAYMENT_ACTION_STATUS_SUCCESS]: 'resources.payment-actions.enums.status.success',
  [PAYMENT_ACTION_STATUS_CHALLENGE]: 'resources.payment-actions.enums.status.challenge',
  [PAYMENT_ACTION_STATUS_ERROR]: 'resources.payment-actions.enums.status.error',
}
