import { getApps, initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth'

import env from '../config/env'

let firebaseAuth
if (getApps().length < 1) {
  const firebaseApp = initializeApp(env.FIREBASE)
  firebaseAuth = getAuth(firebaseApp)
  setPersistence(firebaseAuth, browserLocalPersistence)
  if (import.meta.env.VITE_USE_FIREBASE_EMULATOR === 'true') {
    connectAuthEmulator(firebaseAuth, 'http://localhost:9099')
  }
}

export default {
  auth: firebaseAuth,
}
