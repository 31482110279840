import { useGetList } from 'react-admin'

import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'

import config from './config'

export const useGetHubsListForOrganisation = (organisationId) => {
  const [hasRead] = useResourcePermissions(config.name, SYSTEM_PERMISSION_READ)
  return useGetList(
    config.name,
    {
      pagination: { page: 1, perPage: 100 },
      sort: config.options.defaultSort,
      filter: { organisation_id: organisationId },
    },
    { enabled: hasRead && Boolean(organisationId) },
  )
}
