import { NumberField, useLocaleState, useRecordContext } from 'react-admin'
import get from 'lodash/get'

import env from '../config/env'

import AdvancedTextField from './AdvancedTextField'
import StaticText from './StaticText'

const PriceField = (props) => {
  const [locale] = useLocaleState()
  const record = useRecordContext(props)
  const value = get(record, props.source)
  if (!record || (!value && value !== 0)) {
    return <AdvancedTextField {...props} />
  }

  return (
    <>
      <StaticText sx={props.sx}>{value >= 0 ? '' : '–'}</StaticText>
      <NumberField {...props} locales={locale} record={record} />
    </>
  )
}

PriceField.defaultProps = {
  textAlign: 'right',
  options: { style: 'currency', currency: env.CURRENCY_CODE },
  transform: (v) => Math.abs(v / 100),
}

export default PriceField
