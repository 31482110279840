import { required, useSaveContext } from 'react-admin'
import { useWatch } from 'react-hook-form'

import hubsConfig from '../hubs/config'
import organisationsConfig from '../organisations/config'
import AdvancedReferenceInput from '../../components/AdvancedReferenceInput'

import config from './config'

const VehicleReferenceInput = ({
  filter,
  linkedToHub = true,
  readOnly = false,
  source = config.options.referenceKey,
  ...props
}) => {
  const [organisationId, hubId] = useWatch({
    name: [organisationsConfig.options.referenceKey, hubsConfig.options.referenceKey],
  })
  const isFormTypeFilter = useSaveContext() === undefined

  return (
    <AdvancedReferenceInput
      filter={{
        ...config.options.defaultFilterValues,
        organisation_id: organisationId,
        hub_id: linkedToHub ? hubId : null,
        ...filter,
      }}
      readOnly={isFormTypeFilter ? false : (linkedToHub ? !hubId : !organisationId) || readOnly}
      reference={config.name}
      sort={config.options.defaultSort}
      source={source}
      validate={required()}
      {...props}
    />
  )
}

export default VehicleReferenceInput
