// Ops user roles
export const OPS_USER_ROLE_OWNER = 'OWNER'
export const OPS_USER_ROLE_ADMIN = 'ADMIN'
export const OPS_USER_ROLE_SUPPORT = 'SUPPORT'
export const OPS_USER_ROLE_PLANNER = 'PLANNER'
export const OPS_USER_ROLE_LEASING_COMPANY = 'LEASING_COMPANY'
export const OPS_USER_ROLE_VIEWER = 'VIEWER'

export const OPS_USER_ROLES = [
  OPS_USER_ROLE_OWNER,
  OPS_USER_ROLE_ADMIN,
  OPS_USER_ROLE_SUPPORT,
  OPS_USER_ROLE_PLANNER,
  OPS_USER_ROLE_LEASING_COMPANY,
  OPS_USER_ROLE_VIEWER,
]

export const OPS_USER_ROLES_NAMES = OPS_USER_ROLES.reduce((acc, role) => {
  acc[role] = `resources.ops-users.enums.roles.${role.toLowerCase()}.name`
  return acc
}, {})

export const OPS_USER_ROLES_AND_PERMISSIONS = OPS_USER_ROLES.map((role) => ({
  role: OPS_USER_ROLES_NAMES[role],
  permission: `resources.ops-users.enums.roles.${role.toLowerCase()}.permission`,
}))

// System permissions
export const SYSTEM_PERMISSION_CREATE = 'CREATE'
export const SYSTEM_PERMISSION_READ = 'READ'
export const SYSTEM_PERMISSION_UPDATE = 'UPDATE'
export const SYSTEM_PERMISSION_PATCH = 'PATCH'
export const SYSTEM_PERMISSION_DELETE = 'DELETE'
export const SYSTEM_PERMISSION_ACTIONS = 'ACTIONS'
export const SYSTEM_PERMISSION_PRIVATE_DATA = 'PRIVATE_DATA'
export const SYSTEM_PERMISSION_GATEWAY_LINK = 'GATEWAY_LINK'
export const SYSTEM_PERMISSION_POSITION = 'POSITION'

// UI permissions
export const UI_PERMISSION_MENU = 'MENU'
