export const VEHICLE_UNAVAILABILITY_TYPE_MAINTENANCE = 'MAINTENANCE'
export const VEHICLE_UNAVAILABILITY_TYPE_EXTERNAL_BOOKING = 'EXTERNAL_BOOKING'
export const VEHICLE_UNAVAILABILITY_TYPE_OTHER = 'OTHER'

export const VEHICLE_UNAVAILABILITY_TYPES = {
  [VEHICLE_UNAVAILABILITY_TYPE_MAINTENANCE]: 'resources.maintenances.enums.types.maintenance',
  [VEHICLE_UNAVAILABILITY_TYPE_EXTERNAL_BOOKING]: 'resources.maintenances.enums.types.externalBooking',
  [VEHICLE_UNAVAILABILITY_TYPE_OTHER]: 'resources.maintenances.enums.types.other',
}

export const VEHICLE_UNAVAILABILITY_TYPES_COLORS = {
  [VEHICLE_UNAVAILABILITY_TYPE_MAINTENANCE]: '#808080',
  [VEHICLE_UNAVAILABILITY_TYPE_EXTERNAL_BOOKING]: '#999999',
  [VEHICLE_UNAVAILABILITY_TYPE_OTHER]: '#B2B2B2',
}
