import { Box, IconButton, Menu } from '@mui/material'
import MoreIcon from '@mui/icons-material/MoreVert'
import { makeStyles } from '@mui/styles'
import { SimpleShowLayout, useRecordContext } from 'react-admin'
import classnames from 'classnames'
import { useState } from 'react'

import { getResourceByName } from '..'
import { GenericListLayout, ListDatagrid } from '../common/list'
import { useShowStyles } from '../common/show'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import HubPickUpAndDropOffHoursField from '../../components/HubPickUpAndDropOffHoursField'
import { ReferenceMenuItem } from '../../components/ReferenceLink'
import StaticText from '../../components/StaticText'
import UnitField from '../../components/UnitField'
import { PLACE_DETAILS_FIELD_FORMATTED_ADDRESS } from '../../config/addresses'
import { SYSTEM_PERMISSION_UPDATE } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'

import config from './config'
import { CreateHubButton, EditHubButton } from './buttons'

const useStyles = makeStyles((theme) => ({
  actionsToolbar: {
    float: 'right',
  },
  moreButton: {
    color: theme.palette.contrast,
    width: 36,
    height: 36,
    marginLeft: 6,
  },
}))

const HubPanel = () => {
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  return (
    <SimpleShowLayout className={classnames(showClasses.fieldContainer, commonClasses.borderless)}>
      <HubPickUpAndDropOffHoursField />
    </SimpleShowLayout>
  )
}

const ActionsToolbar = () => {
  const classes = useStyles()
  const record = useRecordContext()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return record ? (
    <>
      <div className={classes.actionsToolbar}>
        <EditHubButton />
        <IconButton className={classes.moreButton} onClick={handleClick} size="large">
          <MoreIcon />
        </IconButton>
        <Menu id={'menu-' + record.id} anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
          {config.options.references
            .map((reference) => {
              const referenceConfig = getResourceByName(reference)
              return referenceConfig ? (
                <ReferenceMenuItem
                  filterValues={{ organisation_id: record.organisation_id }}
                  key={referenceConfig.name}
                  record={record}
                  reference={referenceConfig.name}
                />
              ) : null
            })
            .filter(Boolean)}
        </Menu>
      </div>
    </>
  ) : null
}

export const HubsListLayout = ({ organisationId, ...props }) => {
  const commonClasses = useCommonStyles()
  const [hasEdit] = useResourcePermissions(config.name, SYSTEM_PERMISSION_UPDATE)
  return (
    <GenericListLayout
      action={<CreateHubButton organisationId={organisationId} />}
      compactListLayout={
        <CompactList
          icon={<config.icon />}
          title={() => <AdvancedTextField source="name" />}
          body={() => (
            <>
              <StaticText>{'resources.hubs.fields.address'}: </StaticText>
              <AdvancedTextField source={`address.${PLACE_DETAILS_FIELD_FORMATTED_ADDRESS}`} />
              <br />
              <Box className={commonClasses.flexRowCenterContainer} gap={0.5}>
                <StaticText>{'resources.hubs.fields.geofencing'}:</StaticText>
                <UnitField source="geofencing" unit="m" />
              </Box>
            </>
          )}
          collapsibleBody={<HubPanel />}
          referencesFilterValues={{ organisation_id: organisationId }}
        />
      }
      regularListLayout={
        <ListDatagrid expand={<HubPanel />}>
          <AdvancedTextField source="name" sortable={false} />
          <AdvancedTextField
            source={`address.${PLACE_DETAILS_FIELD_FORMATTED_ADDRESS}`}
            label="resources.hubs.fields.address"
            sortable={false}
          />
          <UnitField source="geofencing" unit="m" sortable={false} />
          <ActionsToolbar hasEdit={hasEdit} />
        </ListDatagrid>
      }
      {...props}
    />
  )
}
