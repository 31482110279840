export default (apiProvider) => {
  let cache = null

  const clear = () => (cache = null)

  const fetch = async () => {
    const { json } = await apiProvider('/profile')
    const { advanced_permissions: advancedPermissions, ...currentUser } = json
    const fullPermissions = {
      currentUser,
      resources: advancedPermissions,
    }
    cache = fullPermissions
    return cache
  }

  const get = async () => {
    let permissions = cache
    if (!permissions) {
      permissions = await fetch()
    }
    return permissions
  }

  return { clear, fetch, get }
}
