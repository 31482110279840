import { useCallback } from 'react'
import { useRecordContext } from 'react-admin'
import { Box, Typography, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Icon from '@mui/icons-material/GetApp'
import QRCode from 'qrcode.react'
import get from 'lodash/get'
import { useTranslate } from 'ra-core'

import { useCommonStyles } from '../config/theme'

const useStyles = makeStyles({
  icon: { paddingRight: '0.25em' },
})

const OrganisationRegistrationLinkField = ({ title, source }) => {
  const record = useRecordContext()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const translate = useTranslate()

  const onDownloadQRCodeClick = useCallback(
    (event) => {
      var canvas = document.getElementById('qr-code-image').getElementsByTagName('canvas')[0]
      const linkEl = event.currentTarget
      linkEl.href = canvas.toDataURL()
      const name = get(record, 'name')
      linkEl.download = `${name ? name + ' - ' : ''}Registration QR Code.png`
    },
    [record],
  )

  const url = get(record, source)
  if (!url) {
    return (
      <Typography component="span" variant="body2">
        n/a
      </Typography>
    )
  }
  return (
    <>
      <Box width="100%" display="flex" flexDirection="row" mt={2}>
        <Box width="64" mr={1}>
          <QRCode value={url} size={64} renderAs="canvas" />
        </Box>
        <Box width="100%" flexDirection="column">
          <Box>
            <Button color="primary" component="a" onClickCapture={onDownloadQRCodeClick}>
              <Icon className={classes.icon} />
              {translate(title)}
            </Button>
          </Box>
          <Box mt={1} ml={1.5}>
            <Typography component="span" variant="body2">
              <a href={url} className={commonClasses.link} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>

      <div style={{ position: 'absolute', height: 0, width: 0, overflow: 'hidden' }}>
        <div id="qr-code-image">
          <QRCode value={url} size={1024} renderAs="canvas" />
        </div>
      </div>
    </>
  )
}

OrganisationRegistrationLinkField.defaultProps = {
  title: 'resources.organisations.downloadQrCodeImage',
  source: 'registration_link',
}

export default OrganisationRegistrationLinkField
