import classnames from 'classnames'
import { useTranslate } from 'ra-core'
import { FunctionField, SimpleShowLayout, useRecordContext } from 'react-admin'
import { IconButton, Tooltip, Typography } from '@mui/material'
import DownloadIcon from '@mui/icons-material/GetApp'

import { GenericListLayout, ListDatagrid } from '../common/list'
import { useShowStyles } from '../common/show'
import opsUsersConfig from '../opsUsers/config'
import OpsUserField from '../opsUsers/field'
import { useApi } from '../../api/apiProvider'
import AdvancedBooleanField from '../../components/AdvancedBooleanField'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import PriceField from '../../components/PriceField'
import ReferenceValue from '../../components/ReferenceValue'
import StaticText from '../../components/StaticText'
import { BOOKING_TRANSACTION_ALL_TYPES, BOOKING_TRANSACTION_STATUSES } from '../../config/bookingTransactions'
import { useCommonStyles } from '../../config/theme'
import {
  isBookingTransactionTypeChargeBookingPrice,
  isBookingTransactionTypeChargeExtra,
} from '../../domain/bookingTransactions'

import config from './config'
import { CreateBookingTransactionButton } from './buttons'

const BookingTransactionPreAuthorisationRelatedField = (props) => (
  <AdvancedBooleanField
    {...props}
    source="is_pa_related"
    trueIcon={null}
    falseIcon={null}
    trueLabel="resources.booking-transactions.enums.isPaRelated.true"
    falseLabel="resources.booking-transactions.enums.isPaRelated.false"
  />
)

const BookingTransactionPanel = () => {
  const record = useRecordContext()
  const showClasses = useShowStyles()
  const commonClasses = useCommonStyles()
  return (
    <SimpleShowLayout className={classnames(showClasses.fieldContainer, commonClasses.borderless)}>
      {(isBookingTransactionTypeChargeBookingPrice(record) || isBookingTransactionTypeChargeExtra(record)) && (
        <BookingTransactionPreAuthorisationRelatedField
          record={record}
          label="resources.booking-transactions.fields.is_pa_related"
        />
      )}
      <AdvancedTextField source="justification" />
    </SimpleShowLayout>
  )
}

const InvoiceField = ({ source }) => {
  const { [source]: invoiceId } = useRecordContext()
  const translate = useTranslate()
  const commonClasses = useCommonStyles()
  const [getInvoiceData] = useApi(`/booking-transactions/invoice/${invoiceId}`)

  const downloadInvoice = async () => {
    if (!invoiceId) return
    const { result: invoiceData } = await getInvoiceData()
    if (!invoiceData) return
    const link = document.createElement('a')
    const filenamePrefix = translate(`resources.booking-transactions.fields.${source}`).toLowerCase()
    link.href = `data:application/pdf;base64,${invoiceData.content}`
    link.download = `${filenamePrefix}_${invoiceData.filename}`
    link.click()
  }

  return (
    <FunctionField
      source={source}
      render={() =>
        invoiceId ? (
          <Tooltip title={translate('resources.booking-transactions.list.downloadInvoice')} placement="left">
            <IconButton onClick={downloadInvoice} className={commonClasses.primaryColor} size="large">
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Typography component="span" variant="body2" style={{ marginLeft: 13 }}>
            n/a
          </Typography>
        )
      }
    />
  )
}

const BookingTransactionsListLayout = ({ bookingId, ...props }) => {
  const commonClasses = useCommonStyles()
  return (
    <GenericListLayout
      action={<CreateBookingTransactionButton bookingId={bookingId} />}
      compactListLayout={
        <CompactList
          icon={<config.icon />}
          title={() => (
            <>
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textPrimary">
                <AdvancedTextField source="type" map={BOOKING_TRANSACTION_ALL_TYPES} />
              </Typography>
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textSecondary">
                {' • '}
                <DateTooltipField source="created_on" addTime />
              </Typography>
            </>
          )}
          body={(record) => (
            <>
              <StaticText>{'resources.booking-transactions.fields.amount'}: </StaticText>
              <PriceField source="amount" />
              <br />
              <StaticText>{'resources.booking-transactions.fields.status'}: </StaticText>
              <AdvancedTextField source="status" map={BOOKING_TRANSACTION_STATUSES} />
              {Boolean(record[opsUsersConfig.options.referenceKey]) && (
                <>
                  <br />
                  <StaticText>{'resources.booking-transactions.fields.ops_user_id'} </StaticText>
                  <ReferenceValue reference={opsUsersConfig.name} />
                </>
              )}
              {(isBookingTransactionTypeChargeBookingPrice(record) || isBookingTransactionTypeChargeExtra(record)) && (
                <>
                  <br />
                  <StaticText>{'resources.booking-transactions.fields.is_pa_related'}: </StaticText>
                  <BookingTransactionPreAuthorisationRelatedField />
                </>
              )}
              <br />
              <StaticText>{'resources.booking-transactions.fields.justification'}: </StaticText>
              <AdvancedTextField source="justification" />
            </>
          )}
        />
      }
      regularListLayout={
        <ListDatagrid expand={<BookingTransactionPanel />}>
          <AdvancedTextField source="type" map={BOOKING_TRANSACTION_ALL_TYPES} />
          <DateTooltipField source="created_on" addTime />
          <PriceField source="amount" />
          <AdvancedTextField source="status" map={BOOKING_TRANSACTION_STATUSES} />
          <OpsUserField />
          <InvoiceField source="invoice_id" sortable={false} />
        </ListDatagrid>
      }
      {...props}
    />
  )
}

export default BookingTransactionsListLayout
