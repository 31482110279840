import ArrowIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'

import { useCommonStyles } from '../config/theme'
import { getISOFromDateTime, parseDateAsDateTime } from '../utils/dates'

import AdvancedDateTimeInput from './AdvancedDateTimeInput'

export const AdvancedDatesFilter = ({
  source = ['timeline_start', 'timeline_end'],
  maxDate,
  maxDurationInDays,
  ...props
}) => {
  const { setValue } = useFormContext()
  const commonClasses = useCommonStyles()

  const [startSource, endSource] = source
  const [startDate, endDate] = useWatch({ name: source })

  const handleDateChange = (newValue, changedSource) => {
    if (!maxDurationInDays || !newValue) return

    const isStart = changedSource === startSource
    const otherSource = isStart ? endSource : startSource
    const otherValue = isStart ? endDate : startDate
    const parsedOtherDate = otherValue ? parseDateAsDateTime(otherValue) : null

    if (parsedOtherDate) {
      const parsedNewDate = parseDateAsDateTime(newValue)
      const durationInDays = parsedNewDate.diff(parsedOtherDate, 'days').days
      // Detect if start date comes after end date or end date comes before start date
      if ((isStart && durationInDays > 0) || (!isStart && durationInDays < 0)) {
        // We don't reset the other date if the new date is the same day as the other date
        if (
          parsedNewDate.year === parsedOtherDate.year &&
          parsedNewDate.month === parsedOtherDate.month &&
          parsedNewDate.day === parsedOtherDate.day
        ) {
          return
        }
        // Otherwise, we reset the other date
        setValue(otherSource, null)
      } else if (Math.abs(durationInDays) > maxDurationInDays) {
        // If the duration is greater than the maximum allowed duration, we adjust the other date
        const adjustedDate = isStart
          ? parsedNewDate.plus({ days: maxDurationInDays })
          : parsedNewDate.minus({ days: maxDurationInDays })
        setValue(otherSource, getISOFromDateTime(adjustedDate))
      }
    }
  }

  return (
    <Box sx={{ display: { xs: 'flex', sm: 'block' }, flexDirection: { xs: 'column', sm: 'row' } }}>
      <AdvancedDateTimeInput
        {...props}
        maxDate={maxDate ?? (endDate && !maxDurationInDays ? parseDateAsDateTime(endDate) : undefined)}
        onChange={(newValue) => handleDateChange(newValue, startSource)}
        source={startSource}
      />
      <ArrowIcon
        className={commonClasses.contrastColor}
        sx={{
          width: 20,
          height: 20,
          margin: { xs: '4px auto -4px auto', sm: '18px 4px 0 4px' },
          transform: { xs: 'rotate(90deg)', sm: 'rotate(0deg)' },
        }}
      />
      <AdvancedDateTimeInput
        {...props}
        maxDate={maxDate}
        minDate={startDate && !maxDurationInDays ? parseDateAsDateTime(startDate) : undefined}
        onChange={(newValue) => handleDateChange(newValue, endSource)}
        source={endSource}
      />
    </Box>
  )
}
