export const ENV_NAME = 'valckenier'
export const HOST_NAME = 'ops.valckenier.mymove.be'
export const API_URL = 'https://valckenier.mymove.be/ops-api'
export const FIREBASE = {
  apiKey: 'AIzaSyDhsNg4A4gPu2JGjX93opcTcoGlxpIpbwc',
  authDomain: 'valckenier-go.firebaseapp.com',
  projectId: 'valckenier-go',
  appId: '1:953587816211:web:6083de4b84bacd795eece6',
}
export const LOCALE = 'en-BE'
export const CURRENCY_CODE = 'EUR'
export const CURRENCY_SYMBOL = '€'
export const TIMEZONE = 'Europe/Brussels'
