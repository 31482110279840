export const DAMAGES_STATUS_REPORTED = 'REPORTED'
export const DAMAGES_STATUS_VALIDATED = 'VALIDATED'
export const DAMAGES_STATUS_DISCARDED = 'DISCARDED'
export const DAMAGES_STATUS_RESOLVED = 'RESOLVED'

export const DAMAGES_STATUSES = {
  [DAMAGES_STATUS_REPORTED]: 'resources.damage-reports.enums.status.reported',
  [DAMAGES_STATUS_VALIDATED]: 'resources.damage-reports.enums.status.validated',
  [DAMAGES_STATUS_DISCARDED]: 'resources.damage-reports.enums.status.discarded',
  [DAMAGES_STATUS_RESOLVED]: 'resources.damage-reports.enums.status.resolved',
}

export const DAMAGES_CATEGORY_MINOR_SCRATCH = 'MINOR_SCRATCH'
export const DAMAGES_CATEGORY_MAJOR_SCRATCH = 'MAJOR_SCRATCH'
export const DAMAGES_CATEGORY_DENT = 'DENT'
export const DAMAGES_CATEGORY_WHEEL_ISSUE = 'WHEEL_ISSUE'
export const DAMAGES_CATEGORY_WINDSHIELD_CRACK = 'WINDSHIELD_CRACK'
export const DAMAGES_CATEGORY_SOUND = 'SOUND'
export const DAMAGES_CATEGORY_OTHER = 'OTHER'

export const DAMAGES_CATEGORIES = {
  [DAMAGES_CATEGORY_MINOR_SCRATCH]: 'resources.damage-reports.enums.category.minorScratch',
  [DAMAGES_CATEGORY_MAJOR_SCRATCH]: 'resources.damage-reports.enums.category.majorScratch',
  [DAMAGES_CATEGORY_DENT]: 'resources.damage-reports.enums.category.dent',
  [DAMAGES_CATEGORY_WHEEL_ISSUE]: 'resources.damage-reports.enums.category.wheelIssue',
  [DAMAGES_CATEGORY_WINDSHIELD_CRACK]: 'resources.damage-reports.enums.category.windshieldCrack',
  [DAMAGES_CATEGORY_SOUND]: 'resources.damage-reports.enums.category.sound',
  [DAMAGES_CATEGORY_OTHER]: 'resources.damage-reports.enums.category.other',
}
