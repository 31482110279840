import { Typography, Tooltip } from '@mui/material'
import { DateTime } from 'luxon'
import { useRecordContext } from 'react-admin'

import { TIME_REGEX, parseDateAsDateTime } from '../utils/dates'

const DateTooltipField = ({ source, addTime, relative, className, record }) => {
  const { [source]: dateStr } = useRecordContext({ record })
  const dt = parseDateAsDateTime(dateStr)
  return (
    <Typography component="span" variant="body2" className={className}>
      {dateStr ? (
        dt.isValid ? (
          relative ? (
            <Tooltip title={dt.toLocaleString(DateTime.DATETIME_SHORT)}>
              <span>{dt.toRelative()}</span>
            </Tooltip>
          ) : addTime ? (
            <span>{dt.toLocaleString(DateTime.DATETIME_SHORT)}</span>
          ) : (
            <Tooltip title={dt.toLocaleString(DateTime.DATETIME_SHORT)}>
              <span>{dt.toLocaleString(DateTime.DATE_SHORT)}</span>
            </Tooltip>
          )
        ) : TIME_REGEX.test(dateStr) ? (
          // We remove seconds if "HH:mm:ss" format
          dateStr.replace(TIME_REGEX, '$1:$2')
        ) : (
          dateStr
        )
      ) : (
        'n/a'
      )}
    </Typography>
  )
}

DateTooltipField.defaultProps = {
  addTime: false,
  relative: false,
}

export default DateTooltipField
