import { useTheme } from '@mui/styles'
import { useEffect } from 'react'
import { Layout, useLocaleState } from 'react-admin'

import CustomAppBar from './AppBar'
import { LANGUAGE_CODE_CACHE_KEY } from './LanguageSwitch'
import CustomMenu from './Menu'

export default (props) => {
  const theme = useTheme()

  const [, setLocale] = useLocaleState()
  useEffect(() => {
    const languageCode = window.localStorage.getItem(LANGUAGE_CODE_CACHE_KEY)
    if (languageCode) setLocale(languageCode)
  }, [setLocale])

  return <Layout {...props} theme={theme} appBar={CustomAppBar} menu={CustomMenu} />
}
