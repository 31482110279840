import { SelectInput, useResourceContext } from 'react-admin'
import { useTranslate } from 'ra-core'

export const EnumSelectInput = ({ source, options = [], translateArgs = {} }) => {
  const resource = useResourceContext()
  const translate = useTranslate()

  const choices = options.reduce(
    (acc, choice) => [
      ...acc,
      {
        id: choice,
        name: translate(`resources.${resource}.enums.${source}.values.${choice.toLowerCase()}`, { ...translateArgs }),
      },
    ],
    [],
  )

  return <SelectInput source={source} choices={choices} />
}
