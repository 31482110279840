import { Badge, Box } from '@mui/material'
import DefaultIcon from '@mui/icons-material/ViewList'
import { MenuItemLink, usePermissions, useResourceDefinitions, useSidebarState } from 'react-admin'
import { useTranslate } from 'ra-core'

import { useAccounts } from '../api/accountsProvider'
import { SYSTEM_PERMISSION_READ, UI_PERMISSION_MENU } from '../config/permissions'
import { hasResourcePermission } from '../domain/permissions'

const Menu = () => {
  const { currentAccount } = useAccounts()
  const [sidebarIsOpen] = useSidebarState()
  const translate = useTranslate()

  const resourcesDefinitions = useResourceDefinitions()
  const resources = Object.keys(resourcesDefinitions).map((name) => resourcesDefinitions[name])
  const { permissions } = usePermissions()
  const displayedResources = resources.filter((resource) =>
    hasResourcePermission(permissions, resource.name, SYSTEM_PERMISSION_READ, UI_PERMISSION_MENU),
  )

  return (
    <Box mt={1}>
      {displayedResources.map((resource) => {
        let icon = resource.icon ? <resource.icon /> : <DefaultIcon />
        if (currentAccount && resource.options?.badge) {
          icon = (
            <Badge badgeContent={currentAccount.counts[resource.name]} color="error" overlap="rectangular">
              {icon}
            </Badge>
          )
        }
        const resourceName = translate(`resources.${resource.name}.name`, 2)
        return (
          <MenuItemLink
            key={resource.name}
            leftIcon={icon}
            primaryText={sidebarIsOpen ? resourceName : ''}
            sx={{ marginBottom: '5px', paddingRight: sidebarIsOpen ? undefined : 0 }}
            to={`/${resource.name}`}
            tooltipProps={{ title: resourceName }}
          />
        )
      })}
    </Box>
  )
}

export default Menu
