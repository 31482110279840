import jsonExport from 'jsonexport/dist'
import { downloadCSV } from 'react-admin'

import { formatDateTimeForExport } from '../../utils/dates'

const damageReportsExporter = (records, fetchRelatedRecords) => {
  let damageReports = []
  fetchRelatedRecords(records, 'organisation_id', 'organisations')
    .then((organisations) => {
      damageReports = records.map((record) =>
        record.organisation_id && organisations[record.organisation_id]
          ? { ...record, organisation_name: organisations[record.organisation_id].name }
          : record,
      )
    })
    .then(() =>
      fetchRelatedRecords(records, 'hub_id', 'hubs').then((hubs) => {
        damageReports = damageReports.map((damageReport) =>
          damageReport.hub_id && hubs[damageReport.hub_id]
            ? { ...damageReport, hub_name: hubs[damageReport.hub_id].name }
            : damageReport,
        )
      }),
    )
    .then(() =>
      fetchRelatedRecords(records, 'vehicle_id', 'vehicles').then((vehicles) => {
        damageReports = damageReports.map((damageReport) =>
          damageReport.vehicle_id && vehicles[damageReport.vehicle_id]
            ? { ...damageReport, vehicle_plate: vehicles[damageReport.vehicle_id].designation }
            : damageReport,
        )
      }),
    )
    .then(() =>
      fetchRelatedRecords(records, 'reporter_id', 'users').then((users) => {
        damageReports = damageReports.map((damageReport) =>
          damageReport.reporter_id && users[damageReport.reporter_id]
            ? {
                ...damageReport,
                reporter_user: `${users[damageReport.reporter_id].first_name} ${
                  users[damageReport.reporter_id].last_name
                }`,
              }
            : damageReport,
        )
      }),
    )
    .then(() => {
      const damageReportsForExport = damageReports.map((damageReport) => {
        // Remove non-used fields
        const { created_on, hub_id, organisation_id, reporter_id, updated_on, vehicle_id, ...damageReportForExport } =
          damageReport
        // Add new fields (date formatting & header change)
        damageReportForExport.creation_date = formatDateTimeForExport(created_on)
        damageReportForExport.last_update_date = formatDateTimeForExport(updated_on)
        return damageReportForExport
      })
      jsonExport(
        damageReportsForExport,
        { headers: ['id', 'organisation_name', 'hub_name', 'vehicle_plate', 'reporter_user'] }, // order fields in the export
        (err, csv) => downloadCSV(csv, 'damage-reports'),
      )
    })
}

export default damageReportsExporter
