import Icon from '@mui/icons-material/AttachMoney'

export default {
  name: 'pricing-strategies',
  recordRepresentation: 'name',
  options: {
    defaultSort: { field: 'start_date', order: 'DESC' },
    referenceKey: 'pricing_strategy_id',
    showResource: 'organisations',
  },
  icon: Icon,
}
