import {
  HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_OPEN_ALL_DAY,
  HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CLOSED_ALL_DAY,
  HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM,
} from '../config/hubs'
import { TIME_REGEX } from '../utils/dates'

export const formatHubPickUpAndDropOffHoursWeekDayParams = (weekDay) => {
  // We replace seconds with "00" on start & end times
  let startTime = TIME_REGEX.test(weekDay.start_time) ? weekDay.start_time.replace(TIME_REGEX, '$1:$2:00') : null
  let endTime = TIME_REGEX.test(weekDay.end_time) ? weekDay.end_time.replace(TIME_REGEX, '$1:$2:00') : null

  switch (weekDay.type) {
    case HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_OPEN_ALL_DAY:
      startTime = '00:00:00'
      endTime = '23:59:59'
      break
    case HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CLOSED_ALL_DAY:
      startTime = '00:00:00'
      endTime = '00:00:00'
      break
    default:
      break
  }

  return { start_time: startTime, end_time: endTime }
}

export const getHubPickUpAndDropOffHoursWeekDayParams = (startTime, endTime) => {
  let type = HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM
  if (startTime === '00:00:00' && endTime === '23:59:59') {
    type = HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_OPEN_ALL_DAY
  } else if (startTime === '00:00:00' && endTime === '00:00:00') {
    type = HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CLOSED_ALL_DAY
  }
  return {
    type,
    start_time: type === HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM ? startTime : null,
    end_time: type === HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM ? endTime : null,
  }
}
